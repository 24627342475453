import { atom, useSetRecoilState } from 'recoil';

import type { RowSelectionState } from '@tanstack/react-table';

import { CrawlingProduct } from '../types';

export const rowSelectionState = atom<RowSelectionState>({
  key: 'rowSelectionState',
  default: {},
});

export const selectedRowsState = atom<CrawlingProduct[]>({
  key: 'selectedRowsState',
  default: [],
});

export const useResetSelection = () => {
  const setSelectedRows = useSetRecoilState(selectedRowsState);
  const setRowSelection = useSetRecoilState(rowSelectionState);

  const resetSelection = () => {
    // On met à jour le state de la sélection Tanstack en dehors de la logique du tableau
    setRowSelection({});
    // On doit donc aussi mettre à jour manuellement le state de la sélection de produits
    setSelectedRows([]);
  };

  return { resetSelection };
};

export const contextRowSelectionState = atom<string | undefined>({
  key: 'contextRowSelectionState',
  default: undefined,
});

export const contextSelectedRowState = atom<CrawlingProduct | undefined>({
  key: 'contextSelectedRowState',
  default: undefined,
});

export const useResetContextSelection = () => {
  const setContextSelectedRow = useSetRecoilState(contextSelectedRowState);
  const setContextRowSelection = useSetRecoilState(contextRowSelectionState);

  const resetContextSelection = () => {
    setContextSelectedRow(undefined);
    setContextRowSelection(undefined);
  };

  return { resetContextSelection };
};
