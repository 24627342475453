import { useEffect } from 'react';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { Popover, PopoverContent, PopoverTrigger } from '@appchoose/popover';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import type { Editor, FocusPosition, JSONContent } from '@tiptap/react';
import { EditorContent, useEditor } from '@tiptap/react';

import { StoreRegion } from '../../types/generated';
import emojis from '../../utils/emojis';
import { sanitizeNewLine } from '../../utils/string';
import { AddDash as AddDashIcon } from '../icons/add-dash';
import { AddPoint as AddPointIcon } from '../icons/add-point';
import { RemoveNewLines as RemoveNewLinesIcon } from '../icons/remove-new-lines';
import { AddDash } from './add-dash';
import { AddPoint } from './add-point';
import { Lowercase } from './lowercase';
import { OverrideEscape } from './override-escape';
import { RemoveNewLines } from './remove-new-lines';
import { TrimStart } from './trim-start';

export type MenuBarProps = {
  editor: Editor | null;
  disabled?: boolean;
  shortcuts: Shortcut[];
};

export type Shortcut = {
  content: string;
  emoji: string;
};

export const getShortcuts = (storeRegion: StoreRegion) => {
  return storeRegion === StoreRegion.Fr
    ? [
        {
          content: '🧺 Entretien',
          emoji: '🧺',
        },
        {
          content: '🌱 Composition',
          emoji: '🌱',
        },
        {
          content: '🧵 Composition',
          emoji: '🧵',
        },
        {
          content: '📐 Dimensions',
          emoji: '📐',
        },
        {
          content: "🧚‍♀️ Comment l'utiliser ?",
          emoji: '🧚‍♀️',
        },
        {
          content: '🇫🇷 Fabriqué en France.',
          emoji: '🇫🇷',
        },
        {
          content: '🔎 Détails',
          emoji: '🔎',
        },
      ]
    : [
        {
          content: '🧺 Care',
          emoji: '🧺',
        },
        {
          content: '🧽 Care',
          emoji: '🧽',
        },
        {
          content: '🌱 Composition',
          emoji: '🌱',
        },
        {
          content: '🧵 Material',
          emoji: '🧵',
        },
        {
          content: '📐 Dimensions',
          emoji: '📐',
        },
        {
          content: '📐 Size Guide',
          emoji: '📐',
        },
        {
          content: '⚖ Volume',
          emoji: '⚖',
        },
        {
          content: '⚖ Weight',
          emoji: '⚖',
        },
        {
          content: '🧚‍♀️ How to use it',
          emoji: '🧚‍♀️',
        },
        {
          content: '🔎 Details',
          emoji: '🔎',
        },
        {
          content: '🇫🇷 Made in France.',
          emoji: '🇫🇷',
        },
        {
          content: '🇺🇸 Made in the USA.',
          emoji: '🇺🇸',
        },
      ];
};

export const getProductShortcuts = (storeRegion: StoreRegion) => {
  return storeRegion === StoreRegion.Fr
    ? [
        {
          content: '🧺 Entretien',
          emoji: '🧺',
        },
        {
          content: '🧽 Entretien',
          emoji: '🧽',
        },
        {
          content: '🌱 Composition',
          emoji: '🌱',
        },
        {
          content: '🧵 Composition',
          emoji: '🧵',
        },
        {
          content: '📐 Dimensions',
          emoji: '📐',
        },
        {
          content: '📐 Guide des tailles',
          emoji: '📐',
        },
        {
          content: '⚖️ Contenance',
          emoji: '⚖️',
        },
        {
          content: '⚖️ Poids',
          emoji: '⚖️',
        },
        {
          content: "🧚‍♀️ Comment l'utiliser ?",
          emoji: '🧚‍♀️',
        },
        {
          content: '🇫🇷 Fabriqué en France.',
          emoji: '🇫🇷',
        },
        {
          content: '🔎 Détails',
          emoji: '🔎',
        },
      ]
    : [
        {
          content: '🧺 Care:',
          emoji: '🧺',
        },
        {
          content: '🧽 Care:',
          emoji: '🧽',
        },
        {
          content: '🌱 Composition:',
          emoji: '🌱',
        },
        {
          content: '🧵 Material:',
          emoji: '🧵',
        },
        {
          content: '📐 Dimensions:',
          emoji: '📐',
        },
        {
          content: '📐 Size Guide:',
          emoji: '📐',
        },
        {
          content: '⚖️ Volume:',
          emoji: '⚖️',
        },
        {
          content: '⚖️ Weight:',
          emoji: '⚖️',
        },
        {
          content: '🧚‍♀️ How to use it:',
          emoji: '🧚‍♀️',
        },
        {
          content: '🔎 Details:',
          emoji: '🔎',
        },
        {
          content: '🇫🇷 Made in France.',
          emoji: '🇫🇷',
        },
        {
          content: '🇺🇸 Made in the USA.',
          emoji: '🇺🇸',
        },
      ];
};

const MenuBar: React.FC<MenuBarProps> = ({
  editor,
  disabled,
  shortcuts,
}: MenuBarProps) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="flex flex-wrap divide-x divide-gray-100 border-b">
      <button
        type="button"
        onClick={() => editor.chain().focus().trimStart().addDash().run()}
        className="flex items-center justify-center p-2 hover:bg-gray-50"
        disabled={disabled}
        tabIndex={-1}
      >
        <AddDashIcon className="text-gray-900" />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().trimStart().addPoint().run()}
        className="flex items-center justify-center p-2 hover:bg-gray-50"
        disabled={disabled}
        tabIndex={-1}
      >
        <AddPointIcon className="text-gray-900" />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().lowercase().run()}
        className="flex items-center justify-center p-2 hover:bg-gray-50"
        disabled={disabled}
        tabIndex={-1}
      >
        <Icon icon="lowercase" size="large" className="text-gray-900" />
      </button>
      <Popover>
        <PopoverTrigger disabled={disabled} tabIndex={-1}>
          <div className="flex items-center justify-center p-2 hover:bg-gray-50">
            <Icon icon="face" size="large" className="text-gray-900" />
          </div>
        </PopoverTrigger>

        <PopoverContent className="w-80">
          <div
            className="grid border-none bg-transparent"
            style={{ gridTemplateColumns: 'repeat(12, 1fr)' }}
          >
            {emojis.map((emoji, index) => (
              <button
                key={index}
                type="button"
                className="emoji-fix flex items-center justify-center border-none hover:bg-gray-100"
                style={{ width: '24px' }}
                onClick={() =>
                  editor.chain().focus().insertContent(emoji).run()
                }
              >
                {emoji}
              </button>
            ))}
          </div>
        </PopoverContent>
      </Popover>
      <button
        type="button"
        onClick={() =>
          editor.chain().focus().trimStart().removeNewLines().run()
        }
        className="flex items-center justify-center p-2 hover:bg-gray-50"
        disabled={disabled}
        tabIndex={-1}
      >
        <RemoveNewLinesIcon className="text-gray-900" />
      </button>
      {shortcuts.map((shortcut, idx) => (
        <button
          key={idx}
          type="button"
          onClick={() =>
            editor.chain().focus().insertContent(shortcut.content).run()
          }
          className="p-1.5 hover:bg-gray-50"
          disabled={disabled}
          tabIndex={-1}
          title={shortcut.content}
        >
          <span
            className="flex items-center justify-center"
            style={{ width: '30px', height: '30px' }}
          >
            {shortcut.emoji}
          </span>
        </button>
      ))}
      <div></div>
    </div>
  );
};

export type TextEditorProps = {
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  disabled?: boolean;
  isValid?: boolean;
  shortcuts: Shortcut[];
  className?: string;
  autofocus?: FocusPosition | undefined;
};

const serialize = (content: string): JSONContent => {
  return {
    type: 'doc',
    content: sanitizeNewLine(content)
      .split('\n')
      .map((line) => {
        if (line.length === 0) return { type: 'paragraph' };
        return {
          type: 'paragraph',
          content: [{ type: 'text', text: line }],
        };
      }),
  };
};

export const TextEditor = ({
  value,
  onChange,
  onBlur,
  disabled,
  isValid,
  shortcuts,
  className,
  autofocus = false,
}: TextEditorProps) => {
  const editor = useEditor({
    autofocus,
    content: value ? serialize(value) : '',
    editable: !disabled,
    editorProps: {
      attributes: {
        // TODO: find a solution for bad emoji rendering https://github.com/appchoose/backlog/issues/2394
        class: 'p-2 min-h-[140px] font-editor focus:outline-none',
      },
    },
    extensions: [
      Document,
      History,
      Paragraph,
      Text,
      AddDash,
      AddPoint,
      Lowercase,
      TrimStart,
      RemoveNewLines,
      OverrideEscape,
    ],
    onUpdate: ({ editor }) => {
      onChange(
        editor.getText({
          blockSeparator: '\n',
        })
      );
    },
    onBlur: () => {
      onBlur();
    },
    shouldRerenderOnTransaction: false,
  });

  useEffect(() => {
    if (!editor) return;

    const editorContent = editor.getText({
      blockSeparator: '\n',
    });
    if (editorContent !== value) {
      editor.commands.setContent(serialize(value ?? ''));
    }
  }, [editor, value]);

  const error = isValid !== undefined && !isValid;

  return (
    <div
      className={cn(
        'text-editor rounded border border-gray-500 text-gray-900 placeholder-gray-500 transition duration-300 focus-within:border-gray-700 focus-within:shadow-[rgb(255,_255,_255)_0px_0px_0px_0px,_rgb(67,_70,_71)_0px_0px_0px_1px,_rgba(0,_0,_0,_0)_0px_0px_0px_0px] focus-within:ring-gray-700 hover:border-gray-700 aria-disabled:cursor-not-allowed aria-disabled:border-gray-300 aria-disabled:text-gray-900 aria-disabled:placeholder-gray-300 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:focus-within:border-red-600 aria-[invalid="true"]:focus-within:shadow-[rgb(255,_255,_255)_0px_0px_0px_0px,_rgb(194,_12,_12)_0px_0px_0px_1px,_rgba(0,_0,_0,_0)_0px_0px_0px_0px] aria-[invalid="true"]:focus-within:ring-red-600',
        className
      )}
      aria-invalid={error}
      aria-disabled={disabled}
    >
      <MenuBar editor={editor} disabled={disabled} shortcuts={shortcuts} />
      <EditorContent editor={editor} disabled={disabled} />
    </div>
  );
};

TextEditor.displayName = 'TextEditor';
