import {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { useFetchData } from '../hooks/use-fetcher';
import { Upload } from './codegen';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  HexColorCode: { input: any; output: any };
  Json: { input: any; output: any };
  LocalDate: { input: string; output: string };
  URL: { input: string; output: string };
  Upload: { input: Upload; output: Upload };
  Void: { input: any; output: any };
};

export type AccentuateReferralInput = {
  bigImage: Scalars['String']['input'];
  saleId: Scalars['String']['input'];
  smallImage: Scalars['String']['input'];
  store: StoreRegion;
};

export type Ad = {
  __typename?: 'Ad';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  is_cold?: Maybe<Scalars['Boolean']['output']>;
  is_retarget?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  video_url?: Maybe<Scalars['String']['output']>;
};

export type AdVideoInput = {
  imageUrl: Scalars['String']['input'];
  is_cold?: InputMaybe<Scalars['Boolean']['input']>;
  is_retarget?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  saleId: Scalars['String']['input'];
  type: Scalars['String']['input'];
  videoUrl: Scalars['String']['input'];
};

export type AddTextPayload = {
  isPrefix: Scalars['Boolean']['input'];
  text: Scalars['String']['input'];
};

export type BadgeInput = {
  hide?: InputMaybe<Scalars['Boolean']['input']>;
  is_special?: InputMaybe<Scalars['Boolean']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};

export type BadgeSvg = {
  __typename?: 'BadgeSvg';
  name: Scalars['String']['output'];
  svg: Scalars['String']['output'];
};

export type Brand = {
  __typename?: 'Brand';
  about?: Maybe<Scalars['String']['output']>;
  accept_contract?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<Scalars['Json']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  category_choose?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  emailId?: Maybe<Scalars['String']['output']>;
  emailedAt?: Maybe<Scalars['DateTime']['output']>;
  hasShopify?: Maybe<Scalars['Boolean']['output']>;
  hubspot_id?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  isFrench?: Maybe<Scalars['Boolean']['output']>;
  is_autoentrepreneur?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notifEmail?: Maybe<Scalars['Boolean']['output']>;
  notifSms?: Maybe<Scalars['Boolean']['output']>;
  plugins?: Maybe<Plugins>;
  returnAddress?: Maybe<Scalars['Json']['output']>;
  sale_objectif_name?: Maybe<Scalars['String']['output']>;
  sales?: Maybe<Array<Maybe<Sale>>>;
  shipping_restricted_to_france?: Maybe<Scalars['Boolean']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type BrandUpdate = {
  brand: BrandUpdateJson;
  saleId?: InputMaybe<Scalars['String']['input']>;
};

export type BrandUpdateAddress = {
  bp?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
};

export type BrandUpdateJson = {
  about?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<BrandUpdateAddress>;
  category?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  contact_phone?: InputMaybe<Scalars['String']['input']>;
  has_shopify?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  instagram?: InputMaybe<Scalars['String']['input']>;
  is_autoentrepreneur?: InputMaybe<Scalars['Boolean']['input']>;
  is_french?: InputMaybe<Scalars['Boolean']['input']>;
  max_delivery_delay?: InputMaybe<Scalars['Int']['input']>;
  min_delivery_delay?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sale_objectif_name?: InputMaybe<Scalars['String']['input']>;
  shipping_restricted_to_france?: InputMaybe<Scalars['Boolean']['input']>;
  store_id?: InputMaybe<StoreRegion>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type Canvas = {
  color?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['Float']['input'];
  rotate?: InputMaybe<Scalars['Float']['input']>;
  width: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type CashbackPolicy = {
  __typename?: 'CashbackPolicy';
  delayInDays: Scalars['Int']['output'];
  thresholds: Array<CashbackThreshold>;
};

export type CashbackPolicyInput = {
  delayInDays: Scalars['Int']['input'];
  thresholds: Array<CashbackThresholdInput>;
};

export type CashbackThreshold = {
  __typename?: 'CashbackThreshold';
  cashbackAmount: Scalars['Int']['output'];
  threshold: Scalars['Int']['output'];
};

export type CashbackThresholdInput = {
  cashbackAmount: Scalars['Int']['input'];
  threshold: Scalars['Int']['input'];
};

export type Category = {
  __typename?: 'Category';
  category: Scalars['String']['output'];
  ids: Array<Scalars['String']['output']>;
  sub_categories: Array<SubCategory>;
};

export type CategoryOption = {
  __typename?: 'CategoryOption';
  placeholders: Array<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type CategoryTree = {
  __typename?: 'CategoryTree';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  notReturnable: Scalars['Boolean']['output'];
  subCategories?: Maybe<Array<CategoryTree>>;
  tva?: Maybe<Scalars['Float']['output']>;
};

export enum CodeType {
  Amount = 'AMOUNT',
  FreeShipping = 'FREE_SHIPPING',
  GiftCard = 'GIFT_CARD',
  Percentage = 'PERCENTAGE',
}

export type CopyBrandParams = {
  shopifyPlugin: Scalars['Boolean']['input'];
  storyTelling: Scalars['Boolean']['input'];
  translateStoryTelling: Scalars['Boolean']['input'];
};

export type CopyProductsParams = {
  /** Often used when copying products from one currency to another, we need to adapt the price */
  increasePriceBy?: InputMaybe<Scalars['Float']['input']>;
  removeParams?: InputMaybe<RemoveParams>;
  resetPrice?: InputMaybe<Scalars['Boolean']['input']>;
  shippingDelaysValue?: InputMaybe<Scalars['Int']['input']>;
  shippingFeesValue?: InputMaybe<Scalars['Int']['input']>;
  stockValue?: InputMaybe<Scalars['Int']['input']>;
  /** Products data */
  translateProductsDescription: Scalars['Boolean']['input'];
  translateProductsName: Scalars['Boolean']['input'];
  translateProductsOptions: Scalars['Boolean']['input'];
};

export type CopySaleParams = {
  brands?: InputMaybe<CopyBrandParams>;
  images: Scalars['Boolean']['input'];
  previews: Scalars['Boolean']['input'];
  shippingValue?: InputMaybe<Scalars['Int']['input']>;
  translateChooseCategory: Scalars['Boolean']['input'];
};

export type Coupon = {
  __typename?: 'Coupon';
  code?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['DateTime']['output']>;
  first_order_only?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  minimum_order_amount?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  quantity_per_user?: Maybe<Scalars['Int']['output']>;
  start_at?: Maybe<Scalars['DateTime']['output']>;
  store_id?: Maybe<StoreRegion>;
  total_used?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<CodeType>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum CouponStatus {
  Active = 'ACTIVE',
  All = 'ALL',
  Expired = 'EXPIRED',
  Programmated = 'PROGRAMMATED',
}

export type CouponUpdate = {
  data?: InputMaybe<CouponUpdateInput>;
};

export type CouponUpdateInput = {
  code: Scalars['String']['input'];
  expires_at?: InputMaybe<Scalars['String']['input']>;
  first_order_only?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  minimum_order_amount?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  quantity_per_user?: InputMaybe<Scalars['Int']['input']>;
  start_at?: InputMaybe<Scalars['String']['input']>;
  store_id: StoreRegion;
  type?: InputMaybe<CodeType>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type CrawlDriveUrlDataInput = {
  googleDriveUrl: Scalars['String']['input'];
  gtin?: InputMaybe<Scalars['String']['input']>;
  productName: Scalars['String']['input'];
  sku?: InputMaybe<Scalars['String']['input']>;
};

export type CrawlingDriveProductImage = {
  __typename?: 'CrawlingDriveProductImage';
  images: Array<Scalars['String']['output']>;
  productName: Scalars['String']['output'];
};

/** *** Represent the "Detail" scope of a product */
export type CustomField = {
  __typename?: 'CustomField';
  /** id of said custom field */
  key: Scalars['ID']['output'];
  /** position of said custom field */
  position: Scalars['Int']['output'];
  /** type of said custom field */
  type?: Maybe<Scalars['String']['output']>;
  /** name of the custom field. ie: Parfum */
  value: Scalars['String']['output'];
};

export type CustomFieldInput = {
  /** id of said custom field */
  key: Scalars['ID']['input'];
  /** position of said custom field */
  position: Scalars['Int']['input'];
  /** type of the custom field */
  type?: InputMaybe<Scalars['String']['input']>;
  /** name of the custom field. ie: Parfum */
  value: Scalars['String']['input'];
};

export type DateRange = {
  __typename?: 'DateRange';
  endAt: Scalars['DateTime']['output'];
  startAt: Scalars['DateTime']['output'];
};

export type ErrorLike = {
  __typename?: 'ErrorLike';
  message: Scalars['String']['output'];
};

export enum FileUploadType {
  Product = 'PRODUCT',
  Sale = 'SALE',
}

export type GiveawayPolicyInput = {
  overOrderAmount: Scalars['Int']['input'];
  productId: Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
};

export type GiveawaySalePolicy = {
  __typename?: 'GiveawaySalePolicy';
  /** When null, the giveaway policy is active all sale's durations */
  dateRange?: Maybe<DateRange>;
  id: Scalars['ID']['output'];
  overOrderAmount: Scalars['Int']['output'];
  productId: Scalars['ID']['output'];
  saleId: Scalars['ID']['output'];
};

export type IaTransform = {
  expand?: InputMaybe<Scalars['Boolean']['input']>;
  removeBackground?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Image = {
  __typename?: 'Image';
  baseKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
};

export type ImageAdded = {
  __typename?: 'ImageAdded';
  image_storage_path_key: Scalars['String']['output'];
  image_updated_url: Scalars['String']['output'];
  /** @deprecated image_storage_path_key */
  key: Scalars['String']['output'];
  source_store_path_key: Scalars['String']['output'];
  source_url: Scalars['String']['output'];
  /** @deprecated image_updated_url */
  url: Scalars['String']['output'];
};

export enum ImageAspect {
  Landscape = 'landscape',
  Portrait = 'portrait',
  Square = 'square',
}

export type ImageData = {
  baseKey?: InputMaybe<Scalars['String']['input']>;
  canvas?: InputMaybe<Canvas>;
  defaultSize?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  itemId?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  properties?: InputMaybe<Scalars['Json']['input']>;
  type: ImageDataType;
  version?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export enum ImageDataType {
  Preview = 'preview',
  Product = 'product',
  Sale = 'sale',
  Size = 'size',
}

export type ImageDimensions = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type ImageInput = {
  id: Scalars['ID']['input'];
  url: Scalars['URL']['input'];
};

export type ImagePreview = {
  __typename?: 'ImagePreview';
  url: Scalars['String']['output'];
};

export type ImagePreviewReturn = ErrorLike | ImagePreview;

export type ImageProccessed = {
  __typename?: 'ImageProccessed';
  /** id sent by the client to the related mutation */
  actionId: Scalars['ID']['output'];
  /** id of the processed image */
  id: Scalars['ID']['output'];
  /** new processed image url */
  url: Scalars['URL']['output'];
};

export type ImageSaveReturn = ErrorLike | ImageAdded;

export type ImageTransformation = {
  canvas?: InputMaybe<Canvas>;
  ia?: InputMaybe<IaTransform>;
};

export type Invoice = {
  __typename?: 'Invoice';
  invoice_amount?: Maybe<Scalars['String']['output']>;
  invoice_by?: Maybe<Scalars['String']['output']>;
  invoice_on?: Maybe<Scalars['DateTime']['output']>;
  is_invoice_ready?: Maybe<Scalars['Boolean']['output']>;
  paid_amount?: Maybe<Scalars['Int']['output']>;
  paid_by?: Maybe<Scalars['String']['output']>;
  paid_on?: Maybe<Scalars['DateTime']['output']>;
  proof_paid_url?: Maybe<Scalars['String']['output']>;
};

export type LastNotification = {
  __typename?: 'LastNotification';
  analytics?: Maybe<Array<NotificationAnalytics>>;
  notification?: Maybe<Notification>;
};

export type MarketingQueries = {
  __typename?: 'MarketingQueries';
  getReferralUploadUrl?: Maybe<UploadUrl>;
  referralBlock?: Maybe<MarketingReferralBlock>;
};

export type MarketingQueriesGetReferralUploadUrlArgs = {
  fileType: Scalars['String']['input'];
  saleId: Scalars['String']['input'];
  uploadType: MarketingReferralImageType;
};

export type MarketingQueriesReferralBlockArgs = {
  store: StoreRegion;
};

export type MarketingReferralBlock = {
  __typename?: 'MarketingReferralBlock';
  bigImage: Scalars['String']['output'];
  btnCta: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  smallImage: Scalars['String']['output'];
  tagline: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum MarketingReferralImageType {
  BigImage = 'bigImage',
  SmallImage = 'smallImage',
}

export enum MarketingType {
  Bulletpoint = 'BULLETPOINT',
  Rsvp = 'RSVP',
  Selection = 'SELECTION',
}

export type MondialRelayData = {
  __typename?: 'MondialRelayData';
  shippingCost: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Add a giveaway policy to a sale
   * If it's a success, it will return `Success`
   * If it's a failure, it will return an error message
   */
  addGiveawayPolicy: Scalars['String']['output'];
  /** @deprecated use `newAddImage` */
  addImage?: Maybe<Scalars['Json']['output']>;
  addImagesToProducts?: Maybe<Scalars['Void']['output']>;
  addProductCouponsV2?: Maybe<Scalars['Void']['output']>;
  addProductsBadges?: Maybe<Scalars['Void']['output']>;
  addSizeGuidesToProducts?: Maybe<Scalars['Void']['output']>;
  addTextToProductsDescription?: Maybe<Scalars['Void']['output']>;
  addTextToProductsName?: Maybe<Scalars['Void']['output']>;
  changeProductOptionToColor?: Maybe<Scalars['Void']['output']>;
  copyBrandV2?: Maybe<Scalars['String']['output']>;
  copyProductV2: Scalars['ID']['output'];
  copyProductsV2?: Maybe<Scalars['String']['output']>;
  /** ** Full duplication ** */
  copySale?: Maybe<Scalars['String']['output']>;
  /** ** Useful for copying products from one sale to another ** */
  copySaleV2?: Maybe<Scalars['String']['output']>;
  crawlDriveImages: Array<CrawlingDriveProductImage>;
  crawlProducts?: Maybe<Scalars['String']['output']>;
  createDurationWidget?: Maybe<Scalars['String']['output']>;
  createLogisticFile?: Maybe<Scalars['String']['output']>;
  /**
   * WIP - Create a product with a new domain
   * input types are not defined yet.
   */
  createProduct?: Maybe<Scalars['String']['output']>;
  /**
   * Delete all giveaway policies for a sale
   * If it's a success, it will return `Success`
   * If it's a failure, it will return an error message
   */
  deleteAllGiveawayPolicies?: Maybe<Scalars['String']['output']>;
  deleteDealToCalendar?: Maybe<Scalars['String']['output']>;
  fillImages?: Maybe<Scalars['Void']['output']>;
  fitImages?: Maybe<Scalars['Void']['output']>;
  hideOutOfStockProductsAndVariants?: Maybe<Scalars['Void']['output']>;
  importProducts?: Maybe<Scalars['String']['output']>;
  markSaleTrackingsAsDelivered?: Maybe<Scalars['String']['output']>;
  newAddImage: ImageAdded;
  previewImage: ImagePreviewReturn;
  removeBackgroundImages?: Maybe<Scalars['Void']['output']>;
  removeCoupon?: Maybe<Scalars['String']['output']>;
  removeGtins?: Maybe<Scalars['Void']['output']>;
  removeProductCoupons?: Maybe<Scalars['String']['output']>;
  removeProducts?: Maybe<Scalars['String']['output']>;
  removeProductsSizeGuide?: Maybe<Scalars['Void']['output']>;
  removeSale?: Maybe<Scalars['String']['output']>;
  removeSkus?: Maybe<Scalars['Void']['output']>;
  removeUser?: Maybe<Scalars['Json']['output']>;
  removeWidget?: Maybe<Scalars['String']['output']>;
  replaceProductsDescription?: Maybe<Scalars['Void']['output']>;
  replaceProductsName?: Maybe<Scalars['Void']['output']>;
  saveImage: ImageSaveReturn;
  saveImages?: Maybe<Scalars['Void']['output']>;
  /** Braze will send a preview a email */
  sendMarketingEmail?: Maybe<Scalars['String']['output']>;
  sendNotification?: Maybe<Scalars['String']['output']>;
  sendOnboardingEmail?: Maybe<Scalars['String']['output']>;
  setProductsAsUnique?: Maybe<Scalars['Void']['output']>;
  setProductsHide?: Maybe<Scalars['Void']['output']>;
  setShopifyStockProtection?: Maybe<Scalars['Void']['output']>;
  setUserRole?: Maybe<Scalars['Json']['output']>;
  showHiddenProductsAndVariants?: Maybe<Scalars['Void']['output']>;
  shuffleProductList?: Maybe<Scalars['Void']['output']>;
  transformImage?: Maybe<Scalars['String']['output']>;
  updateAccentuateReferral?: Maybe<Scalars['String']['output']>;
  updateBrand?: Maybe<Scalars['String']['output']>;
  updateCoupon?: Maybe<Scalars['String']['output']>;
  updateDealKey?: Maybe<Scalars['String']['output']>;
  updateImage: ImageAdded;
  updateInvoice?: Maybe<Scalars['String']['output']>;
  updateManyProduct?: Maybe<Scalars['Void']['output']>;
  updateManyProductOptions?: Maybe<Array<ProductOptionError>>;
  updateProductV3: ProductV3;
  /**
   * WIP - update a product with a new domain
   * return type is not defined yet.
   */
  updateProductV4: ProductV3;
  /** @deprecated need to create a update position function */
  updateProducts?: Maybe<Scalars['String']['output']>;
  updateProductsBuyingPrice?: Maybe<Scalars['Void']['output']>;
  updateProductsCategory?: Maybe<Scalars['Void']['output']>;
  updateProductsChooseFees?: Maybe<Scalars['Void']['output']>;
  updateProductsCouponExpiresAt?: Maybe<Scalars['Void']['output']>;
  updateProductsDelivery?: Maybe<Scalars['Void']['output']>;
  updateProductsGender?: Maybe<Scalars['Void']['output']>;
  updateProductsOriginCountry?: Maybe<Scalars['Void']['output']>;
  updateProductsRetailPrice?: Maybe<Scalars['Void']['output']>;
  updateProductsReturnEligibility?: Maybe<Scalars['Void']['output']>;
  updateProductsShippingCost?: Maybe<Scalars['Void']['output']>;
  updateSale?: Maybe<Scalars['String']['output']>;
  /** **Batch update of references (sku or gtin) for a specific sale** */
  updateStocksByRef: Array<VariantUpdated>;
  /** **Batch update of references (sku or gtin) for a specific sale** */
  updateVariantsByRef: Array<VariantUpdated>;
};

export type MutationAddGiveawayPolicyArgs = {
  input: GiveawayPolicyInput;
};

export type MutationAddImageArgs = {
  image: ImageData;
  upload?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationAddImagesToProductsArgs = {
  images: Array<Scalars['String']['input']>;
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationAddProductCouponsV2Args = {
  codes: Array<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

export type MutationAddProductsBadgesArgs = {
  badges: Array<ProductBadgeInput>;
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationAddSizeGuidesToProductsArgs = {
  productIds: Array<Scalars['ID']['input']>;
  sizeGuides: Array<Scalars['String']['input']>;
};

export type MutationAddTextToProductsDescriptionArgs = {
  payload: AddTextPayload;
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationAddTextToProductsNameArgs = {
  payload: AddTextPayload;
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationChangeProductOptionToColorArgs = {
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationCopyBrandV2Args = {
  brandIdFrom: Scalars['String']['input'];
  brandIdTo: Scalars['String']['input'];
  params: CopyBrandParams;
};

export type MutationCopyProductV2Args = {
  keepImages: Scalars['Boolean']['input'];
  productId: Scalars['ID']['input'];
};

export type MutationCopyProductsV2Args = {
  params: CopyProductsParams;
  saleIdFrom: Scalars['String']['input'];
  saleIdTo: Scalars['String']['input'];
};

export type MutationCopySaleArgs = {
  saleId: Scalars['String']['input'];
};

export type MutationCopySaleV2Args = {
  params: CopySaleParams;
  saleIdFrom: Scalars['String']['input'];
  saleIdTo: Scalars['String']['input'];
};

export type MutationCrawlDriveImagesArgs = {
  urlData: Array<CrawlDriveUrlDataInput>;
};

export type MutationCrawlProductsArgs = {
  saleId: Scalars['String']['input'];
  urls: Array<Scalars['String']['input']>;
};

export type MutationCreateDurationWidgetArgs = {
  saleId: Scalars['String']['input'];
};

export type MutationCreateLogisticFileArgs = {
  brandId?: InputMaybe<Scalars['String']['input']>;
  saleId: Scalars['String']['input'];
};

export type MutationCreateProductArgs = {
  data: Scalars['Json']['input'];
};

export type MutationDeleteAllGiveawayPoliciesArgs = {
  saleId: Scalars['ID']['input'];
};

export type MutationDeleteDealToCalendarArgs = {
  dataKey?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type MutationFillImagesArgs = {
  actionId: Scalars['ID']['input'];
  images: Array<ImageInput>;
  sessionId: Scalars['ID']['input'];
};

export type MutationFitImagesArgs = {
  actionId: Scalars['ID']['input'];
  images: Array<ImageInput>;
  sessionId: Scalars['ID']['input'];
};

export type MutationHideOutOfStockProductsAndVariantsArgs = {
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationImportProductsArgs = {
  csvContent?: InputMaybe<Scalars['String']['input']>;
  mockData?: InputMaybe<Scalars['Boolean']['input']>;
  saleId: Scalars['String']['input'];
  sync?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMarkSaleTrackingsAsDeliveredArgs = {
  filters: MarkSaleTrackingsAsDeliveredFilter;
  saleId: Scalars['String']['input'];
};

export type MutationNewAddImageArgs = {
  image: ImageData;
  upload: Scalars['Upload']['input'];
};

export type MutationPreviewImageArgs = {
  image: TransformImageData;
};

export type MutationRemoveBackgroundImagesArgs = {
  actionId: Scalars['ID']['input'];
  images: Array<ImageInput>;
  sessionId: Scalars['ID']['input'];
};

export type MutationRemoveCouponArgs = {
  couponId: Scalars['Int']['input'];
};

export type MutationRemoveGtinsArgs = {
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationRemoveProductCouponsArgs = {
  productId: Scalars['String']['input'];
  saleId: Scalars['String']['input'];
};

export type MutationRemoveProductsArgs = {
  productsIds: Array<InputMaybe<Scalars['String']['input']>>;
  saleId: Scalars['String']['input'];
};

export type MutationRemoveProductsSizeGuideArgs = {
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationRemoveSaleArgs = {
  saleId: Scalars['String']['input'];
};

export type MutationRemoveSkusArgs = {
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationRemoveUserArgs = {
  userId: Scalars['String']['input'];
};

export type MutationRemoveWidgetArgs = {
  id: Scalars['String']['input'];
  saleId: Scalars['String']['input'];
};

export type MutationReplaceProductsDescriptionArgs = {
  payload: Array<ReplacePayload>;
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationReplaceProductsNameArgs = {
  payload: Array<ReplacePayload>;
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationSaveImageArgs = {
  data: SaveImage;
};

export type MutationSaveImagesArgs = {
  actionId: Scalars['ID']['input'];
  images: Array<ImageInput>;
  options?: InputMaybe<SaveImagesOptions>;
  sessionId: Scalars['ID']['input'];
};

export type MutationSendMarketingEmailArgs = {
  type?: InputMaybe<MarketingType>;
  url: Scalars['String']['input'];
  userAliasId: UserAliasBraze;
  userEmail: Scalars['String']['input'];
};

export type MutationSendNotificationArgs = {
  data: NotificationInput;
};

export type MutationSendOnboardingEmailArgs = {
  brandId: Scalars['String']['input'];
};

export type MutationSetProductsAsUniqueArgs = {
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationSetProductsHideArgs = {
  productIds: Array<Scalars['ID']['input']>;
  value: Scalars['Boolean']['input'];
};

export type MutationSetShopifyStockProtectionArgs = {
  protect: Scalars['Boolean']['input'];
  sellerId: Scalars['ID']['input'];
};

export type MutationSetUserRoleArgs = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Scalars['Json']['input']>;
  userId: Scalars['String']['input'];
};

export type MutationShowHiddenProductsAndVariantsArgs = {
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationShuffleProductListArgs = {
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationTransformImageArgs = {
  image: TransformParamsInput;
};

export type MutationUpdateAccentuateReferralArgs = {
  data: AccentuateReferralInput;
};

export type MutationUpdateBrandArgs = {
  data?: InputMaybe<BrandUpdate>;
};

export type MutationUpdateCouponArgs = {
  data: CouponUpdate;
};

export type MutationUpdateDealKeyArgs = {
  data?: InputMaybe<Scalars['Json']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateImageArgs = {
  image: ImageData;
};

export type MutationUpdateInvoiceArgs = {
  brandId?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['Json']['input']>;
  saleId: Scalars['String']['input'];
};

export type MutationUpdateManyProductArgs = {
  data: Array<UpdateProductManyInput>;
};

export type MutationUpdateManyProductOptionsArgs = {
  data: Array<UpdateProductOptionManyInput>;
  saleId: Scalars['String']['input'];
};

export type MutationUpdateProductV3Args = {
  data: ProductUpdateInputV3;
};

export type MutationUpdateProductV4Args = {
  data: ProductUpdateInputV3;
};

export type MutationUpdateProductsArgs = {
  data?: InputMaybe<ProductsUpdate>;
};

export type MutationUpdateProductsBuyingPriceArgs = {
  productIds: Array<Scalars['ID']['input']>;
  value: Scalars['Float']['input'];
};

export type MutationUpdateProductsCategoryArgs = {
  category: Scalars['String']['input'];
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationUpdateProductsChooseFeesArgs = {
  fees: Scalars['Float']['input'];
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationUpdateProductsCouponExpiresAtArgs = {
  productIds: Array<Scalars['ID']['input']>;
  value: Scalars['LocalDate']['input'];
};

export type MutationUpdateProductsDeliveryArgs = {
  productIds: Array<Scalars['ID']['input']>;
  value: Scalars['Boolean']['input'];
};

export type MutationUpdateProductsGenderArgs = {
  productIds: Array<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateProductsOriginCountryArgs = {
  productIds: Array<Scalars['ID']['input']>;
  value: Scalars['String']['input'];
};

export type MutationUpdateProductsRetailPriceArgs = {
  productIds: Array<Scalars['ID']['input']>;
  value: Scalars['Int']['input'];
};

export type MutationUpdateProductsReturnEligibilityArgs = {
  isReturnable: Scalars['Boolean']['input'];
  productIds: Array<Scalars['ID']['input']>;
};

export type MutationUpdateProductsShippingCostArgs = {
  productIds: Array<Scalars['ID']['input']>;
  value: Scalars['Float']['input'];
};

export type MutationUpdateSaleArgs = {
  brandNameChanged?: InputMaybe<Scalars['Boolean']['input']>;
  sale: SaleInput;
};

export type MutationUpdateStocksByRefArgs = {
  saleId: Scalars['String']['input'];
  stocks: Array<StockUpdateByRef>;
};

export type MutationUpdateVariantsByRefArgs = {
  saleId: Scalars['String']['input'];
  variants: Array<VariantUpdateByRef>;
};

export type Notification = {
  __typename?: 'Notification';
  brand_name?: Maybe<Scalars['String']['output']>;
  for_subscribers?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<Scalars['String']['output']>;
  send_at?: Maybe<Scalars['DateTime']['output']>;
  stats?: Maybe<Scalars['Json']['output']>;
  store_id?: Maybe<StoreRegion>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NotificationAnalytics = {
  __typename?: 'NotificationAnalytics';
  conversions?: Maybe<Scalars['Int']['output']>;
  conversions1?: Maybe<Scalars['Int']['output']>;
  conversions1_by_send_time?: Maybe<Scalars['Int']['output']>;
  conversions2?: Maybe<Scalars['Int']['output']>;
  conversions2_by_send_time?: Maybe<Scalars['Int']['output']>;
  conversions3?: Maybe<Scalars['Int']['output']>;
  conversions3_by_send_time?: Maybe<Scalars['Int']['output']>;
  conversions_by_send_time?: Maybe<Scalars['Int']['output']>;
  messages?: Maybe<NotificationAnalyticsMessages>;
  revenue?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  unique_recipients?: Maybe<Scalars['Int']['output']>;
};

export type NotificationAnalyticsMessagePush = {
  __typename?: 'NotificationAnalyticsMessagePush';
  body_clicks?: Maybe<Scalars['Int']['output']>;
  bounces?: Maybe<Scalars['Int']['output']>;
  direct_opens?: Maybe<Scalars['Int']['output']>;
  sent?: Maybe<Scalars['Int']['output']>;
  total_opens?: Maybe<Scalars['Int']['output']>;
  variation_api_id?: Maybe<Scalars['String']['output']>;
};

export type NotificationAnalyticsMessages = {
  __typename?: 'NotificationAnalyticsMessages';
  android_push?: Maybe<Array<Maybe<NotificationAnalyticsMessagePush>>>;
  ios_push?: Maybe<Array<Maybe<NotificationAnalyticsMessagePush>>>;
};

export type NotificationInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  disableSmartNotification?: InputMaybe<Scalars['Boolean']['input']>;
  onlySubscribers?: InputMaybe<Scalars['Boolean']['input']>;
  operator?: InputMaybe<Scalars['String']['input']>;
  saleId: Scalars['String']['input'];
  store: StoreRegion;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** **Pickup point network** */
export enum PickupPointNetwork {
  /** **Colissimo** */
  Colissimo = 'COLISSIMO',
  /** **Mondial relay** */
  MondialRelay = 'MONDIAL_RELAY',
  /** **Shop 2 shop** */
  Shop2Shop = 'SHOP2SHOP',
}

export type Plugins = {
  __typename?: 'Plugins';
  shopify?: Maybe<Scalars['Json']['output']>;
};

export type Preview = {
  __typename?: 'Preview';
  baseKey: Scalars['String']['output'];
  full?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
};

export type PreviewPropertie = {
  id: Scalars['String']['input'];
  properties?: InputMaybe<PreviewProperties>;
};

export type PreviewProperties = {
  full?: InputMaybe<Scalars['Boolean']['input']>;
};

/** *** Represent the badges of a product (ie: Made in XXX, vegan, ...) */
export type ProductBadge = {
  __typename?: 'ProductBadge';
  isSpecial: Scalars['Boolean']['output'];
  position: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type ProductBadgeInput = {
  /** is this badge special */
  isSpecial: Scalars['Boolean']['input'];
  /** position of said badge */
  position: Scalars['Int']['input'];
  /** Badge label */
  value: Scalars['String']['input'];
};

export type ProductBrand = {
  __typename?: 'ProductBrand';
  about: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  name: Scalars['String']['output'];
  vat: Scalars['Float']['output'];
};

export type ProductCoupon = {
  __typename?: 'ProductCoupon';
  code: Scalars['String']['output'];
  order_id?: Maybe<Scalars['String']['output']>;
  product_id: Scalars['String']['output'];
};

export type ProductImage = {
  __typename?: 'ProductImage';
  aspect?: Maybe<ImageAspect>;
  currentVersion: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isCover: Scalars['Boolean']['output'];
  isOriginal: Scalars['Boolean']['output'];
  isVariantCover: Scalars['Boolean']['output'];
  originalUrl: Scalars['URL']['output'];
  productId: Scalars['ID']['output'];
  url: Scalars['URL']['output'];
};

export type ProductOptionError = {
  __typename?: 'ProductOptionError';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type ProductOptionInput = {
  /** Product option id */
  id: Scalars['ID']['input'];
  /** Product option images */
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Product option name */
  name: Scalars['String']['input'];
  /** Product option position */
  position: Scalars['Int']['input'];
  /** Product option type */
  type: Scalars['String']['input'];
  /** Product option values */
  values: Array<ProductOptionValueInput>;
};

export type ProductOptionV2 = {
  __typename?: 'ProductOptionV2';
  /** Product option id */
  id: Scalars['ID']['output'];
  /** Product option name */
  name: Scalars['String']['output'];
  /** Product option position */
  position: Scalars['Int']['output'];
  /** Product option type */
  type?: Maybe<Scalars['String']['output']>;
  /** Product option values */
  values: Array<ProductOptionValueV2>;
};

export type ProductOptionV3 = {
  __typename?: 'ProductOptionV3';
  /** Product option id */
  id: Scalars['ID']['output'];
  /** Product option images */
  images?: Maybe<Array<Image>>;
  /** Product option name */
  name: Scalars['String']['output'];
  /** Product option position */
  position: Scalars['Int']['output'];
  /** Product option type */
  type?: Maybe<Scalars['String']['output']>;
  /** Product option values */
  values: Array<ProductOptionValueV2>;
};

export type ProductOptionValueInput = {
  id: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type ProductOptionValueV2 = {
  __typename?: 'ProductOptionValueV2';
  /** Product option value id */
  id: Scalars['ID']['output'];
  /** Product option value position. */
  position: Scalars['Int']['output'];
  /** Product option value displayed value. ie: blue when the main option is color */
  value: Scalars['String']['output'];
};

export enum ProductType {
  Coupon = 'coupon',
  Object = 'object',
}

export type ProductUpdateInputV3 = {
  badges: Array<ProductBadgeInput>;
  brandId?: InputMaybe<Scalars['ID']['input']>;
  category: Scalars['String']['input'];
  cgu?: InputMaybe<Array<Scalars['String']['input']>>;
  couponExpireAt?: InputMaybe<Scalars['LocalDate']['input']>;
  couponOffer?: InputMaybe<Scalars['String']['input']>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  faq?: InputMaybe<Array<Scalars['String']['input']>>;
  faqAfterOrder?: InputMaybe<Scalars['String']['input']>;
  faqBeforeOrder?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  hide: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  isCobrand?: InputMaybe<Scalars['Boolean']['input']>;
  isDeliveredByChoose?: InputMaybe<Scalars['Boolean']['input']>;
  isDraft: Scalars['Boolean']['input'];
  isReturnable?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  options?: InputMaybe<Array<ProductOptionInput>>;
  variants: Array<VariantToUpsert>;
};

export type ProductV3 = {
  __typename?: 'ProductV3';
  badges: Array<ProductBadge>;
  brand: ProductBrand;
  category?: Maybe<ProductCategory>;
  cgu?: Maybe<Array<Scalars['String']['output']>>;
  couponDetail?: Maybe<Scalars['String']['output']>;
  couponExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency: StoreCurrency;
  customFields?: Maybe<Array<CustomField>>;
  description?: Maybe<Scalars['String']['output']>;
  faq?: Maybe<Array<Scalars['String']['output']>>;
  faqPostOrder?: Maybe<Scalars['String']['output']>;
  faqPreOrder?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  hide: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Image>>;
  importCsvPosition?: Maybe<Scalars['Int']['output']>;
  isAlcohol?: Maybe<Scalars['Boolean']['output']>;
  isCobrand: Scalars['Boolean']['output'];
  isDeliveredByChoose?: Maybe<Scalars['Boolean']['output']>;
  isDraft: Scalars['Boolean']['output'];
  isGiveaway: Scalars['Boolean']['output'];
  isReturnable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** Product options */
  options?: Maybe<Array<ProductOptionV3>>;
  position?: Maybe<Scalars['Int']['output']>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  type: ProductType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Product variants */
  variants: Array<ProductVariantV2>;
};

export type ProductVariantOptionInput = {
  optionId: Scalars['ID']['input'];
  valueId: Scalars['ID']['input'];
};

export type ProductVariantOptionV2 = {
  __typename?: 'ProductVariantOptionV2';
  /** Id refering to a product option */
  productOptionId: Scalars['String']['output'];
  /** id of a production option value */
  valueId: Scalars['String']['output'];
};

export type ProductVariantV2 = {
  __typename?: 'ProductVariantV2';
  /**
   * TBD
   * @deprecated not used anymore
   */
  absorbedShipping?: Maybe<Scalars['Int']['output']>;
  /** Product variant price choose pay */
  buyingPrice?: Maybe<Scalars['Int']['output']>;
  /** Choose fees */
  chooseFees?: Maybe<Scalars['Int']['output']>;
  /** Time computed for the product to be shipped (based on product.delivery and sale.delivery) */
  computedDeliveryDelays?: Maybe<Scalars['Int']['output']>;
  /** Time set for the product to be shipped */
  deliveryDelays?: Maybe<Scalars['Int']['output']>;
  /** discount percentage express as thousand. ie: 20% = 2000 */
  discountPercentage?: Maybe<Scalars['Int']['output']>;
  /** Eco tax for the product */
  eeePrice?: Maybe<Scalars['Int']['output']>;
  /** Product variant gtin */
  gtin?: Maybe<Scalars['String']['output']>;
  /** height of the product in millimeters */
  heightMillimeter?: Maybe<Scalars['Int']['output']>;
  /** TBD */
  hide: Scalars['Boolean']['output'];
  /** Product variant id */
  id: Scalars['ID']['output'];
  /** Product variant images */
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** is the variant unique */
  isUnique: Scalars['Boolean']['output'];
  /** length of the product in millimeters */
  lengthMillimeter?: Maybe<Scalars['Int']['output']>;
  /** Product variant options */
  options: Array<ProductVariantOptionV2>;
  /** where the variant is made */
  originCountry?: Maybe<Scalars['String']['output']>;
  /** Product variant position */
  position?: Maybe<Scalars['Int']['output']>;
  /** Product variant retail price (public price) */
  retailPrice?: Maybe<Scalars['Int']['output']>;
  /** Fees to ship */
  shippingFees?: Maybe<Scalars['Int']['output']>;
  /** Product variant sku */
  sku?: Maybe<Scalars['String']['output']>;
  /** Product variant stock count. If null means there is unlimited stock */
  stockCount?: Maybe<Scalars['Int']['output']>;
  /** weight of the product in gramms */
  weightGram?: Maybe<Scalars['Int']['output']>;
  /** width of the product in millimeters */
  widthMillimeter?: Maybe<Scalars['Int']['output']>;
};

export type ProductsUpdate = {
  ids: Array<InputMaybe<Scalars['String']['input']>>;
  key: Scalars['String']['input'];
  newTva?: InputMaybe<Scalars['Float']['input']>;
  saleId: Scalars['String']['input'];
  values?: InputMaybe<Scalars['String']['input']>;
};

/** **Provisional sale (a sale that is still in negociation)** */
export type ProvisionalSale = {
  __typename?: 'ProvisionalSale';
  /** Does the brand have a shopify ? */
  brandHasShopify?: Maybe<Scalars['Boolean']['output']>;
  /**
   * **Count of sales for the brand**
   *
   * - > null if brand hubspot id is not present
   */
  brandSaleCount?: Maybe<Scalars['Int']['output']>;
  /**
   * **Cancellation level of the sale**
   *
   * - > high
   */
  cancellationLevel?: Maybe<Scalars['String']['output']>;
  /** **Category of the sale** */
  category?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  forecast?: Maybe<Scalars['Int']['output']>;
  /**
   * **Gender of the sale**
   *
   * - > 0 => female
   * - > 1 => male
   * - > 2 => unisex
   */
  gender?: Maybe<Scalars['Int']['output']>;
  /** **Hubspot category of the sale** */
  hubspotCategory?: Maybe<Scalars['String']['output']>;
  /** **Hubspot ID of the sale** */
  hubspotId?: Maybe<Scalars['ID']['output']>;
  hubspot_team_name?: Maybe<TeamName>;
  hubspot_top_brand_type?: Maybe<TopBrandType>;
  /** **ID of the sale** */
  id: Scalars['ID']['output'];
  /** **Instagram ID of the sale (or brand)** */
  instagramId?: Maybe<Scalars['String']['output']>;
  /** **Name of the sale** */
  name: Scalars['String']['output'];
  /** **Note of the sale (text for choose members)** */
  note?: Maybe<Scalars['String']['output']>;
  /**
   * **Operation type of the sale**
   *
   * - > dropshipping
   * - > firm_purchase
   * - > post_operation_purchase
   * - > reseller
   * - > clearance
   */
  operationType?: Maybe<Scalars['String']['output']>;
  /** **Name of the Choose sale team member assigned to the sale** */
  saleBy?: Maybe<Scalars['String']['output']>;
  shopify?: Maybe<ShopifyData>;
  /** **Date when the sale should start** */
  startsAt: Scalars['DateTime']['output'];
  /** **Website URL of the sale (or brand)** */
  website?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** *** Get all the badges sorted for the selected region */
  badges: Array<BadgeSvg>;
  brand?: Maybe<Brand>;
  categories: Array<Category>;
  /** **Get the category's essential options** */
  categoryEssentialOptions: Array<CategoryOption>;
  /** **Get the category's options** */
  categoryOptions: Array<CategoryOption>;
  /** **Get the categories and their sub categories as a tree list** */
  categoryTree: Array<CategoryTree>;
  coupons: Array<Coupon>;
  /** **Get the PDF of an oder. If forBrand is set we only get the order without credit/cash options, often useful for Brands and shipments ** */
  createInvoice?: Maybe<Scalars['String']['output']>;
  getImagesByProductIds: Array<ProductImage>;
  getProductCoupons: Array<ProductCoupon>;
  getUploadUrl?: Maybe<Scalars['Json']['output']>;
  last_notification?: Maybe<LastNotification>;
  makePublicFile?: Maybe<Scalars['Json']['output']>;
  /** *** */
  marketing: MarketingQueries;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  /** Get one product */
  product: ProductV3;
  /** **Get provisional sales** */
  provisionalSales: Array<ProvisionalSale>;
  sale?: Maybe<Sale>;
  sales?: Maybe<SalesCache>;
  salesCount?: Maybe<Array<Maybe<SaleCount>>>;
  searchBrand: Array<Brand>;
  searchSalesES?: Maybe<SalesElasticSearch>;
  searchSalesV2: SalesElasticSearch;
  shopify: ShopifyQueries;
  users?: Maybe<Scalars['Json']['output']>;
};

export type QueryBadgesArgs = {
  storeId: StoreRegion;
};

export type QueryBrandArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCategoryEssentialOptionsArgs = {
  categoryId: Scalars['String']['input'];
};

export type QueryCategoryOptionsArgs = {
  categoryId: Scalars['String']['input'];
};

export type QueryCouponsArgs = {
  status: CouponStatus;
  store_id?: InputMaybe<StoreRegion>;
};

export type QueryCreateInvoiceArgs = {
  forBrand?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['String']['input'];
};

export type QueryGetImagesByProductIdsArgs = {
  productIds: Array<Scalars['ID']['input']>;
};

export type QueryGetProductCouponsArgs = {
  productId: Scalars['String']['input'];
};

export type QueryGetUploadUrlArgs = {
  fileType: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  saleId: Scalars['String']['input'];
  uploadType: FileUploadType;
};

export type QueryLast_NotificationArgs = {
  storeId: StoreRegion;
};

export type QueryMakePublicFileArgs = {
  url: Scalars['String']['input'];
};

export type QueryNotificationsArgs = {
  limit: Scalars['Int']['input'];
};

export type QueryProductArgs = {
  productId: Scalars['String']['input'];
};

export type QueryProvisionalSalesArgs = {
  storeId: StoreRegion;
};

export type QuerySaleArgs = {
  key: Scalars['String']['input'];
};

export type QuerySalesArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  status: SaleStatus;
  storeId: StoreRegion;
};

export type QuerySalesCountArgs = {
  storeId: StoreRegion;
};

export type QuerySearchBrandArgs = {
  name: Scalars['String']['input'];
};

export type QuerySearchSalesEsArgs = {
  name: Scalars['String']['input'];
};

export type QuerySearchSalesV2Args = {
  limit: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  store: StoreRegion;
};

export type RemoveParams = {
  minProductPrice?: InputMaybe<Scalars['Int']['input']>;
};

export type ReplacePayload = {
  new: Scalars['String']['input'];
  old: Scalars['String']['input'];
};

export type Sale = {
  __typename?: 'Sale';
  ads?: Maybe<Array<Maybe<Ad>>>;
  badges?: Maybe<Scalars['Json']['output']>;
  brand?: Maybe<Brand>;
  brandId?: Maybe<Scalars['String']['output']>;
  brand_name?: Maybe<Scalars['String']['output']>;
  cancellation_level?: Maybe<Scalars['String']['output']>;
  cashbackPolicy?: Maybe<CashbackPolicy>;
  /** @deprecated use hubspot_category instead */
  categorie_choose?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  category_choose?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<StoreCurrency>;
  deliver_by_choose?: Maybe<Scalars['Boolean']['output']>;
  delivery_delays?: Maybe<Scalars['Int']['output']>;
  delivery_offered_by?: Maybe<Scalars['String']['output']>;
  delivery_offered_until?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disable_sorting?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated use on the homepage */
  discount?: Maybe<Scalars['Float']['output']>;
  /** If the sale is the origin of duplicated sales, this field will contain the last duplicated sale id */
  duplicated_sale_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated use end_at_date */
  end_at: Scalars['DateTime']['output'];
  end_at_date: Scalars['LocalDate']['output'];
  forecast?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  /**
   * Giveaway policies for the sale.
   * Only the active giveaway policy is returned for now.
   */
  giveawayPolicies: Array<GiveawaySalePolicy>;
  /**
   * True if at least one product for sale can be returned within the 14-day
   * withdrawal period.
   */
  hasReturnableProducts: Scalars['Boolean']['output'];
  has_orders?: Maybe<Scalars['Boolean']['output']>;
  hide?: Maybe<Scalars['Boolean']['output']>;
  hubspot_category?: Maybe<Scalars['String']['output']>;
  hubspot_deal_id?: Maybe<Scalars['String']['output']>;
  hubspot_team_name?: Maybe<TeamName>;
  hubspot_top_brand_type?: Maybe<TopBrandType>;
  id: Scalars['String']['output'];
  image_header?: Maybe<Scalars['String']['output']>;
  initial_start_at?: Maybe<Scalars['DateTime']['output']>;
  instagram_followers?: Maybe<Scalars['Int']['output']>;
  invoice?: Maybe<Invoice>;
  isShopify?: Maybe<Scalars['Boolean']['output']>;
  is_cancelled?: Maybe<Scalars['Boolean']['output']>;
  is_ready?: Maybe<Scalars['Boolean']['output']>;
  is_selection?: Maybe<Scalars['Boolean']['output']>;
  is_setup?: Maybe<Scalars['Boolean']['output']>;
  logistics_manager?: Maybe<Scalars['String']['output']>;
  newImages?: Maybe<Array<SaleImage>>;
  newPreviews?: Maybe<Array<Preview>>;
  note?: Maybe<Scalars['String']['output']>;
  operation_type?: Maybe<Scalars['String']['output']>;
  original_sale_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated use on the calendarpage */
  previews?: Maybe<Scalars['Json']['output']>;
  products: Array<ProductV3>;
  rating?: Maybe<Scalars['Json']['output']>;
  sale_status?: Maybe<SaleStatusFilter>;
  selection_name?: Maybe<Scalars['String']['output']>;
  setup_by?: Maybe<Scalars['String']['output']>;
  setup_status?: Maybe<SaleSetupStatus>;
  shippingPolicy: ShippingPolicy;
  /** Shopify connection and stock info */
  shopify?: Maybe<ShopifyData>;
  /** @deprecated use start_at_date */
  start_at: Scalars['DateTime']['output'];
  start_at_date: Scalars['LocalDate']['output'];
  stats_stock?: Maybe<Scalars['Json']['output']>;
  /** @deprecated use on the salepage */
  status?: Maybe<SaleSetupStatus>;
  storeId: StoreRegion;
  store_id?: Maybe<StoreRegion>;
  total_sales?: Maybe<Scalars['Int']['output']>;
  tva?: Maybe<Scalars['Int']['output']>;
  upcoming_display_forced: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  url_google_drive?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Scalars['Json']['output']>;
  widgets?: Maybe<Array<Maybe<Widget>>>;
};

export type SaleCount = {
  __typename?: 'SaleCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export type SaleImage = {
  __typename?: 'SaleImage';
  baseKey: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  properties?: Maybe<SaleImageProperties>;
};

export type SaleImageProperties = {
  __typename?: 'SaleImageProperties';
  full?: Maybe<Scalars['Boolean']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
};

export type SaleInput = {
  badges?: InputMaybe<Array<BadgeInput>>;
  brand_id?: InputMaybe<Scalars['String']['input']>;
  cancel_reason?: InputMaybe<Scalars['String']['input']>;
  cancellation_level?: InputMaybe<Scalars['String']['input']>;
  cashbackPolicy?: InputMaybe<CashbackPolicyInput>;
  category?: InputMaybe<Scalars['String']['input']>;
  category_choose?: InputMaybe<Scalars['String']['input']>;
  check_by?: InputMaybe<Scalars['String']['input']>;
  deliver_by_choose?: InputMaybe<Scalars['Boolean']['input']>;
  delivery_delays?: InputMaybe<Scalars['Int']['input']>;
  delivery_offered_by?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disable_sorting?: InputMaybe<Scalars['Boolean']['input']>;
  end_at_date?: InputMaybe<Scalars['LocalDate']['input']>;
  fix_top?: InputMaybe<Scalars['Boolean']['input']>;
  forecast?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Giveaway policies for the sale.
   * Only one policy is accepted for now.
   * Remove all policies if empty array is provided.
   * Do nothing if null is provided.
   */
  giveawayPolicies?: InputMaybe<Array<GiveawayPolicyInput>>;
  hide?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_deal_id?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  image_header?: InputMaybe<Scalars['String']['input']>;
  initial_start_at?: InputMaybe<Scalars['DateTime']['input']>;
  instagram_followers?: InputMaybe<Scalars['Int']['input']>;
  is_cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  is_ready?: InputMaybe<Scalars['Boolean']['input']>;
  is_selection?: InputMaybe<Scalars['Boolean']['input']>;
  is_setup?: InputMaybe<Scalars['Boolean']['input']>;
  logistics_manager?: InputMaybe<Scalars['String']['input']>;
  newImagesRemoved?: InputMaybe<Array<Scalars['String']['input']>>;
  new_images?: InputMaybe<Array<Scalars['String']['input']>>;
  note?: InputMaybe<Scalars['String']['input']>;
  operation_type?: InputMaybe<Scalars['String']['input']>;
  previews?: InputMaybe<Array<Scalars['String']['input']>>;
  previews_properties?: InputMaybe<Array<PreviewPropertie>>;
  selection_name?: InputMaybe<Scalars['String']['input']>;
  setup_by?: InputMaybe<Scalars['String']['input']>;
  shippingPolicy?: InputMaybe<ShippingPolicyInput>;
  start_at_date?: InputMaybe<Scalars['LocalDate']['input']>;
  status?: InputMaybe<SaleSetupStatus>;
  store_id?: InputMaybe<StoreRegion>;
  tva?: InputMaybe<Scalars['Int']['input']>;
  upcoming_display_forced?: InputMaybe<Scalars['Boolean']['input']>;
  url_google_drive?: InputMaybe<Scalars['String']['input']>;
};

export enum SaleSetupStatus {
  Normal = 'normal',
  Ready = 'ready',
  SetupReady = 'setup_ready',
  SetupReadyOps = 'setup_ready_ops',
  SetupWaitingElements = 'setup_waiting_elements',
}

export enum SaleStatus {
  Cancelled = 'CANCELLED',
  Ended = 'ENDED',
  Ongoing = 'ONGOING',
  Upcoming = 'UPCOMING',
}

export enum SaleStatusFilter {
  Cancelled = 'CANCELLED',
  Ended = 'ENDED',
  Hided = 'HIDED',
  Ongoing = 'ONGOING',
  Stopped = 'STOPPED',
  Upcoming = 'UPCOMING',
}

export type SalesCache = {
  __typename?: 'SalesCache';
  has_more?: Maybe<Scalars['Boolean']['output']>;
  pagination?: Maybe<Scalars['Int']['output']>;
  sales: Array<Sale>;
  total_sales?: Maybe<Scalars['Int']['output']>;
};

export type SalesElasticSearch = {
  __typename?: 'SalesElasticSearch';
  hits?: Maybe<Array<Maybe<SalesElasticSearchHit>>>;
  max_score?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SalesElasticSearchHit = {
  __typename?: 'SalesElasticSearchHit';
  _id: Scalars['String']['output'];
  _score: Scalars['Float']['output'];
  _source: Scalars['Json']['output'];
  highlight: Scalars['Json']['output'];
  status?: Maybe<SaleStatusFilter>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SaveImage = {
  image: TransformImageData;
  item: SaveImageItem;
};

export type SaveImageItem = {
  baseKey: Scalars['String']['input'];
  id: Scalars['String']['input'];
  imageId: Scalars['String']['input'];
  type: ImageDataType;
};

export type SaveImagesOptions = {
  backgroundColor: Scalars['HexColorCode']['input'];
};

export type ShippingPolicy = {
  __typename?: 'ShippingPolicy';
  /** If defined, gives the minimum amount to order to get free shipping */
  freeShippingMinimumOrderAmount?: Maybe<Scalars['Int']['output']>;
  /**
   * If defined, Mondial relay delivery offer is available for all sale's variants
   * @deprecated use pickupPoint
   */
  mondialRelay?: Maybe<MondialRelayData>;
  /** If defined, pickup network is available for all sale's variants */
  pickupPoint?: Maybe<ShippingPolicyPickupPoint>;
};

export type ShippingPolicyInput = {
  /**
   * Number of days after given sale startAt date to get free shipping
   * If null, removes this rule from sale shipping policy
   */
  deliveryOfferedNumberOfDays?: InputMaybe<Scalars['Int']['input']>;
  /**
   * If defined, gives the minimum amount to order to get free shipping
   * If null, removes this rule from sale shipping policy
   */
  freeShippingMinimumOrderAmount?: InputMaybe<Scalars['Int']['input']>;
};

export type ShippingPolicyPickupPoint = {
  __typename?: 'ShippingPolicyPickupPoint';
  network: PickupPointNetwork;
  shippingCost: Scalars['Int']['output'];
};

export type ShopifyData = {
  __typename?: 'ShopifyData';
  hasCredentials: Scalars['Boolean']['output'];
  hasStockProtection: Scalars['Boolean']['output'];
  isStockLive: Scalars['Boolean']['output'];
  testMondialRelayOn?: Maybe<Scalars['DateTime']['output']>;
  testOrderOn?: Maybe<Scalars['DateTime']['output']>;
};

export type ShopifyPermissions = {
  __typename?: 'ShopifyPermissions';
  can_create_draft_order: Scalars['Boolean']['output'];
  can_create_order: Scalars['Boolean']['output'];
  can_create_order_products: Scalars['Boolean']['output'];
  can_read_fulfillments: Scalars['Boolean']['output'];
  can_sync_stock: Scalars['Boolean']['output'];
  deliveries_list: ShopifyPermissionsDeliveries;
  delivery?: Maybe<Scalars['String']['output']>;
  has_stock_protection?: Maybe<Scalars['Boolean']['output']>;
  is_disconnected: Scalars['Boolean']['output'];
  warning_too_much_permissions: Scalars['Boolean']['output'];
};

export type ShopifyPermissionsDeliveries = {
  __typename?: 'ShopifyPermissionsDeliveries';
  BE: Array<Maybe<Scalars['String']['output']>>;
  ES: Array<Maybe<Scalars['String']['output']>>;
  FR: Array<Maybe<Scalars['String']['output']>>;
  US: Array<Maybe<Scalars['String']['output']>>;
};

export type ShopifyQueries = {
  __typename?: 'ShopifyQueries';
  permissions: ShopifyPermissions;
};

export type ShopifyQueriesPermissionsArgs = {
  sellerId: Scalars['ID']['input'];
};

export type StockUpdateByRef = {
  /** ** Gtin or SKU that the agent wants to update ** */
  productRef: Scalars['String']['input'];
  /** ** Value of the stock to set ** */
  stock: Scalars['Int']['input'];
};

export enum StoreCurrency {
  Eur = 'eur',
  Usd = 'usd',
}

export enum StoreRegion {
  Fr = 'fr',
  Us = 'us',
}

export type SubCategory = {
  __typename?: 'SubCategory';
  category: Scalars['String']['output'];
  ids: Array<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  couponsChanged?: Maybe<Scalars['Json']['output']>;
  crawlingProductsUpdated?: Maybe<CrawlingProductsUpdatedPayload>;
  /** triggered when an image processing is done */
  imageProccessed: ImageProccessed;
  importProductsUpdated?: Maybe<ImportProductsUpdatedPayload>;
};

export type SubscriptionCrawlingProductsUpdatedArgs = {
  saleId: Scalars['String']['input'];
};

export type SubscriptionImageProccessedArgs = {
  sessionId: Scalars['ID']['input'];
};

export type SubscriptionImportProductsUpdatedArgs = {
  saleId: Scalars['String']['input'];
};

export enum TeamName {
  Cosmetiques = 'cosmetiques',
  Kids = 'kids',
  Maison = 'maison',
  Mode = 'mode',
}

export enum TopBrandType {
  ASuivre = 'a_suivre',
  Banger = 'banger',
  Business = 'business',
  Dead = 'dead',
  Image = 'image',
  MiddleNon = 'middle_non',
  MiddleOui = 'middle_oui',
  NoGo = 'no_go',
}

export type TransformImageData = {
  dimensions: ImageDimensions;
  key: Scalars['String']['input'];
  transformations: Array<ImageTransformation>;
};

export type UpdateOptionManyInput = {
  id: Scalars['ID']['input'];
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOptionVariantManyInput = {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type UpdateProductManyInput = {
  badges?: InputMaybe<Array<ProductBadgeInput>>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  hide?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  isDeliveredByChoose?: InputMaybe<Scalars['Boolean']['input']>;
  isReturnable?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<UpdateOptionManyInput>>;
  variants?: InputMaybe<Array<UpdateVariantManyInput>>;
};

export type UpdateProductOptionManyInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<UpdateOptionVariantManyInput>>;
};

export type UpdateVariantManyInput = {
  buyingPrice?: InputMaybe<Scalars['Int']['input']>;
  chooseFees?: InputMaybe<Scalars['Float']['input']>;
  deliveryDelays?: InputMaybe<Scalars['Int']['input']>;
  /** discount expressed as thousand. ie: 20% = 2000 */
  discountPercentage?: InputMaybe<Scalars['Int']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  hide?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  /** indicates if the current variant is unique */
  isUnique?: InputMaybe<Scalars['Boolean']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  retailPrice?: InputMaybe<Scalars['Int']['input']>;
  shippingFees?: InputMaybe<Scalars['Int']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  /** Product variant stock count. null means unlimited stock */
  stockCount?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum UserAliasBraze {
  TeamFr = 'TEAM_FR',
  TeamUs = 'TEAM_US',
}

export type VariantToUpsert = {
  buyingPrice?: InputMaybe<Scalars['Int']['input']>;
  /** Null when buying price is given */
  chooseFees?: InputMaybe<Scalars['Int']['input']>;
  deliveryDelays?: InputMaybe<Scalars['Int']['input']>;
  /** discount expressed as thousand. ie: 20% = 2000 */
  discountPercentage: Scalars['Int']['input'];
  eeePrice?: InputMaybe<Scalars['Int']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  /** height of the product with packaging in millimeters */
  heightMillimeter?: InputMaybe<Scalars['Int']['input']>;
  hide: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  /** indicates if the current variant is unique */
  isUnique: Scalars['Boolean']['input'];
  /** length of the product with packaging in millimeters */
  lengthMillimeter?: InputMaybe<Scalars['Int']['input']>;
  options: Array<ProductVariantOptionInput>;
  /** where the product is made from */
  originCountry?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  retailPrice: Scalars['Int']['input'];
  shippingFees: Scalars['Int']['input'];
  sku?: InputMaybe<Scalars['String']['input']>;
  /** Product variant stock count. null means unlimited stock */
  stockCount?: InputMaybe<Scalars['Int']['input']>;
  /** weight of the product with packaging in grams */
  weightGram?: InputMaybe<Scalars['Int']['input']>;
  /** width of the product with packaging in millimeters */
  widthMillimeter?: InputMaybe<Scalars['Int']['input']>;
};

export type VariantUpdateByRef = {
  /** ** Buying price to set ** */
  buyingPrice?: InputMaybe<Scalars['Int']['input']>;
  /** ** Choose price to set ** */
  choosePrice?: InputMaybe<Scalars['Int']['input']>;
  /** ** Delivery delays to set ** */
  deliveryDelays?: InputMaybe<Scalars['Int']['input']>;
  /** ** Eco taxe price to set ** */
  eeePrice?: InputMaybe<Scalars['Int']['input']>;
  /** ** Gtin or SKU that the agent wants to update ** */
  productRef: Scalars['String']['input'];
  /** ** Retail price to set ** */
  retailPrice?: InputMaybe<Scalars['Int']['input']>;
  /** ** Shipping price to set ** */
  shippingPrice?: InputMaybe<Scalars['Int']['input']>;
  /** ** Value of the stock to set ** */
  stock?: InputMaybe<Scalars['Int']['input']>;
};

/** ** Payload returned to the Front to inform the agent how many refs were updated ** */
export type VariantUpdated = {
  __typename?: 'VariantUpdated';
  productRef: Scalars['String']['output'];
  result: Scalars['Boolean']['output'];
};

export type Widget = {
  __typename?: 'Widget';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  proposition?: Maybe<Scalars['Json']['output']>;
  results?: Maybe<Scalars['Json']['output']>;
  state?: Maybe<WidgetState>;
  type?: Maybe<WidgetType>;
};

export enum WidgetState {
  Accepted = 'ACCEPTED',
  Refused = 'REFUSED',
}

export enum WidgetType {
  Duration = 'DURATION',
  Stock = 'STOCK',
}

export type CrawlingProductsUpdatedErrorPayload = {
  __typename?: 'crawlingProductsUpdatedErrorPayload';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CrawlingProductsUpdatedPayload = {
  __typename?: 'crawlingProductsUpdatedPayload';
  data?: Maybe<CrawlingProductsUpdatedProductInfoPayload>;
  error?: Maybe<CrawlingProductsUpdatedErrorPayload>;
  id: Scalars['String']['output'];
  saleId: Scalars['String']['output'];
};

export type CrawlingProductsUpdatedProductInfoPayload = {
  __typename?: 'crawlingProductsUpdatedProductInfoPayload';
  composition?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ImportProductsUpdatedErrorPayload = {
  __typename?: 'importProductsUpdatedErrorPayload';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ImportProductsUpdatedPayload = {
  __typename?: 'importProductsUpdatedPayload';
  error?: Maybe<ImportProductsUpdatedErrorPayload>;
  id: Scalars['String']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  saleId: Scalars['String']['output'];
};

export type MarkSaleTrackingsAsDeliveredFilter = {
  includeExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  includeNotPrepared?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransformParamsInput = {
  baseKey?: InputMaybe<Scalars['String']['input']>;
  gcpPath: Scalars['String']['input'];
  imageId: Scalars['String']['input'];
  imgProxyUrlParams: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  type: ImageDataType;
};

export type AddImagesToProductsMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  images: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AddImagesToProductsMutation = {
  __typename?: 'Mutation';
  addImagesToProducts?: any | null;
};

export type AddProductCouponsMutationVariables = Exact<{
  productId: Scalars['ID']['input'];
  codes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AddProductCouponsMutation = {
  __typename?: 'Mutation';
  addProductCouponsV2?: any | null;
};

export type AddSizeGuidesToProductsMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  sizeGuides: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AddSizeGuidesToProductsMutation = {
  __typename?: 'Mutation';
  addSizeGuidesToProducts?: any | null;
};

export type AddTextToProductsDescriptionMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  payload: AddTextPayload;
}>;

export type AddTextToProductsDescriptionMutation = {
  __typename?: 'Mutation';
  addTextToProductsDescription?: any | null;
};

export type AddTextToProductsNameMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  payload: AddTextPayload;
}>;

export type AddTextToProductsNameMutation = {
  __typename?: 'Mutation';
  addTextToProductsName?: any | null;
};

export type ChangeProductOptionToColorMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type ChangeProductOptionToColorMutation = {
  __typename?: 'Mutation';
  changeProductOptionToColor?: any | null;
};

export type CopyProductMutationVariables = Exact<{
  productId: Scalars['ID']['input'];
  keepImages: Scalars['Boolean']['input'];
}>;

export type CopyProductMutation = {
  __typename?: 'Mutation';
  copyProductV2: string;
};

export type CopySaleMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type CopySaleMutation = {
  __typename?: 'Mutation';
  copySale?: string | null;
};

export type CouponsChangedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type CouponsChangedSubscription = {
  __typename?: 'Subscription';
  couponsChanged?: any | null;
};

export type CrawlDriveImagesMutationVariables = Exact<{
  urlData: Array<CrawlDriveUrlDataInput> | CrawlDriveUrlDataInput;
}>;

export type CrawlDriveImagesMutation = {
  __typename?: 'Mutation';
  crawlDriveImages: Array<{
    __typename?: 'CrawlingDriveProductImage';
    productName: string;
    images: Array<string>;
  }>;
};

export type CrawlProductsMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  urls: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type CrawlProductsMutation = {
  __typename?: 'Mutation';
  crawlProducts?: string | null;
};

export type CrawlingProductsUpdatedSubscriptionVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type CrawlingProductsUpdatedSubscription = {
  __typename?: 'Subscription';
  crawlingProductsUpdated?: {
    __typename?: 'crawlingProductsUpdatedPayload';
    id: string;
    saleId: string;
    data?: {
      __typename?: 'crawlingProductsUpdatedProductInfoPayload';
      composition?: string | null;
      description?: string | null;
      images?: Array<string | null> | null;
    } | null;
    error?: {
      __typename?: 'crawlingProductsUpdatedErrorPayload';
      code?: string | null;
      message?: string | null;
    } | null;
  } | null;
};

export type CreateDurationWidgetMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type CreateDurationWidgetMutation = {
  __typename?: 'Mutation';
  createDurationWidget?: string | null;
};

export type CreateLogisticFileMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  brandId?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateLogisticFileMutation = {
  __typename?: 'Mutation';
  createLogisticFile?: string | null;
};

export type FillImagesMutationVariables = Exact<{
  images: Array<ImageInput> | ImageInput;
  sessionId: Scalars['ID']['input'];
  actionId: Scalars['ID']['input'];
}>;

export type FillImagesMutation = {
  __typename?: 'Mutation';
  fillImages?: any | null;
};

export type FitImagesMutationVariables = Exact<{
  images: Array<ImageInput> | ImageInput;
  sessionId: Scalars['ID']['input'];
  actionId: Scalars['ID']['input'];
}>;

export type FitImagesMutation = {
  __typename?: 'Mutation';
  fitImages?: any | null;
};

export type BadgesQueryVariables = Exact<{
  storeId: StoreRegion;
}>;

export type BadgesQuery = {
  __typename?: 'Query';
  badges: Array<{ __typename?: 'BadgeSvg'; name: string; svg: string }>;
};

export type BrandQueryVariables = Exact<{
  brandId: Scalars['ID']['input'];
}>;

export type BrandQuery = {
  __typename?: 'Query';
  brand?: {
    __typename?: 'Brand';
    about?: string | null;
    contactName?: string | null;
    id: string;
    instagram?: string | null;
    name?: string | null;
  } | null;
};

export type CalendarSalesQueryVariables = Exact<{
  status: SaleStatus;
  storeId: StoreRegion;
}>;

export type CalendarSalesQuery = {
  __typename?: 'Query';
  sales?: {
    __typename?: 'SalesCache';
    sales: Array<{
      __typename?: 'Sale';
      cancellation_level?: string | null;
      category?: string | null;
      deliver_by_choose?: boolean | null;
      delivery_offered_until?: string | null;
      delivery_offered_by?: string | null;
      description?: string | null;
      discount?: number | null;
      duplicated_sale_id?: string | null;
      end_at: string;
      end_at_date: string;
      forecast?: number | null;
      gender?: number | null;
      hide?: boolean | null;
      hubspot_category?: string | null;
      hubspot_deal_id?: string | null;
      hubspot_team_name?: TeamName | null;
      hubspot_top_brand_type?: TopBrandType | null;
      id: string;
      image_header?: string | null;
      is_cancelled?: boolean | null;
      is_ready?: boolean | null;
      is_setup?: boolean | null;
      isShopify?: boolean | null;
      logistics_manager?: string | null;
      note?: string | null;
      operation_type?: string | null;
      original_sale_id?: string | null;
      previews?: any | null;
      sale_status?: SaleStatusFilter | null;
      selection_name?: string | null;
      setup_status?: SaleSetupStatus | null;
      setup_by?: string | null;
      start_at: string;
      start_at_date: string;
      stats_stock?: any | null;
      storeId: StoreRegion;
      total_sales?: number | null;
      url_google_drive?: string | null;
      brand?: {
        __typename?: 'Brand';
        about?: string | null;
        id: string;
        name?: string | null;
        contactEmail?: string | null;
        contactName?: string | null;
        website?: string | null;
        instagram?: string | null;
      } | null;
      shippingPolicy: {
        __typename?: 'ShippingPolicy';
        pickupPoint?: {
          __typename?: 'ShippingPolicyPickupPoint';
          network: PickupPointNetwork;
          shippingCost: number;
        } | null;
      };
      shopify?: {
        __typename?: 'ShopifyData';
        hasCredentials: boolean;
        isStockLive: boolean;
        testMondialRelayOn?: string | null;
        testOrderOn?: string | null;
      } | null;
    }>;
  } | null;
};

export type CategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type CategoriesQuery = {
  __typename?: 'Query';
  categories: Array<{
    __typename?: 'Category';
    ids: Array<string>;
    category: string;
    sub_categories: Array<{
      __typename?: 'SubCategory';
      category: string;
      ids: Array<string>;
    }>;
  }>;
};

export type CategoryEssentialOptionsQueryVariables = Exact<{
  categoryId: Scalars['String']['input'];
}>;

export type CategoryEssentialOptionsQuery = {
  __typename?: 'Query';
  categoryEssentialOptions: Array<{
    __typename?: 'CategoryOption';
    text: string;
    type: string;
    values: Array<string>;
    placeholders: Array<string>;
  }>;
};

export type CategoryOptionsQueryVariables = Exact<{
  categoryId: Scalars['String']['input'];
}>;

export type CategoryOptionsQuery = {
  __typename?: 'Query';
  categoryOptions: Array<{
    __typename?: 'CategoryOption';
    text: string;
    type: string;
    values: Array<string>;
    placeholders: Array<string>;
  }>;
};

export type CategoryTreeQueryVariables = Exact<{ [key: string]: never }>;

export type CategoryTreeQuery = {
  __typename?: 'Query';
  categoryTree: Array<{
    __typename?: 'CategoryTree';
    id: string;
    label: string;
    tva?: number | null;
    notReturnable: boolean;
    subCategories?: Array<{
      __typename?: 'CategoryTree';
      id: string;
      label: string;
      tva?: number | null;
      notReturnable: boolean;
      subCategories?: Array<{
        __typename?: 'CategoryTree';
        id: string;
        label: string;
        tva?: number | null;
        notReturnable: boolean;
        subCategories?: Array<{
          __typename?: 'CategoryTree';
          id: string;
          label: string;
          tva?: number | null;
          notReturnable: boolean;
          subCategories?: Array<{
            __typename?: 'CategoryTree';
            id: string;
            label: string;
            tva?: number | null;
            notReturnable: boolean;
            subCategories?: Array<{
              __typename?: 'CategoryTree';
              id: string;
              label: string;
              tva?: number | null;
              notReturnable: boolean;
              subCategories?: Array<{
                __typename?: 'CategoryTree';
                id: string;
                label: string;
                tva?: number | null;
                notReturnable: boolean;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      }> | null;
    }> | null;
  }>;
};

export type CouponsQueryVariables = Exact<{
  status: CouponStatus;
  storeId: StoreRegion;
}>;

export type CouponsQuery = {
  __typename?: 'Query';
  coupons: Array<{
    __typename?: 'Coupon';
    id?: number | null;
    code?: string | null;
    type?: CodeType | null;
    value?: number | null;
    start_at?: string | null;
    expires_at?: string | null;
    quantity?: number | null;
    quantity_per_user?: number | null;
    minimum_order_amount?: number | null;
    first_order_only?: boolean | null;
    total_used?: number | null;
    store_id?: StoreRegion | null;
  }>;
};

export type GetImagesByProductIdsQueryVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetImagesByProductIdsQuery = {
  __typename?: 'Query';
  getImagesByProductIds: Array<{
    __typename?: 'ProductImage';
    id: string;
    productId: string;
    url: string;
    originalUrl: string;
    currentVersion: number;
    isCover: boolean;
    isVariantCover: boolean;
    isOriginal: boolean;
    aspect?: ImageAspect | null;
  }>;
};

export type GetProductCouponsQueryVariables = Exact<{
  productId: Scalars['String']['input'];
}>;

export type GetProductCouponsQuery = {
  __typename?: 'Query';
  getProductCoupons: Array<{
    __typename?: 'ProductCoupon';
    product_id: string;
    code: string;
    order_id?: string | null;
  }>;
};

export type GetProductQueryVariables = Exact<{
  productId: Scalars['String']['input'];
}>;

export type GetProductQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'ProductV3';
    id: string;
    type: ProductType;
    isDraft: boolean;
    isAlcohol?: boolean | null;
    sourceUrl?: string | null;
    hide: boolean;
    description?: string | null;
    name: string;
    currency: StoreCurrency;
    gender?: number | null;
    couponDetail?: string | null;
    couponExpirationDate?: string | null;
    cgu?: Array<string> | null;
    faqPreOrder?: string | null;
    faq?: Array<string> | null;
    faqPostOrder?: string | null;
    isCobrand: boolean;
    isReturnable: boolean;
    isDeliveredByChoose?: boolean | null;
    isGiveaway: boolean;
    position?: number | null;
    badges: Array<{
      __typename?: 'ProductBadge';
      isSpecial: boolean;
      position: number;
      value: string;
    }>;
    category?: {
      __typename?: 'ProductCategory';
      name: string;
      vat: number;
    } | null;
    brand: {
      __typename?: 'ProductBrand';
      id: string;
      name: string;
      about: string;
    };
    variants: Array<{
      __typename?: 'ProductVariantV2';
      id: string;
      position?: number | null;
      isUnique: boolean;
      hide: boolean;
      gtin?: string | null;
      sku?: string | null;
      images?: Array<string | null> | null;
      absorbedShipping?: number | null;
      buyingPrice?: number | null;
      chooseFees?: number | null;
      deliveryDelays?: number | null;
      discountPercentage?: number | null;
      eeePrice?: number | null;
      retailPrice?: number | null;
      shippingFees?: number | null;
      weightGram?: number | null;
      widthMillimeter?: number | null;
      heightMillimeter?: number | null;
      lengthMillimeter?: number | null;
      stockCount?: number | null;
      originCountry?: string | null;
      options: Array<{
        __typename?: 'ProductVariantOptionV2';
        productOptionId: string;
        valueId: string;
      }>;
    }>;
    options?: Array<{
      __typename?: 'ProductOptionV3';
      id: string;
      type?: string | null;
      name: string;
      position: number;
      images?: Array<{
        __typename?: 'Image';
        id: string;
        baseKey?: string | null;
        image?: string | null;
      }> | null;
      values: Array<{
        __typename?: 'ProductOptionValueV2';
        id: string;
        value: string;
        position: number;
      }>;
    }> | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key: string;
      position: number;
      type?: string | null;
      value: string;
    }> | null;
    images?: Array<{
      __typename?: 'Image';
      id: string;
      baseKey?: string | null;
      image?: string | null;
    }> | null;
  };
};

export type ProvisionalSalesQueryVariables = Exact<{
  storeId: StoreRegion;
}>;

export type ProvisionalSalesQuery = {
  __typename?: 'Query';
  provisionalSales: Array<{
    __typename?: 'ProvisionalSale';
    id: string;
    name: string;
    category?: string | null;
    saleBy?: string | null;
    startsAt: string;
    brandHasShopify?: boolean | null;
    forecast?: number | null;
    hubspotId?: string | null;
    hubspotCategory?: string | null;
    hubspot_team_name?: TeamName | null;
    hubspot_top_brand_type?: TopBrandType | null;
    cancellationLevel?: string | null;
    operationType?: string | null;
    gender?: number | null;
    instagramId?: string | null;
    website?: string | null;
    note?: string | null;
    brandSaleCount?: number | null;
    shopify?: {
      __typename?: 'ShopifyData';
      hasCredentials: boolean;
      isStockLive: boolean;
    } | null;
  }>;
};

export type ReferralBlockQueryVariables = Exact<{
  store: StoreRegion;
}>;

export type ReferralBlockQuery = {
  __typename?: 'Query';
  marketing: {
    __typename?: 'MarketingQueries';
    referralBlock?: {
      __typename?: 'MarketingReferralBlock';
      bigImage: string;
      btnCta: string;
      date: string;
      smallImage: string;
      tagline: string;
      title: string;
    } | null;
  };
};

export type GetReferralUploadUrlQueryVariables = Exact<{
  fileType: Scalars['String']['input'];
  saleId: Scalars['String']['input'];
  uploadType: MarketingReferralImageType;
}>;

export type GetReferralUploadUrlQuery = {
  __typename?: 'Query';
  marketing: {
    __typename?: 'MarketingQueries';
    getReferralUploadUrl?: {
      __typename?: 'UploadUrl';
      key: string;
      url: string;
    } | null;
  };
};

export type SaleProductsQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;

export type SaleProductsQuery = {
  __typename?: 'Query';
  sale?: {
    __typename?: 'Sale';
    products: Array<{
      __typename?: 'ProductV3';
      currency: StoreCurrency;
      description?: string | null;
      hide: boolean;
      id: string;
      importCsvPosition?: number | null;
      isCobrand: boolean;
      isDeliveredByChoose?: boolean | null;
      isDraft: boolean;
      isGiveaway: boolean;
      isReturnable: boolean;
      name: string;
      position?: number | null;
      sourceUrl?: string | null;
      type: ProductType;
      badges: Array<{
        __typename?: 'ProductBadge';
        isSpecial: boolean;
        position: number;
        value: string;
      }>;
      brand: {
        __typename?: 'ProductBrand';
        id: string;
        name: string;
        about: string;
      };
      category?: {
        __typename?: 'ProductCategory';
        name: string;
        vat: number;
      } | null;
      images?: Array<{
        __typename?: 'Image';
        id: string;
        baseKey?: string | null;
        image?: string | null;
      }> | null;
      options?: Array<{
        __typename?: 'ProductOptionV3';
        id: string;
        type?: string | null;
        name: string;
        position: number;
        images?: Array<{
          __typename?: 'Image';
          id: string;
          baseKey?: string | null;
          image?: string | null;
        }> | null;
        values: Array<{
          __typename?: 'ProductOptionValueV2';
          id: string;
          value: string;
          position: number;
        }>;
      }> | null;
      variants: Array<{
        __typename?: 'ProductVariantV2';
        id: string;
        position?: number | null;
        isUnique: boolean;
        hide: boolean;
        gtin?: string | null;
        sku?: string | null;
        images?: Array<string | null> | null;
        absorbedShipping?: number | null;
        buyingPrice?: number | null;
        chooseFees?: number | null;
        computedDeliveryDelays?: number | null;
        discountPercentage?: number | null;
        eeePrice?: number | null;
        retailPrice?: number | null;
        shippingFees?: number | null;
        weightGram?: number | null;
        stockCount?: number | null;
        originCountry?: string | null;
        options: Array<{
          __typename?: 'ProductVariantOptionV2';
          productOptionId: string;
          valueId: string;
        }>;
      }>;
    }>;
  } | null;
};

export type SaleQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;

export type SaleQuery = {
  __typename?: 'Query';
  sale?: {
    __typename?: 'Sale';
    badges?: any | null;
    category?: string | null;
    currency?: StoreCurrency | null;
    deliver_by_choose?: boolean | null;
    delivery_delays?: number | null;
    delivery_offered_until?: string | null;
    delivery_offered_by?: string | null;
    description?: string | null;
    disable_sorting?: boolean | null;
    duplicated_sale_id?: string | null;
    end_at: string;
    end_at_date: string;
    has_orders?: boolean | null;
    hasReturnableProducts: boolean;
    hide?: boolean | null;
    hubspot_deal_id?: string | null;
    id: string;
    image_header?: string | null;
    initial_start_at?: string | null;
    is_cancelled?: boolean | null;
    is_ready?: boolean | null;
    is_selection?: boolean | null;
    is_setup?: boolean | null;
    isShopify?: boolean | null;
    logistics_manager?: string | null;
    operation_type?: string | null;
    original_sale_id?: string | null;
    sale_status?: SaleStatusFilter | null;
    selection_name?: string | null;
    setup_by?: string | null;
    start_at: string;
    start_at_date: string;
    status?: SaleSetupStatus | null;
    storeId: StoreRegion;
    tva?: number | null;
    upcoming_display_forced: boolean;
    url?: string | null;
    url_google_drive?: string | null;
    ads?: Array<{
      __typename?: 'Ad';
      created_at?: string | null;
      is_retarget?: boolean | null;
      is_cold?: boolean | null;
      ids?: Array<string | null> | null;
      video_url?: string | null;
    } | null> | null;
    brand?: {
      __typename?: 'Brand';
      id: string;
      name?: string | null;
      contactName?: string | null;
      sale_objectif_name?: string | null;
      contactPhone?: string | null;
      accept_contract?: boolean | null;
      about?: string | null;
      contactEmail?: string | null;
      instagram?: string | null;
      address?: any | null;
      isFrench?: boolean | null;
      is_autoentrepreneur?: boolean | null;
      shipping_restricted_to_france?: boolean | null;
      emailedAt?: string | null;
      emailId?: string | null;
      plugins?: { __typename?: 'Plugins'; shopify?: any | null } | null;
    } | null;
    cashbackPolicy?: {
      __typename?: 'CashbackPolicy';
      delayInDays: number;
      thresholds: Array<{
        __typename?: 'CashbackThreshold';
        threshold: number;
        cashbackAmount: number;
      }>;
    } | null;
    giveawayPolicies: Array<{
      __typename?: 'GiveawaySalePolicy';
      saleId: string;
      overOrderAmount: number;
      productId: string;
      dateRange?: {
        __typename?: 'DateRange';
        startAt: string;
        endAt: string;
      } | null;
    }>;
    invoice?: {
      __typename?: 'Invoice';
      is_invoice_ready?: boolean | null;
      invoice_amount?: string | null;
      invoice_by?: string | null;
      invoice_on?: string | null;
      paid_on?: string | null;
      proof_paid_url?: string | null;
      paid_amount?: number | null;
      paid_by?: string | null;
    } | null;
    newImages?: Array<{
      __typename?: 'SaleImage';
      baseKey: string;
      id: string;
      image: string;
      properties?: {
        __typename?: 'SaleImageProperties';
        full?: boolean | null;
        gender?: number | null;
      } | null;
    }> | null;
    newPreviews?: Array<{
      __typename?: 'Preview';
      baseKey: string;
      full?: boolean | null;
      id: string;
      image: string;
    }> | null;
    shopify?: {
      __typename?: 'ShopifyData';
      testMondialRelayOn?: string | null;
      testOrderOn?: string | null;
    } | null;
    shippingPolicy: {
      __typename?: 'ShippingPolicy';
      freeShippingMinimumOrderAmount?: number | null;
      pickupPoint?: {
        __typename?: 'ShippingPolicyPickupPoint';
        network: PickupPointNetwork;
        shippingCost: number;
      } | null;
    };
    widgets?: Array<{
      __typename?: 'Widget';
      created_at?: string | null;
      id: string;
      proposition?: any | null;
      results?: any | null;
      state?: WidgetState | null;
      type?: WidgetType | null;
    } | null> | null;
  } | null;
};

export type SalesQueryVariables = Exact<{
  status: SaleStatus;
  storeId: StoreRegion;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SalesQuery = {
  __typename?: 'Query';
  sales?: {
    __typename?: 'SalesCache';
    has_more?: boolean | null;
    pagination?: number | null;
    total_sales?: number | null;
    sales: Array<{
      __typename?: 'Sale';
      badges?: any | null;
      category?: string | null;
      deliver_by_choose?: boolean | null;
      delivery_offered_until?: string | null;
      delivery_offered_by?: string | null;
      discount?: number | null;
      end_at: string;
      end_at_date: string;
      hide?: boolean | null;
      id: string;
      image_header?: string | null;
      is_selection?: boolean | null;
      logistics_manager?: string | null;
      sale_status?: SaleStatusFilter | null;
      selection_name?: string | null;
      setup_by?: string | null;
      start_at: string;
      start_at_date: string;
      storeId: StoreRegion;
      upcoming_display_forced: boolean;
      brand?: {
        __typename?: 'Brand';
        id: string;
        name?: string | null;
        contactEmail?: string | null;
        contactName?: string | null;
        website?: string | null;
        instagram?: string | null;
      } | null;
    }>;
  } | null;
};

export type GetUploadUrlQueryVariables = Exact<{
  fileType: Scalars['String']['input'];
  uploadType: FileUploadType;
  saleId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUploadUrlQuery = {
  __typename?: 'Query';
  getUploadUrl?: any | null;
};

export type HideOutOfStockProductsAndVariantsMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type HideOutOfStockProductsAndVariantsMutation = {
  __typename?: 'Mutation';
  hideOutOfStockProductsAndVariants?: any | null;
};

export type ImageProccessedSubscriptionVariables = Exact<{
  sessionId: Scalars['ID']['input'];
}>;

export type ImageProccessedSubscription = {
  __typename?: 'Subscription';
  imageProccessed: {
    __typename?: 'ImageProccessed';
    actionId: string;
    id: string;
    url: string;
  };
};

export type ImportProductsUpdatedSubscriptionVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type ImportProductsUpdatedSubscription = {
  __typename?: 'Subscription';
  importProductsUpdated?: {
    __typename?: 'importProductsUpdatedPayload';
    id: string;
    saleId: string;
    error?: {
      __typename?: 'importProductsUpdatedErrorPayload';
      code?: string | null;
      message?: string | null;
    } | null;
  } | null;
};

export type ImportProductsMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  sync?: InputMaybe<Scalars['Boolean']['input']>;
  csvContent?: InputMaybe<Scalars['String']['input']>;
}>;

export type ImportProductsMutation = {
  __typename?: 'Mutation';
  importProducts?: string | null;
};

export type LastNotificationQueryVariables = Exact<{
  storeId: StoreRegion;
}>;

export type LastNotificationQuery = {
  __typename?: 'Query';
  last_notification?: {
    __typename?: 'LastNotification';
    notification?: {
      __typename?: 'Notification';
      title?: string | null;
      message?: string | null;
      operator?: string | null;
      send_at?: string | null;
      stats?: any | null;
      for_subscribers?: boolean | null;
      brand_name?: string | null;
    } | null;
    analytics?: Array<{
      __typename?: 'NotificationAnalytics';
      conversions?: number | null;
      conversions1?: number | null;
      conversions2?: number | null;
      conversions3?: number | null;
      time?: string | null;
      conversions_by_send_time?: number | null;
      conversions1_by_send_time?: number | null;
      conversions2_by_send_time?: number | null;
      conversions3_by_send_time?: number | null;
      unique_recipients?: number | null;
      revenue?: number | null;
      messages?: {
        __typename?: 'NotificationAnalyticsMessages';
        ios_push?: Array<{
          __typename?: 'NotificationAnalyticsMessagePush';
          variation_api_id?: string | null;
          sent?: number | null;
          direct_opens?: number | null;
          total_opens?: number | null;
          bounces?: number | null;
          body_clicks?: number | null;
        } | null> | null;
        android_push?: Array<{
          __typename?: 'NotificationAnalyticsMessagePush';
          variation_api_id?: string | null;
          sent?: number | null;
          total_opens?: number | null;
          direct_opens?: number | null;
          bounces?: number | null;
          body_clicks?: number | null;
        } | null> | null;
      } | null;
    }> | null;
  } | null;
};

export type MakePublicFileQueryVariables = Exact<{
  url: Scalars['String']['input'];
}>;

export type MakePublicFileQuery = {
  __typename?: 'Query';
  makePublicFile?: any | null;
};

export type MarkSaleTrackingsAsDeliveredMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  filters: MarkSaleTrackingsAsDeliveredFilter;
}>;

export type MarkSaleTrackingsAsDeliveredMutation = {
  __typename?: 'Mutation';
  markSaleTrackingsAsDelivered?: string | null;
};

export type PreviewImageMutationVariables = Exact<{
  image: TransformImageData;
}>;

export type PreviewImageMutation = {
  __typename?: 'Mutation';
  previewImage:
    | { __typename: 'ErrorLike'; message: string }
    | { __typename: 'ImagePreview'; url: string };
};

export type RemoveBackgroundImagesMutationVariables = Exact<{
  images: Array<ImageInput> | ImageInput;
  sessionId: Scalars['ID']['input'];
  actionId: Scalars['ID']['input'];
}>;

export type RemoveBackgroundImagesMutation = {
  __typename?: 'Mutation';
  removeBackgroundImages?: any | null;
};

export type RemoveCouponMutationVariables = Exact<{
  couponId: Scalars['Int']['input'];
}>;

export type RemoveCouponMutation = {
  __typename?: 'Mutation';
  removeCoupon?: string | null;
};

export type RemoveGtinsMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type RemoveGtinsMutation = {
  __typename?: 'Mutation';
  removeGtins?: any | null;
};

export type RemoveProductCouponsMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  productId: Scalars['String']['input'];
}>;

export type RemoveProductCouponsMutation = {
  __typename?: 'Mutation';
  removeProductCoupons?: string | null;
};

export type RemoveProductsSizeGuideMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type RemoveProductsSizeGuideMutation = {
  __typename?: 'Mutation';
  removeProductsSizeGuide?: any | null;
};

export type RemoveProductsMutationVariables = Exact<{
  productsIds:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  saleId: Scalars['String']['input'];
}>;

export type RemoveProductsMutation = {
  __typename?: 'Mutation';
  removeProducts?: string | null;
};

export type RemoveProvisionalSaleMutationVariables = Exact<{
  key?: InputMaybe<Scalars['String']['input']>;
  dataKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type RemoveProvisionalSaleMutation = {
  __typename?: 'Mutation';
  deleteDealToCalendar?: string | null;
};

export type RemoveSaleMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type RemoveSaleMutation = {
  __typename?: 'Mutation';
  removeSale?: string | null;
};

export type RemoveSkusMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type RemoveSkusMutation = {
  __typename?: 'Mutation';
  removeSkus?: any | null;
};

export type RemoveWidgetMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type RemoveWidgetMutation = {
  __typename?: 'Mutation';
  removeWidget?: string | null;
};

export type ReplaceProductsNameMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  payload: Array<ReplacePayload> | ReplacePayload;
}>;

export type ReplaceProductsNameMutation = {
  __typename?: 'Mutation';
  replaceProductsName?: any | null;
};

export type SalesCountQueryVariables = Exact<{
  storeId: StoreRegion;
}>;

export type SalesCountQuery = {
  __typename?: 'Query';
  salesCount?: Array<{
    __typename?: 'SaleCount';
    count?: number | null;
  } | null> | null;
};

export type SaveImageMutationVariables = Exact<{
  data: SaveImage;
}>;

export type SaveImageMutation = {
  __typename?: 'Mutation';
  saveImage:
    | { __typename: 'ErrorLike'; message: string }
    | {
        __typename: 'ImageAdded';
        source_store_path_key: string;
        image_storage_path_key: string;
        image_updated_url: string;
        source_url: string;
      };
};

export type SaveImagesMutationVariables = Exact<{
  images: Array<ImageInput> | ImageInput;
  sessionId: Scalars['ID']['input'];
  actionId: Scalars['ID']['input'];
  options?: InputMaybe<SaveImagesOptions>;
}>;

export type SaveImagesMutation = {
  __typename?: 'Mutation';
  saveImages?: any | null;
};

export type SearchBrandQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type SearchBrandQuery = {
  __typename?: 'Query';
  searchBrand: Array<{
    __typename?: 'Brand';
    id: string;
    name?: string | null;
    about?: string | null;
    createdAt?: string | null;
  }>;
};

export type SearchSalesV2QueryVariables = Exact<{
  name: Scalars['String']['input'];
  store: StoreRegion;
  limit: Scalars['Int']['input'];
}>;

export type SearchSalesV2Query = {
  __typename?: 'Query';
  searchSalesV2: {
    __typename?: 'SalesElasticSearch';
    total?: number | null;
    hits?: Array<{
      __typename?: 'SalesElasticSearchHit';
      _score: number;
      _source: any;
      url?: string | null;
      _id: string;
      highlight: any;
      status?: SaleStatusFilter | null;
    } | null> | null;
  };
};

export type SendNotificationMutationVariables = Exact<{
  data: NotificationInput;
}>;

export type SendNotificationMutation = {
  __typename?: 'Mutation';
  sendNotification?: string | null;
};

export type SendOnboardingEmailMutationVariables = Exact<{
  brandId: Scalars['String']['input'];
}>;

export type SendOnboardingEmailMutation = {
  __typename?: 'Mutation';
  sendOnboardingEmail?: string | null;
};

export type SetProductsAsUniqueMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type SetProductsAsUniqueMutation = {
  __typename?: 'Mutation';
  setProductsAsUnique?: any | null;
};

export type SetProductsHideMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
}>;

export type SetProductsHideMutation = {
  __typename?: 'Mutation';
  setProductsHide?: any | null;
};

export type SetShopifyStockProtectionMutationVariables = Exact<{
  sellerId: Scalars['ID']['input'];
  protect: Scalars['Boolean']['input'];
}>;

export type SetShopifyStockProtectionMutation = {
  __typename?: 'Mutation';
  setShopifyStockProtection?: any | null;
};

export type ShowHiddenProductsAndVariantsMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type ShowHiddenProductsAndVariantsMutation = {
  __typename?: 'Mutation';
  showHiddenProductsAndVariants?: any | null;
};

export type ShuffleProductListMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type ShuffleProductListMutation = {
  __typename?: 'Mutation';
  shuffleProductList?: any | null;
};

export type UpdateAccentuateReferralMutationVariables = Exact<{
  data: AccentuateReferralInput;
}>;

export type UpdateAccentuateReferralMutation = {
  __typename?: 'Mutation';
  updateAccentuateReferral?: string | null;
};

export type UpdateBrandMutationVariables = Exact<{
  data?: InputMaybe<BrandUpdate>;
}>;

export type UpdateBrandMutation = {
  __typename?: 'Mutation';
  updateBrand?: string | null;
};

export type UpdateCouponMutationVariables = Exact<{
  data: CouponUpdate;
}>;

export type UpdateCouponMutation = {
  __typename?: 'Mutation';
  updateCoupon?: string | null;
};

export type UpdateInvoiceMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  data?: InputMaybe<Scalars['Json']['input']>;
}>;

export type UpdateInvoiceMutation = {
  __typename?: 'Mutation';
  updateInvoice?: string | null;
};

export type UpdateManyProductOptionsMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  data: Array<UpdateProductOptionManyInput> | UpdateProductOptionManyInput;
}>;

export type UpdateManyProductOptionsMutation = {
  __typename?: 'Mutation';
  updateManyProductOptions?: Array<{
    __typename?: 'ProductOptionError';
    id: string;
    message: string;
  }> | null;
};

export type UpdateManyProductMutationVariables = Exact<{
  data: Array<UpdateProductManyInput> | UpdateProductManyInput;
}>;

export type UpdateManyProductMutation = {
  __typename?: 'Mutation';
  updateManyProduct?: any | null;
};

export type UpdateProductMutationVariables = Exact<{
  data: ProductUpdateInputV3;
}>;

export type UpdateProductMutation = {
  __typename?: 'Mutation';
  updateProductV3: {
    __typename?: 'ProductV3';
    id: string;
    name: string;
    sourceUrl?: string | null;
    description?: string | null;
    gender?: number | null;
    isDraft: boolean;
    type: ProductType;
    createdAt?: string | null;
    updatedAt?: string | null;
    hide: boolean;
    currency: StoreCurrency;
    position?: number | null;
    faq?: Array<string> | null;
    faqPreOrder?: string | null;
    faqPostOrder?: string | null;
    couponExpirationDate?: string | null;
    couponDetail?: string | null;
    cgu?: Array<string> | null;
    isAlcohol?: boolean | null;
    isDeliveredByChoose?: boolean | null;
    isReturnable: boolean;
    badges: Array<{
      __typename?: 'ProductBadge';
      isSpecial: boolean;
      position: number;
      value: string;
    }>;
    images?: Array<{
      __typename?: 'Image';
      id: string;
      baseKey?: string | null;
      image?: string | null;
    }> | null;
    category?: {
      __typename?: 'ProductCategory';
      name: string;
      vat: number;
    } | null;
    brand: {
      __typename?: 'ProductBrand';
      id: string;
      about: string;
      name: string;
    };
    variants: Array<{
      __typename?: 'ProductVariantV2';
      id: string;
      images?: Array<string | null> | null;
      stockCount?: number | null;
      sku?: string | null;
      gtin?: string | null;
      position?: number | null;
      buyingPrice?: number | null;
      retailPrice?: number | null;
      discountPercentage?: number | null;
      chooseFees?: number | null;
      hide: boolean;
      absorbedShipping?: number | null;
      shippingFees?: number | null;
      deliveryDelays?: number | null;
      originCountry?: string | null;
      options: Array<{
        __typename?: 'ProductVariantOptionV2';
        productOptionId: string;
        valueId: string;
      }>;
    }>;
    customFields?: Array<{
      __typename?: 'CustomField';
      key: string;
      position: number;
      type?: string | null;
      value: string;
    }> | null;
    options?: Array<{
      __typename?: 'ProductOptionV3';
      id: string;
      type?: string | null;
      name: string;
      position: number;
      values: Array<{
        __typename?: 'ProductOptionValueV2';
        id: string;
        position: number;
        value: string;
      }>;
      images?: Array<{
        __typename?: 'Image';
        id: string;
        baseKey?: string | null;
        image?: string | null;
      }> | null;
    }> | null;
  };
};

export type UpdateProductsBuyingPriceMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  value: Scalars['Float']['input'];
}>;

export type UpdateProductsBuyingPriceMutation = {
  __typename?: 'Mutation';
  updateProductsBuyingPrice?: any | null;
};

export type UpdateProductsCategoryMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  category: Scalars['String']['input'];
}>;

export type UpdateProductsCategoryMutation = {
  __typename?: 'Mutation';
  updateProductsCategory?: any | null;
};

export type UpdateProductsCouponExpiresAtMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  value: Scalars['LocalDate']['input'];
}>;

export type UpdateProductsCouponExpiresAtMutation = {
  __typename?: 'Mutation';
  updateProductsCouponExpiresAt?: any | null;
};

export type UpdateProductsDeliveryMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
}>;

export type UpdateProductsDeliveryMutation = {
  __typename?: 'Mutation';
  updateProductsDelivery?: any | null;
};

export type UpdateProductsGenderMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  value?: InputMaybe<Scalars['Int']['input']>;
}>;

export type UpdateProductsGenderMutation = {
  __typename?: 'Mutation';
  updateProductsGender?: any | null;
};

export type UpdateProductsOriginCountryMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  value: Scalars['String']['input'];
}>;

export type UpdateProductsOriginCountryMutation = {
  __typename?: 'Mutation';
  updateProductsOriginCountry?: any | null;
};

export type UpdateProductsRetailPriceMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  value: Scalars['Int']['input'];
}>;

export type UpdateProductsRetailPriceMutation = {
  __typename?: 'Mutation';
  updateProductsRetailPrice?: any | null;
};

export type UpdateProductsReturnEligibilityMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  isReturnable: Scalars['Boolean']['input'];
}>;

export type UpdateProductsReturnEligibilityMutation = {
  __typename?: 'Mutation';
  updateProductsReturnEligibility?: any | null;
};

export type UpdateProductsChooseFeesMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  fees: Scalars['Float']['input'];
}>;

export type UpdateProductsChooseFeesMutation = {
  __typename?: 'Mutation';
  updateProductsChooseFees?: any | null;
};

export type UpdateProductsShippingCostMutationVariables = Exact<{
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  value: Scalars['Float']['input'];
}>;

export type UpdateProductsShippingCostMutation = {
  __typename?: 'Mutation';
  updateProductsShippingCost?: any | null;
};

export type UpdateProductsMutationVariables = Exact<{
  data?: InputMaybe<ProductsUpdate>;
}>;

export type UpdateProductsMutation = {
  __typename?: 'Mutation';
  updateProducts?: string | null;
};

export type UpdateProvisionalSalePartialMutationVariables = Exact<{
  key?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['Json']['input']>;
}>;

export type UpdateProvisionalSalePartialMutation = {
  __typename?: 'Mutation';
  updateDealKey?: string | null;
};

export type UpdateSaleMutationVariables = Exact<{
  sale: SaleInput;
  brandNameChanged?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateSaleMutation = {
  __typename?: 'Mutation';
  updateSale?: string | null;
};

export const AddImagesToProductsDocument = `
    mutation addImagesToProducts($productIds: [ID!]!, $images: [String!]!) {
  addImagesToProducts(productIds: $productIds, images: $images)
}
    `;

export const useAddImagesToProductsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AddImagesToProductsMutation,
    TError,
    AddImagesToProductsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AddImagesToProductsMutation,
    TError,
    AddImagesToProductsMutationVariables,
    TContext
  >({
    mutationKey: ['addImagesToProducts'],
    mutationFn: useFetchData<
      AddImagesToProductsMutation,
      AddImagesToProductsMutationVariables
    >(AddImagesToProductsDocument),
    ...options,
  });
};

export const AddProductCouponsDocument = `
    mutation addProductCoupons($productId: ID!, $codes: [String!]!) {
  addProductCouponsV2(productId: $productId, codes: $codes)
}
    `;

export const useAddProductCouponsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AddProductCouponsMutation,
    TError,
    AddProductCouponsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AddProductCouponsMutation,
    TError,
    AddProductCouponsMutationVariables,
    TContext
  >({
    mutationKey: ['addProductCoupons'],
    mutationFn: useFetchData<
      AddProductCouponsMutation,
      AddProductCouponsMutationVariables
    >(AddProductCouponsDocument),
    ...options,
  });
};

export const AddSizeGuidesToProductsDocument = `
    mutation addSizeGuidesToProducts($productIds: [ID!]!, $sizeGuides: [String!]!) {
  addSizeGuidesToProducts(productIds: $productIds, sizeGuides: $sizeGuides)
}
    `;

export const useAddSizeGuidesToProductsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AddSizeGuidesToProductsMutation,
    TError,
    AddSizeGuidesToProductsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AddSizeGuidesToProductsMutation,
    TError,
    AddSizeGuidesToProductsMutationVariables,
    TContext
  >({
    mutationKey: ['addSizeGuidesToProducts'],
    mutationFn: useFetchData<
      AddSizeGuidesToProductsMutation,
      AddSizeGuidesToProductsMutationVariables
    >(AddSizeGuidesToProductsDocument),
    ...options,
  });
};

export const AddTextToProductsDescriptionDocument = `
    mutation addTextToProductsDescription($productIds: [ID!]!, $payload: AddTextPayload!) {
  addTextToProductsDescription(productIds: $productIds, payload: $payload)
}
    `;

export const useAddTextToProductsDescriptionMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AddTextToProductsDescriptionMutation,
    TError,
    AddTextToProductsDescriptionMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AddTextToProductsDescriptionMutation,
    TError,
    AddTextToProductsDescriptionMutationVariables,
    TContext
  >({
    mutationKey: ['addTextToProductsDescription'],
    mutationFn: useFetchData<
      AddTextToProductsDescriptionMutation,
      AddTextToProductsDescriptionMutationVariables
    >(AddTextToProductsDescriptionDocument),
    ...options,
  });
};

export const AddTextToProductsNameDocument = `
    mutation addTextToProductsName($productIds: [ID!]!, $payload: AddTextPayload!) {
  addTextToProductsName(productIds: $productIds, payload: $payload)
}
    `;

export const useAddTextToProductsNameMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AddTextToProductsNameMutation,
    TError,
    AddTextToProductsNameMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AddTextToProductsNameMutation,
    TError,
    AddTextToProductsNameMutationVariables,
    TContext
  >({
    mutationKey: ['addTextToProductsName'],
    mutationFn: useFetchData<
      AddTextToProductsNameMutation,
      AddTextToProductsNameMutationVariables
    >(AddTextToProductsNameDocument),
    ...options,
  });
};

export const ChangeProductOptionToColorDocument = `
    mutation changeProductOptionToColor($productIds: [ID!]!) {
  changeProductOptionToColor(productIds: $productIds)
}
    `;

export const useChangeProductOptionToColorMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ChangeProductOptionToColorMutation,
    TError,
    ChangeProductOptionToColorMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ChangeProductOptionToColorMutation,
    TError,
    ChangeProductOptionToColorMutationVariables,
    TContext
  >({
    mutationKey: ['changeProductOptionToColor'],
    mutationFn: useFetchData<
      ChangeProductOptionToColorMutation,
      ChangeProductOptionToColorMutationVariables
    >(ChangeProductOptionToColorDocument),
    ...options,
  });
};

export const CopyProductDocument = `
    mutation copyProduct($productId: ID!, $keepImages: Boolean!) {
  copyProductV2(productId: $productId, keepImages: $keepImages)
}
    `;

export const useCopyProductMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CopyProductMutation,
    TError,
    CopyProductMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CopyProductMutation,
    TError,
    CopyProductMutationVariables,
    TContext
  >({
    mutationKey: ['copyProduct'],
    mutationFn: useFetchData<CopyProductMutation, CopyProductMutationVariables>(
      CopyProductDocument
    ),
    ...options,
  });
};

export const CopySaleDocument = `
    mutation copySale($saleId: String!) {
  copySale(saleId: $saleId)
}
    `;

export const useCopySaleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CopySaleMutation,
    TError,
    CopySaleMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CopySaleMutation,
    TError,
    CopySaleMutationVariables,
    TContext
  >({
    mutationKey: ['copySale'],
    mutationFn: useFetchData<CopySaleMutation, CopySaleMutationVariables>(
      CopySaleDocument
    ),
    ...options,
  });
};

export const CouponsChangedDocument = `
    subscription CouponsChanged {
  couponsChanged
}
    `;
export const CrawlDriveImagesDocument = `
    mutation crawlDriveImages($urlData: [CrawlDriveUrlDataInput!]!) {
  crawlDriveImages(urlData: $urlData) {
    productName
    images
  }
}
    `;

export const useCrawlDriveImagesMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CrawlDriveImagesMutation,
    TError,
    CrawlDriveImagesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CrawlDriveImagesMutation,
    TError,
    CrawlDriveImagesMutationVariables,
    TContext
  >({
    mutationKey: ['crawlDriveImages'],
    mutationFn: useFetchData<
      CrawlDriveImagesMutation,
      CrawlDriveImagesMutationVariables
    >(CrawlDriveImagesDocument),
    ...options,
  });
};

export const CrawlProductsDocument = `
    mutation crawlProducts($saleId: String!, $urls: [String!]!) {
  crawlProducts(saleId: $saleId, urls: $urls)
}
    `;

export const useCrawlProductsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CrawlProductsMutation,
    TError,
    CrawlProductsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CrawlProductsMutation,
    TError,
    CrawlProductsMutationVariables,
    TContext
  >({
    mutationKey: ['crawlProducts'],
    mutationFn: useFetchData<
      CrawlProductsMutation,
      CrawlProductsMutationVariables
    >(CrawlProductsDocument),
    ...options,
  });
};

export const CrawlingProductsUpdatedDocument = `
    subscription crawlingProductsUpdated($saleId: String!) {
  crawlingProductsUpdated(saleId: $saleId) {
    id
    saleId
    data {
      composition
      description
      images
    }
    error {
      code
      message
    }
  }
}
    `;
export const CreateDurationWidgetDocument = `
    mutation createDurationWidget($saleId: String!) {
  createDurationWidget(saleId: $saleId)
}
    `;

export const useCreateDurationWidgetMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateDurationWidgetMutation,
    TError,
    CreateDurationWidgetMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateDurationWidgetMutation,
    TError,
    CreateDurationWidgetMutationVariables,
    TContext
  >({
    mutationKey: ['createDurationWidget'],
    mutationFn: useFetchData<
      CreateDurationWidgetMutation,
      CreateDurationWidgetMutationVariables
    >(CreateDurationWidgetDocument),
    ...options,
  });
};

export const CreateLogisticFileDocument = `
    mutation createLogisticFile($saleId: String!, $brandId: String) {
  createLogisticFile(saleId: $saleId, brandId: $brandId)
}
    `;

export const useCreateLogisticFileMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateLogisticFileMutation,
    TError,
    CreateLogisticFileMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateLogisticFileMutation,
    TError,
    CreateLogisticFileMutationVariables,
    TContext
  >({
    mutationKey: ['createLogisticFile'],
    mutationFn: useFetchData<
      CreateLogisticFileMutation,
      CreateLogisticFileMutationVariables
    >(CreateLogisticFileDocument),
    ...options,
  });
};

export const FillImagesDocument = `
    mutation fillImages($images: [ImageInput!]!, $sessionId: ID!, $actionId: ID!) {
  fillImages(images: $images, sessionId: $sessionId, actionId: $actionId)
}
    `;

export const useFillImagesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    FillImagesMutation,
    TError,
    FillImagesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    FillImagesMutation,
    TError,
    FillImagesMutationVariables,
    TContext
  >({
    mutationKey: ['fillImages'],
    mutationFn: useFetchData<FillImagesMutation, FillImagesMutationVariables>(
      FillImagesDocument
    ),
    ...options,
  });
};

export const FitImagesDocument = `
    mutation fitImages($images: [ImageInput!]!, $sessionId: ID!, $actionId: ID!) {
  fitImages(images: $images, sessionId: $sessionId, actionId: $actionId)
}
    `;

export const useFitImagesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    FitImagesMutation,
    TError,
    FitImagesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    FitImagesMutation,
    TError,
    FitImagesMutationVariables,
    TContext
  >({
    mutationKey: ['fitImages'],
    mutationFn: useFetchData<FitImagesMutation, FitImagesMutationVariables>(
      FitImagesDocument
    ),
    ...options,
  });
};

export const BadgesDocument = `
    query badges($storeId: StoreRegion!) {
  badges(storeId: $storeId) {
    name
    svg
  }
}
    `;

export const useBadgesQuery = <TData = BadgesQuery, TError = unknown>(
  variables: BadgesQueryVariables,
  options?: Omit<UseQueryOptions<BadgesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<BadgesQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<BadgesQuery, TError, TData>({
    queryKey: ['badges', variables],
    queryFn: useFetchData<BadgesQuery, BadgesQueryVariables>(
      BadgesDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseBadgesQuery = <TData = BadgesQuery, TError = unknown>(
  variables: BadgesQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<BadgesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<BadgesQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<BadgesQuery, TError, TData>({
    queryKey: ['badgesSuspense', variables],
    queryFn: useFetchData<BadgesQuery, BadgesQueryVariables>(
      BadgesDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteBadgesQuery = <
  TData = InfiniteData<BadgesQuery>,
  TError = unknown,
>(
  variables: BadgesQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<BadgesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<BadgesQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<BadgesQuery, BadgesQueryVariables>(BadgesDocument);
  return useInfiniteQuery<BadgesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['badges.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteBadgesQuery = <
  TData = InfiniteData<BadgesQuery>,
  TError = unknown,
>(
  variables: BadgesQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<BadgesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      BadgesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<BadgesQuery, BadgesQueryVariables>(BadgesDocument);
  return useSuspenseInfiniteQuery<BadgesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['badges.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const BrandDocument = `
    query brand($brandId: ID!) {
  brand(id: $brandId) {
    about
    contactName
    id
    instagram
    name
  }
}
    `;

export const useBrandQuery = <TData = BrandQuery, TError = unknown>(
  variables: BrandQueryVariables,
  options?: Omit<UseQueryOptions<BrandQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<BrandQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<BrandQuery, TError, TData>({
    queryKey: ['brand', variables],
    queryFn: useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useSuspenseBrandQuery = <TData = BrandQuery, TError = unknown>(
  variables: BrandQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<BrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<BrandQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<BrandQuery, TError, TData>({
    queryKey: ['brandSuspense', variables],
    queryFn: useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useInfiniteBrandQuery = <
  TData = InfiniteData<BrandQuery>,
  TError = unknown,
>(
  variables: BrandQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<BrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<BrandQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument);
  return useInfiniteQuery<BrandQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['brand.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteBrandQuery = <
  TData = InfiniteData<BrandQuery>,
  TError = unknown,
>(
  variables: BrandQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<BrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      BrandQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument);
  return useSuspenseInfiniteQuery<BrandQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['brand.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const CalendarSalesDocument = `
    query CalendarSales($status: SaleStatus!, $storeId: StoreRegion!) {
  sales(status: $status, storeId: $storeId) {
    sales {
      brand {
        about
        id
        name
        contactEmail
        contactName
        website
        instagram
      }
      cancellation_level
      category
      deliver_by_choose
      delivery_offered_until
      delivery_offered_by
      description
      discount
      duplicated_sale_id
      end_at
      end_at_date
      forecast
      gender
      hide
      hubspot_category
      hubspot_deal_id
      hubspot_team_name
      hubspot_top_brand_type
      id
      image_header
      is_cancelled
      is_ready
      is_setup
      isShopify
      logistics_manager
      note
      operation_type
      original_sale_id
      previews
      sale_status
      selection_name
      setup_status
      setup_by
      shippingPolicy {
        pickupPoint {
          network
          shippingCost
        }
      }
      shopify {
        hasCredentials
        isStockLive
        testMondialRelayOn
        testOrderOn
      }
      start_at
      start_at_date
      stats_stock
      storeId
      total_sales
      url_google_drive
    }
  }
}
    `;

export const useCalendarSalesQuery = <
  TData = CalendarSalesQuery,
  TError = unknown,
>(
  variables: CalendarSalesQueryVariables,
  options?: Omit<
    UseQueryOptions<CalendarSalesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CalendarSalesQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<CalendarSalesQuery, TError, TData>({
    queryKey: ['CalendarSales', variables],
    queryFn: useFetchData<CalendarSalesQuery, CalendarSalesQueryVariables>(
      CalendarSalesDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseCalendarSalesQuery = <
  TData = CalendarSalesQuery,
  TError = unknown,
>(
  variables: CalendarSalesQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CalendarSalesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CalendarSalesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<CalendarSalesQuery, TError, TData>({
    queryKey: ['CalendarSalesSuspense', variables],
    queryFn: useFetchData<CalendarSalesQuery, CalendarSalesQueryVariables>(
      CalendarSalesDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteCalendarSalesQuery = <
  TData = InfiniteData<CalendarSalesQuery>,
  TError = unknown,
>(
  variables: CalendarSalesQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<CalendarSalesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      CalendarSalesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<CalendarSalesQuery, CalendarSalesQueryVariables>(
    CalendarSalesDocument
  );
  return useInfiniteQuery<CalendarSalesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['CalendarSales.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteCalendarSalesQuery = <
  TData = InfiniteData<CalendarSalesQuery>,
  TError = unknown,
>(
  variables: CalendarSalesQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<CalendarSalesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      CalendarSalesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<CalendarSalesQuery, CalendarSalesQueryVariables>(
    CalendarSalesDocument
  );
  return useSuspenseInfiniteQuery<CalendarSalesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'CalendarSales.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const CategoriesDocument = `
    query Categories {
  categories {
    ids
    sub_categories {
      category
      ids
    }
    category
  }
}
    `;

export const useCategoriesQuery = <TData = CategoriesQuery, TError = unknown>(
  variables?: CategoriesQueryVariables,
  options?: Omit<
    UseQueryOptions<CategoriesQuery, TError, TData>,
    'queryKey'
  > & { queryKey?: UseQueryOptions<CategoriesQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<CategoriesQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['Categories'] : ['Categories', variables],
    queryFn: useFetchData<CategoriesQuery, CategoriesQueryVariables>(
      CategoriesDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseCategoriesQuery = <
  TData = CategoriesQuery,
  TError = unknown,
>(
  variables?: CategoriesQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CategoriesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CategoriesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<CategoriesQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CategoriesSuspense']
        : ['CategoriesSuspense', variables],
    queryFn: useFetchData<CategoriesQuery, CategoriesQueryVariables>(
      CategoriesDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteCategoriesQuery = <
  TData = InfiniteData<CategoriesQuery>,
  TError = unknown,
>(
  variables: CategoriesQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<CategoriesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      CategoriesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument
  );
  return useInfiniteQuery<CategoriesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['Categories.infinite']
            : ['Categories.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteCategoriesQuery = <
  TData = InfiniteData<CategoriesQuery>,
  TError = unknown,
>(
  variables: CategoriesQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<CategoriesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      CategoriesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument
  );
  return useSuspenseInfiniteQuery<CategoriesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['Categories.infiniteSuspense']
            : ['Categories.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const CategoryEssentialOptionsDocument = `
    query CategoryEssentialOptions($categoryId: String!) {
  categoryEssentialOptions(categoryId: $categoryId) {
    text
    type
    values
    placeholders
  }
}
    `;

export const useCategoryEssentialOptionsQuery = <
  TData = CategoryEssentialOptionsQuery,
  TError = unknown,
>(
  variables: CategoryEssentialOptionsQueryVariables,
  options?: Omit<
    UseQueryOptions<CategoryEssentialOptionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      CategoryEssentialOptionsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<CategoryEssentialOptionsQuery, TError, TData>({
    queryKey: ['CategoryEssentialOptions', variables],
    queryFn: useFetchData<
      CategoryEssentialOptionsQuery,
      CategoryEssentialOptionsQueryVariables
    >(CategoryEssentialOptionsDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseCategoryEssentialOptionsQuery = <
  TData = CategoryEssentialOptionsQuery,
  TError = unknown,
>(
  variables: CategoryEssentialOptionsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CategoryEssentialOptionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CategoryEssentialOptionsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<CategoryEssentialOptionsQuery, TError, TData>({
    queryKey: ['CategoryEssentialOptionsSuspense', variables],
    queryFn: useFetchData<
      CategoryEssentialOptionsQuery,
      CategoryEssentialOptionsQueryVariables
    >(CategoryEssentialOptionsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteCategoryEssentialOptionsQuery = <
  TData = InfiniteData<CategoryEssentialOptionsQuery>,
  TError = unknown,
>(
  variables: CategoryEssentialOptionsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<CategoryEssentialOptionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      CategoryEssentialOptionsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    CategoryEssentialOptionsQuery,
    CategoryEssentialOptionsQueryVariables
  >(CategoryEssentialOptionsDocument);
  return useInfiniteQuery<CategoryEssentialOptionsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'CategoryEssentialOptions.infinite',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteCategoryEssentialOptionsQuery = <
  TData = InfiniteData<CategoryEssentialOptionsQuery>,
  TError = unknown,
>(
  variables: CategoryEssentialOptionsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<
      CategoryEssentialOptionsQuery,
      TError,
      TData
    >,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      CategoryEssentialOptionsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    CategoryEssentialOptionsQuery,
    CategoryEssentialOptionsQueryVariables
  >(CategoryEssentialOptionsDocument);
  return useSuspenseInfiniteQuery<CategoryEssentialOptionsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'CategoryEssentialOptions.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const CategoryOptionsDocument = `
    query CategoryOptions($categoryId: String!) {
  categoryOptions(categoryId: $categoryId) {
    text
    type
    values
    placeholders
  }
}
    `;

export const useCategoryOptionsQuery = <
  TData = CategoryOptionsQuery,
  TError = unknown,
>(
  variables: CategoryOptionsQueryVariables,
  options?: Omit<
    UseQueryOptions<CategoryOptionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CategoryOptionsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<CategoryOptionsQuery, TError, TData>({
    queryKey: ['CategoryOptions', variables],
    queryFn: useFetchData<CategoryOptionsQuery, CategoryOptionsQueryVariables>(
      CategoryOptionsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseCategoryOptionsQuery = <
  TData = CategoryOptionsQuery,
  TError = unknown,
>(
  variables: CategoryOptionsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CategoryOptionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CategoryOptionsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<CategoryOptionsQuery, TError, TData>({
    queryKey: ['CategoryOptionsSuspense', variables],
    queryFn: useFetchData<CategoryOptionsQuery, CategoryOptionsQueryVariables>(
      CategoryOptionsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteCategoryOptionsQuery = <
  TData = InfiniteData<CategoryOptionsQuery>,
  TError = unknown,
>(
  variables: CategoryOptionsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<CategoryOptionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      CategoryOptionsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    CategoryOptionsQuery,
    CategoryOptionsQueryVariables
  >(CategoryOptionsDocument);
  return useInfiniteQuery<CategoryOptionsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['CategoryOptions.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteCategoryOptionsQuery = <
  TData = InfiniteData<CategoryOptionsQuery>,
  TError = unknown,
>(
  variables: CategoryOptionsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<CategoryOptionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      CategoryOptionsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    CategoryOptionsQuery,
    CategoryOptionsQueryVariables
  >(CategoryOptionsDocument);
  return useSuspenseInfiniteQuery<CategoryOptionsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'CategoryOptions.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const CategoryTreeDocument = `
    query CategoryTree {
  categoryTree {
    id
    label
    tva
    notReturnable
    subCategories {
      id
      label
      tva
      notReturnable
      subCategories {
        id
        label
        tva
        notReturnable
        subCategories {
          id
          label
          tva
          notReturnable
          subCategories {
            id
            label
            tva
            notReturnable
            subCategories {
              id
              label
              tva
              notReturnable
              subCategories {
                id
                label
                tva
                notReturnable
              }
            }
          }
        }
      }
    }
  }
}
    `;

export const useCategoryTreeQuery = <
  TData = CategoryTreeQuery,
  TError = unknown,
>(
  variables?: CategoryTreeQueryVariables,
  options?: Omit<
    UseQueryOptions<CategoryTreeQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CategoryTreeQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<CategoryTreeQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['CategoryTree'] : ['CategoryTree', variables],
    queryFn: useFetchData<CategoryTreeQuery, CategoryTreeQueryVariables>(
      CategoryTreeDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseCategoryTreeQuery = <
  TData = CategoryTreeQuery,
  TError = unknown,
>(
  variables?: CategoryTreeQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CategoryTreeQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CategoryTreeQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<CategoryTreeQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CategoryTreeSuspense']
        : ['CategoryTreeSuspense', variables],
    queryFn: useFetchData<CategoryTreeQuery, CategoryTreeQueryVariables>(
      CategoryTreeDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteCategoryTreeQuery = <
  TData = InfiniteData<CategoryTreeQuery>,
  TError = unknown,
>(
  variables: CategoryTreeQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<CategoryTreeQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      CategoryTreeQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<CategoryTreeQuery, CategoryTreeQueryVariables>(
    CategoryTreeDocument
  );
  return useInfiniteQuery<CategoryTreeQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['CategoryTree.infinite']
            : ['CategoryTree.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteCategoryTreeQuery = <
  TData = InfiniteData<CategoryTreeQuery>,
  TError = unknown,
>(
  variables: CategoryTreeQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<CategoryTreeQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      CategoryTreeQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<CategoryTreeQuery, CategoryTreeQueryVariables>(
    CategoryTreeDocument
  );
  return useSuspenseInfiniteQuery<CategoryTreeQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['CategoryTree.infiniteSuspense']
            : ['CategoryTree.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const CouponsDocument = `
    query Coupons($status: CouponStatus!, $storeId: StoreRegion!) {
  coupons(status: $status, store_id: $storeId) {
    id
    code
    type
    value
    start_at
    expires_at
    quantity
    quantity_per_user
    minimum_order_amount
    first_order_only
    total_used
    store_id
  }
}
    `;

export const useCouponsQuery = <TData = CouponsQuery, TError = unknown>(
  variables: CouponsQueryVariables,
  options?: Omit<UseQueryOptions<CouponsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<CouponsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<CouponsQuery, TError, TData>({
    queryKey: ['Coupons', variables],
    queryFn: useFetchData<CouponsQuery, CouponsQueryVariables>(
      CouponsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseCouponsQuery = <TData = CouponsQuery, TError = unknown>(
  variables: CouponsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CouponsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<CouponsQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<CouponsQuery, TError, TData>({
    queryKey: ['CouponsSuspense', variables],
    queryFn: useFetchData<CouponsQuery, CouponsQueryVariables>(
      CouponsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteCouponsQuery = <
  TData = InfiniteData<CouponsQuery>,
  TError = unknown,
>(
  variables: CouponsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<CouponsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<CouponsQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<CouponsQuery, CouponsQueryVariables>(
    CouponsDocument
  );
  return useInfiniteQuery<CouponsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['Coupons.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteCouponsQuery = <
  TData = InfiniteData<CouponsQuery>,
  TError = unknown,
>(
  variables: CouponsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<CouponsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      CouponsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<CouponsQuery, CouponsQueryVariables>(
    CouponsDocument
  );
  return useSuspenseInfiniteQuery<CouponsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['Coupons.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const GetImagesByProductIdsDocument = `
    query getImagesByProductIds($productIds: [ID!]!) {
  getImagesByProductIds(productIds: $productIds) {
    id
    productId
    url
    originalUrl
    currentVersion
    isCover
    isVariantCover
    isOriginal
    aspect
  }
}
    `;

export const useGetImagesByProductIdsQuery = <
  TData = GetImagesByProductIdsQuery,
  TError = unknown,
>(
  variables: GetImagesByProductIdsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetImagesByProductIdsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetImagesByProductIdsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<GetImagesByProductIdsQuery, TError, TData>({
    queryKey: ['getImagesByProductIds', variables],
    queryFn: useFetchData<
      GetImagesByProductIdsQuery,
      GetImagesByProductIdsQueryVariables
    >(GetImagesByProductIdsDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseGetImagesByProductIdsQuery = <
  TData = GetImagesByProductIdsQuery,
  TError = unknown,
>(
  variables: GetImagesByProductIdsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<GetImagesByProductIdsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      GetImagesByProductIdsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<GetImagesByProductIdsQuery, TError, TData>({
    queryKey: ['getImagesByProductIdsSuspense', variables],
    queryFn: useFetchData<
      GetImagesByProductIdsQuery,
      GetImagesByProductIdsQueryVariables
    >(GetImagesByProductIdsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetImagesByProductIdsQuery = <
  TData = InfiniteData<GetImagesByProductIdsQuery>,
  TError = unknown,
>(
  variables: GetImagesByProductIdsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetImagesByProductIdsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetImagesByProductIdsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    GetImagesByProductIdsQuery,
    GetImagesByProductIdsQueryVariables
  >(GetImagesByProductIdsDocument);
  return useInfiniteQuery<GetImagesByProductIdsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'getImagesByProductIds.infinite',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteGetImagesByProductIdsQuery = <
  TData = InfiniteData<GetImagesByProductIdsQuery>,
  TError = unknown,
>(
  variables: GetImagesByProductIdsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<GetImagesByProductIdsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      GetImagesByProductIdsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    GetImagesByProductIdsQuery,
    GetImagesByProductIdsQueryVariables
  >(GetImagesByProductIdsDocument);
  return useSuspenseInfiniteQuery<GetImagesByProductIdsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'getImagesByProductIds.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const GetProductCouponsDocument = `
    query getProductCoupons($productId: String!) {
  getProductCoupons(productId: $productId) {
    product_id
    code
    order_id
  }
}
    `;

export const useGetProductCouponsQuery = <
  TData = GetProductCouponsQuery,
  TError = unknown,
>(
  variables: GetProductCouponsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetProductCouponsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetProductCouponsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<GetProductCouponsQuery, TError, TData>({
    queryKey: ['getProductCoupons', variables],
    queryFn: useFetchData<
      GetProductCouponsQuery,
      GetProductCouponsQueryVariables
    >(GetProductCouponsDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseGetProductCouponsQuery = <
  TData = GetProductCouponsQuery,
  TError = unknown,
>(
  variables: GetProductCouponsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<GetProductCouponsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      GetProductCouponsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<GetProductCouponsQuery, TError, TData>({
    queryKey: ['getProductCouponsSuspense', variables],
    queryFn: useFetchData<
      GetProductCouponsQuery,
      GetProductCouponsQueryVariables
    >(GetProductCouponsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetProductCouponsQuery = <
  TData = InfiniteData<GetProductCouponsQuery>,
  TError = unknown,
>(
  variables: GetProductCouponsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetProductCouponsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetProductCouponsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    GetProductCouponsQuery,
    GetProductCouponsQueryVariables
  >(GetProductCouponsDocument);
  return useInfiniteQuery<GetProductCouponsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getProductCoupons.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteGetProductCouponsQuery = <
  TData = InfiniteData<GetProductCouponsQuery>,
  TError = unknown,
>(
  variables: GetProductCouponsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<GetProductCouponsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      GetProductCouponsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    GetProductCouponsQuery,
    GetProductCouponsQueryVariables
  >(GetProductCouponsDocument);
  return useSuspenseInfiniteQuery<GetProductCouponsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'getProductCoupons.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const GetProductDocument = `
    query getProduct($productId: String!) {
  product(productId: $productId) {
    id
    type
    isDraft
    isAlcohol
    badges {
      isSpecial
      position
      value
    }
    sourceUrl
    hide
    category {
      name
      vat
    }
    description
    name
    currency
    brand {
      id
      name
      about
    }
    variants {
      id
      position
      isUnique
      options {
        productOptionId
        valueId
      }
      hide
      gtin
      sku
      images
      absorbedShipping
      buyingPrice
      chooseFees
      deliveryDelays
      discountPercentage
      eeePrice
      retailPrice
      shippingFees
      weightGram
      widthMillimeter
      heightMillimeter
      lengthMillimeter
      stockCount
      originCountry
    }
    options {
      id
      type
      name
      images {
        id
        baseKey
        image
      }
      position
      values {
        id
        value
        position
      }
    }
    gender
    couponDetail
    couponExpirationDate
    cgu
    faqPreOrder
    faq
    faqPostOrder
    customFields {
      key
      position
      type
      value
    }
    isCobrand
    isReturnable
    isDeliveredByChoose
    isGiveaway
    position
    images {
      id
      baseKey
      image
    }
  }
}
    `;

export const useGetProductQuery = <TData = GetProductQuery, TError = unknown>(
  variables: GetProductQueryVariables,
  options?: Omit<
    UseQueryOptions<GetProductQuery, TError, TData>,
    'queryKey'
  > & { queryKey?: UseQueryOptions<GetProductQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<GetProductQuery, TError, TData>({
    queryKey: ['getProduct', variables],
    queryFn: useFetchData<GetProductQuery, GetProductQueryVariables>(
      GetProductDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseGetProductQuery = <
  TData = GetProductQuery,
  TError = unknown,
>(
  variables: GetProductQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<GetProductQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      GetProductQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<GetProductQuery, TError, TData>({
    queryKey: ['getProductSuspense', variables],
    queryFn: useFetchData<GetProductQuery, GetProductQueryVariables>(
      GetProductDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetProductQuery = <
  TData = InfiniteData<GetProductQuery>,
  TError = unknown,
>(
  variables: GetProductQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetProductQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetProductQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument
  );
  return useInfiniteQuery<GetProductQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getProduct.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteGetProductQuery = <
  TData = InfiniteData<GetProductQuery>,
  TError = unknown,
>(
  variables: GetProductQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<GetProductQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      GetProductQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument
  );
  return useSuspenseInfiniteQuery<GetProductQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getProduct.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const ProvisionalSalesDocument = `
    query ProvisionalSales($storeId: StoreRegion!) {
  provisionalSales(storeId: $storeId) {
    id
    name
    category
    saleBy
    startsAt
    brandHasShopify
    forecast
    hubspotId
    hubspotCategory
    hubspot_team_name
    hubspot_top_brand_type
    cancellationLevel
    operationType
    gender
    instagramId
    website
    note
    brandSaleCount
    shopify {
      hasCredentials
      isStockLive
    }
  }
}
    `;

export const useProvisionalSalesQuery = <
  TData = ProvisionalSalesQuery,
  TError = unknown,
>(
  variables: ProvisionalSalesQueryVariables,
  options?: Omit<
    UseQueryOptions<ProvisionalSalesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      ProvisionalSalesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<ProvisionalSalesQuery, TError, TData>({
    queryKey: ['ProvisionalSales', variables],
    queryFn: useFetchData<
      ProvisionalSalesQuery,
      ProvisionalSalesQueryVariables
    >(ProvisionalSalesDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseProvisionalSalesQuery = <
  TData = ProvisionalSalesQuery,
  TError = unknown,
>(
  variables: ProvisionalSalesQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<ProvisionalSalesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      ProvisionalSalesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<ProvisionalSalesQuery, TError, TData>({
    queryKey: ['ProvisionalSalesSuspense', variables],
    queryFn: useFetchData<
      ProvisionalSalesQuery,
      ProvisionalSalesQueryVariables
    >(ProvisionalSalesDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteProvisionalSalesQuery = <
  TData = InfiniteData<ProvisionalSalesQuery>,
  TError = unknown,
>(
  variables: ProvisionalSalesQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<ProvisionalSalesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      ProvisionalSalesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    ProvisionalSalesQuery,
    ProvisionalSalesQueryVariables
  >(ProvisionalSalesDocument);
  return useInfiniteQuery<ProvisionalSalesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['ProvisionalSales.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteProvisionalSalesQuery = <
  TData = InfiniteData<ProvisionalSalesQuery>,
  TError = unknown,
>(
  variables: ProvisionalSalesQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<ProvisionalSalesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      ProvisionalSalesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    ProvisionalSalesQuery,
    ProvisionalSalesQueryVariables
  >(ProvisionalSalesDocument);
  return useSuspenseInfiniteQuery<ProvisionalSalesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'ProvisionalSales.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const ReferralBlockDocument = `
    query referralBlock($store: StoreRegion!) {
  marketing {
    referralBlock(store: $store) {
      bigImage
      btnCta
      date
      smallImage
      tagline
      title
    }
  }
}
    `;

export const useReferralBlockQuery = <
  TData = ReferralBlockQuery,
  TError = unknown,
>(
  variables: ReferralBlockQueryVariables,
  options?: Omit<
    UseQueryOptions<ReferralBlockQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<ReferralBlockQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ReferralBlockQuery, TError, TData>({
    queryKey: ['referralBlock', variables],
    queryFn: useFetchData<ReferralBlockQuery, ReferralBlockQueryVariables>(
      ReferralBlockDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseReferralBlockQuery = <
  TData = ReferralBlockQuery,
  TError = unknown,
>(
  variables: ReferralBlockQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<ReferralBlockQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      ReferralBlockQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<ReferralBlockQuery, TError, TData>({
    queryKey: ['referralBlockSuspense', variables],
    queryFn: useFetchData<ReferralBlockQuery, ReferralBlockQueryVariables>(
      ReferralBlockDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteReferralBlockQuery = <
  TData = InfiniteData<ReferralBlockQuery>,
  TError = unknown,
>(
  variables: ReferralBlockQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<ReferralBlockQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      ReferralBlockQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<ReferralBlockQuery, ReferralBlockQueryVariables>(
    ReferralBlockDocument
  );
  return useInfiniteQuery<ReferralBlockQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['referralBlock.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteReferralBlockQuery = <
  TData = InfiniteData<ReferralBlockQuery>,
  TError = unknown,
>(
  variables: ReferralBlockQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<ReferralBlockQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      ReferralBlockQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<ReferralBlockQuery, ReferralBlockQueryVariables>(
    ReferralBlockDocument
  );
  return useSuspenseInfiniteQuery<ReferralBlockQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'referralBlock.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const GetReferralUploadUrlDocument = `
    query getReferralUploadUrl($fileType: String!, $saleId: String!, $uploadType: MarketingReferralImageType!) {
  marketing {
    getReferralUploadUrl(
      fileType: $fileType
      saleId: $saleId
      uploadType: $uploadType
    ) {
      key
      url
    }
  }
}
    `;

export const useGetReferralUploadUrlQuery = <
  TData = GetReferralUploadUrlQuery,
  TError = unknown,
>(
  variables: GetReferralUploadUrlQueryVariables,
  options?: Omit<
    UseQueryOptions<GetReferralUploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetReferralUploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<GetReferralUploadUrlQuery, TError, TData>({
    queryKey: ['getReferralUploadUrl', variables],
    queryFn: useFetchData<
      GetReferralUploadUrlQuery,
      GetReferralUploadUrlQueryVariables
    >(GetReferralUploadUrlDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseGetReferralUploadUrlQuery = <
  TData = GetReferralUploadUrlQuery,
  TError = unknown,
>(
  variables: GetReferralUploadUrlQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<GetReferralUploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      GetReferralUploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<GetReferralUploadUrlQuery, TError, TData>({
    queryKey: ['getReferralUploadUrlSuspense', variables],
    queryFn: useFetchData<
      GetReferralUploadUrlQuery,
      GetReferralUploadUrlQueryVariables
    >(GetReferralUploadUrlDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetReferralUploadUrlQuery = <
  TData = InfiniteData<GetReferralUploadUrlQuery>,
  TError = unknown,
>(
  variables: GetReferralUploadUrlQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetReferralUploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetReferralUploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    GetReferralUploadUrlQuery,
    GetReferralUploadUrlQueryVariables
  >(GetReferralUploadUrlDocument);
  return useInfiniteQuery<GetReferralUploadUrlQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'getReferralUploadUrl.infinite',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteGetReferralUploadUrlQuery = <
  TData = InfiniteData<GetReferralUploadUrlQuery>,
  TError = unknown,
>(
  variables: GetReferralUploadUrlQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<GetReferralUploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      GetReferralUploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    GetReferralUploadUrlQuery,
    GetReferralUploadUrlQueryVariables
  >(GetReferralUploadUrlDocument);
  return useSuspenseInfiniteQuery<GetReferralUploadUrlQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'getReferralUploadUrl.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const SaleProductsDocument = `
    query saleProducts($key: String!) {
  sale(key: $key) {
    products {
      badges {
        isSpecial
        position
        value
      }
      brand {
        id
        name
        about
      }
      currency
      category {
        name
        vat
      }
      description
      hide
      id
      images {
        id
        baseKey
        image
      }
      importCsvPosition
      isCobrand
      isDeliveredByChoose
      isDraft
      isGiveaway
      isReturnable
      name
      options {
        id
        type
        name
        images {
          id
          baseKey
          image
        }
        position
        values {
          id
          value
          position
        }
      }
      position
      sourceUrl
      variants {
        id
        position
        isUnique
        options {
          productOptionId
          valueId
        }
        hide
        gtin
        sku
        images
        absorbedShipping
        buyingPrice
        chooseFees
        computedDeliveryDelays
        discountPercentage
        eeePrice
        retailPrice
        shippingFees
        weightGram
        stockCount
        originCountry
      }
      type
    }
  }
}
    `;

export const useSaleProductsQuery = <
  TData = SaleProductsQuery,
  TError = unknown,
>(
  variables: SaleProductsQueryVariables,
  options?: Omit<
    UseQueryOptions<SaleProductsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SaleProductsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SaleProductsQuery, TError, TData>({
    queryKey: ['saleProducts', variables],
    queryFn: useFetchData<SaleProductsQuery, SaleProductsQueryVariables>(
      SaleProductsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseSaleProductsQuery = <
  TData = SaleProductsQuery,
  TError = unknown,
>(
  variables: SaleProductsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<SaleProductsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      SaleProductsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<SaleProductsQuery, TError, TData>({
    queryKey: ['saleProductsSuspense', variables],
    queryFn: useFetchData<SaleProductsQuery, SaleProductsQueryVariables>(
      SaleProductsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteSaleProductsQuery = <
  TData = InfiniteData<SaleProductsQuery>,
  TError = unknown,
>(
  variables: SaleProductsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<SaleProductsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      SaleProductsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<SaleProductsQuery, SaleProductsQueryVariables>(
    SaleProductsDocument
  );
  return useInfiniteQuery<SaleProductsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['saleProducts.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteSaleProductsQuery = <
  TData = InfiniteData<SaleProductsQuery>,
  TError = unknown,
>(
  variables: SaleProductsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<SaleProductsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      SaleProductsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<SaleProductsQuery, SaleProductsQueryVariables>(
    SaleProductsDocument
  );
  return useSuspenseInfiniteQuery<SaleProductsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'saleProducts.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const SaleDocument = `
    query sale($key: String!) {
  sale(key: $key) {
    ads {
      created_at
      is_retarget
      is_cold
      ids
      video_url
    }
    badges
    brand {
      id
      name
      contactName
      sale_objectif_name
      contactPhone
      accept_contract
      about
      contactEmail
      instagram
      address
      isFrench
      is_autoentrepreneur
      shipping_restricted_to_france
      emailedAt
      emailId
      plugins {
        shopify
      }
    }
    cashbackPolicy {
      delayInDays
      thresholds {
        threshold
        cashbackAmount
      }
    }
    category
    currency
    deliver_by_choose
    delivery_delays
    delivery_offered_until
    delivery_offered_by
    description
    disable_sorting
    duplicated_sale_id
    end_at
    end_at_date
    giveawayPolicies {
      saleId
      overOrderAmount
      productId
      dateRange {
        startAt
        endAt
      }
    }
    has_orders
    hasReturnableProducts
    hide
    hubspot_deal_id
    id
    image_header
    initial_start_at
    invoice {
      is_invoice_ready
      invoice_amount
      invoice_by
      invoice_on
      paid_on
      proof_paid_url
      paid_amount
      paid_by
    }
    is_cancelled
    is_ready
    is_selection
    is_setup
    isShopify
    logistics_manager
    newImages {
      baseKey
      id
      image
      properties {
        full
        gender
      }
    }
    newPreviews {
      baseKey
      full
      id
      image
    }
    operation_type
    original_sale_id
    sale_status
    selection_name
    setup_by
    shopify {
      testMondialRelayOn
      testOrderOn
    }
    shippingPolicy {
      freeShippingMinimumOrderAmount
      pickupPoint {
        network
        shippingCost
      }
    }
    start_at
    start_at_date
    status
    storeId
    tva
    upcoming_display_forced
    url
    url_google_drive
    widgets {
      created_at
      id
      proposition
      results
      state
      type
    }
  }
}
    `;

export const useSaleQuery = <TData = SaleQuery, TError = unknown>(
  variables: SaleQueryVariables,
  options?: Omit<UseQueryOptions<SaleQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SaleQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SaleQuery, TError, TData>({
    queryKey: ['sale', variables],
    queryFn: useFetchData<SaleQuery, SaleQueryVariables>(SaleDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useSuspenseSaleQuery = <TData = SaleQuery, TError = unknown>(
  variables: SaleQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<SaleQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<SaleQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<SaleQuery, TError, TData>({
    queryKey: ['saleSuspense', variables],
    queryFn: useFetchData<SaleQuery, SaleQueryVariables>(SaleDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useInfiniteSaleQuery = <
  TData = InfiniteData<SaleQuery>,
  TError = unknown,
>(
  variables: SaleQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<SaleQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<SaleQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<SaleQuery, SaleQueryVariables>(SaleDocument);
  return useInfiniteQuery<SaleQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['sale.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteSaleQuery = <
  TData = InfiniteData<SaleQuery>,
  TError = unknown,
>(
  variables: SaleQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<SaleQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      SaleQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<SaleQuery, SaleQueryVariables>(SaleDocument);
  return useSuspenseInfiniteQuery<SaleQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['sale.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const SalesDocument = `
    query Sales($status: SaleStatus!, $storeId: StoreRegion!, $offset: Int) {
  sales(status: $status, storeId: $storeId, offset: $offset) {
    has_more
    pagination
    sales {
      badges
      brand {
        id
        name
        contactEmail
        contactName
        website
        instagram
      }
      category
      deliver_by_choose
      delivery_offered_until
      delivery_offered_by
      discount
      end_at
      end_at_date
      hide
      id
      image_header
      is_selection
      logistics_manager
      sale_status
      selection_name
      setup_by
      start_at
      start_at_date
      storeId
      upcoming_display_forced
    }
    total_sales
  }
}
    `;

export const useSalesQuery = <TData = SalesQuery, TError = unknown>(
  variables: SalesQueryVariables,
  options?: Omit<UseQueryOptions<SalesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SalesQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SalesQuery, TError, TData>({
    queryKey: ['Sales', variables],
    queryFn: useFetchData<SalesQuery, SalesQueryVariables>(SalesDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useSuspenseSalesQuery = <TData = SalesQuery, TError = unknown>(
  variables: SalesQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<SalesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<SalesQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<SalesQuery, TError, TData>({
    queryKey: ['SalesSuspense', variables],
    queryFn: useFetchData<SalesQuery, SalesQueryVariables>(SalesDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useInfiniteSalesQuery = <
  TData = InfiniteData<SalesQuery>,
  TError = unknown,
>(
  variables: SalesQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<SalesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<SalesQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<SalesQuery, SalesQueryVariables>(SalesDocument);
  return useInfiniteQuery<SalesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['Sales.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteSalesQuery = <
  TData = InfiniteData<SalesQuery>,
  TError = unknown,
>(
  variables: SalesQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<SalesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      SalesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<SalesQuery, SalesQueryVariables>(SalesDocument);
  return useSuspenseInfiniteQuery<SalesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['Sales.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const GetUploadUrlDocument = `
    query GetUploadUrl($fileType: String!, $uploadType: FileUploadType!, $saleId: String!, $name: String) {
  getUploadUrl(
    fileType: $fileType
    uploadType: $uploadType
    saleId: $saleId
    name: $name
  )
}
    `;

export const useGetUploadUrlQuery = <
  TData = GetUploadUrlQuery,
  TError = unknown,
>(
  variables: GetUploadUrlQueryVariables,
  options?: Omit<
    UseQueryOptions<GetUploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetUploadUrlQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<GetUploadUrlQuery, TError, TData>({
    queryKey: ['GetUploadUrl', variables],
    queryFn: useFetchData<GetUploadUrlQuery, GetUploadUrlQueryVariables>(
      GetUploadUrlDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseGetUploadUrlQuery = <
  TData = GetUploadUrlQuery,
  TError = unknown,
>(
  variables: GetUploadUrlQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<GetUploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      GetUploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<GetUploadUrlQuery, TError, TData>({
    queryKey: ['GetUploadUrlSuspense', variables],
    queryFn: useFetchData<GetUploadUrlQuery, GetUploadUrlQueryVariables>(
      GetUploadUrlDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetUploadUrlQuery = <
  TData = InfiniteData<GetUploadUrlQuery>,
  TError = unknown,
>(
  variables: GetUploadUrlQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetUploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetUploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<GetUploadUrlQuery, GetUploadUrlQueryVariables>(
    GetUploadUrlDocument
  );
  return useInfiniteQuery<GetUploadUrlQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['GetUploadUrl.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteGetUploadUrlQuery = <
  TData = InfiniteData<GetUploadUrlQuery>,
  TError = unknown,
>(
  variables: GetUploadUrlQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<GetUploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      GetUploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<GetUploadUrlQuery, GetUploadUrlQueryVariables>(
    GetUploadUrlDocument
  );
  return useSuspenseInfiniteQuery<GetUploadUrlQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'GetUploadUrl.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const HideOutOfStockProductsAndVariantsDocument = `
    mutation hideOutOfStockProductsAndVariants($productIds: [ID!]!) {
  hideOutOfStockProductsAndVariants(productIds: $productIds)
}
    `;

export const useHideOutOfStockProductsAndVariantsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    HideOutOfStockProductsAndVariantsMutation,
    TError,
    HideOutOfStockProductsAndVariantsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    HideOutOfStockProductsAndVariantsMutation,
    TError,
    HideOutOfStockProductsAndVariantsMutationVariables,
    TContext
  >({
    mutationKey: ['hideOutOfStockProductsAndVariants'],
    mutationFn: useFetchData<
      HideOutOfStockProductsAndVariantsMutation,
      HideOutOfStockProductsAndVariantsMutationVariables
    >(HideOutOfStockProductsAndVariantsDocument),
    ...options,
  });
};

export const ImageProccessedDocument = `
    subscription imageProccessed($sessionId: ID!) {
  imageProccessed(sessionId: $sessionId) {
    actionId
    id
    url
  }
}
    `;
export const ImportProductsUpdatedDocument = `
    subscription importProductsUpdated($saleId: String!) {
  importProductsUpdated(saleId: $saleId) {
    id
    saleId
    error {
      code
      message
    }
  }
}
    `;
export const ImportProductsDocument = `
    mutation importProducts($saleId: String!, $sync: Boolean, $csvContent: String) {
  importProducts(saleId: $saleId, sync: $sync, csvContent: $csvContent)
}
    `;

export const useImportProductsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ImportProductsMutation,
    TError,
    ImportProductsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ImportProductsMutation,
    TError,
    ImportProductsMutationVariables,
    TContext
  >({
    mutationKey: ['importProducts'],
    mutationFn: useFetchData<
      ImportProductsMutation,
      ImportProductsMutationVariables
    >(ImportProductsDocument),
    ...options,
  });
};

export const LastNotificationDocument = `
    query lastNotification($storeId: StoreRegion!) {
  last_notification(storeId: $storeId) {
    notification {
      title
      message
      operator
      send_at
      stats
      for_subscribers
      brand_name
    }
    analytics {
      conversions
      conversions1
      conversions2
      conversions3
      time
      messages {
        ios_push {
          variation_api_id
          sent
          direct_opens
          total_opens
          bounces
          body_clicks
        }
        android_push {
          variation_api_id
          sent
          total_opens
          direct_opens
          bounces
          body_clicks
        }
      }
      conversions_by_send_time
      conversions1_by_send_time
      conversions2_by_send_time
      conversions3_by_send_time
      unique_recipients
      revenue
    }
  }
}
    `;

export const useLastNotificationQuery = <
  TData = LastNotificationQuery,
  TError = unknown,
>(
  variables: LastNotificationQueryVariables,
  options?: Omit<
    UseQueryOptions<LastNotificationQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      LastNotificationQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<LastNotificationQuery, TError, TData>({
    queryKey: ['lastNotification', variables],
    queryFn: useFetchData<
      LastNotificationQuery,
      LastNotificationQueryVariables
    >(LastNotificationDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseLastNotificationQuery = <
  TData = LastNotificationQuery,
  TError = unknown,
>(
  variables: LastNotificationQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<LastNotificationQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      LastNotificationQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<LastNotificationQuery, TError, TData>({
    queryKey: ['lastNotificationSuspense', variables],
    queryFn: useFetchData<
      LastNotificationQuery,
      LastNotificationQueryVariables
    >(LastNotificationDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteLastNotificationQuery = <
  TData = InfiniteData<LastNotificationQuery>,
  TError = unknown,
>(
  variables: LastNotificationQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<LastNotificationQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      LastNotificationQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    LastNotificationQuery,
    LastNotificationQueryVariables
  >(LastNotificationDocument);
  return useInfiniteQuery<LastNotificationQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['lastNotification.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteLastNotificationQuery = <
  TData = InfiniteData<LastNotificationQuery>,
  TError = unknown,
>(
  variables: LastNotificationQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<LastNotificationQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      LastNotificationQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    LastNotificationQuery,
    LastNotificationQueryVariables
  >(LastNotificationDocument);
  return useSuspenseInfiniteQuery<LastNotificationQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'lastNotification.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const MakePublicFileDocument = `
    query makePublicFile($url: String!) {
  makePublicFile(url: $url)
}
    `;

export const useMakePublicFileQuery = <
  TData = MakePublicFileQuery,
  TError = unknown,
>(
  variables: MakePublicFileQueryVariables,
  options?: Omit<
    UseQueryOptions<MakePublicFileQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<MakePublicFileQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<MakePublicFileQuery, TError, TData>({
    queryKey: ['makePublicFile', variables],
    queryFn: useFetchData<MakePublicFileQuery, MakePublicFileQueryVariables>(
      MakePublicFileDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseMakePublicFileQuery = <
  TData = MakePublicFileQuery,
  TError = unknown,
>(
  variables: MakePublicFileQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<MakePublicFileQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      MakePublicFileQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<MakePublicFileQuery, TError, TData>({
    queryKey: ['makePublicFileSuspense', variables],
    queryFn: useFetchData<MakePublicFileQuery, MakePublicFileQueryVariables>(
      MakePublicFileDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteMakePublicFileQuery = <
  TData = InfiniteData<MakePublicFileQuery>,
  TError = unknown,
>(
  variables: MakePublicFileQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<MakePublicFileQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      MakePublicFileQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<MakePublicFileQuery, MakePublicFileQueryVariables>(
    MakePublicFileDocument
  );
  return useInfiniteQuery<MakePublicFileQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['makePublicFile.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteMakePublicFileQuery = <
  TData = InfiniteData<MakePublicFileQuery>,
  TError = unknown,
>(
  variables: MakePublicFileQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<MakePublicFileQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      MakePublicFileQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<MakePublicFileQuery, MakePublicFileQueryVariables>(
    MakePublicFileDocument
  );
  return useSuspenseInfiniteQuery<MakePublicFileQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'makePublicFile.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const MarkSaleTrackingsAsDeliveredDocument = `
    mutation markSaleTrackingsAsDelivered($saleId: String!, $filters: markSaleTrackingsAsDeliveredFilter!) {
  markSaleTrackingsAsDelivered(saleId: $saleId, filters: $filters)
}
    `;

export const useMarkSaleTrackingsAsDeliveredMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    MarkSaleTrackingsAsDeliveredMutation,
    TError,
    MarkSaleTrackingsAsDeliveredMutationVariables,
    TContext
  >
) => {
  return useMutation<
    MarkSaleTrackingsAsDeliveredMutation,
    TError,
    MarkSaleTrackingsAsDeliveredMutationVariables,
    TContext
  >({
    mutationKey: ['markSaleTrackingsAsDelivered'],
    mutationFn: useFetchData<
      MarkSaleTrackingsAsDeliveredMutation,
      MarkSaleTrackingsAsDeliveredMutationVariables
    >(MarkSaleTrackingsAsDeliveredDocument),
    ...options,
  });
};

export const PreviewImageDocument = `
    mutation previewImage($image: TransformImageData!) {
  previewImage(image: $image) {
    __typename
    ... on ImagePreview {
      url
    }
    ... on ErrorLike {
      message
    }
  }
}
    `;

export const usePreviewImageMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PreviewImageMutation,
    TError,
    PreviewImageMutationVariables,
    TContext
  >
) => {
  return useMutation<
    PreviewImageMutation,
    TError,
    PreviewImageMutationVariables,
    TContext
  >({
    mutationKey: ['previewImage'],
    mutationFn: useFetchData<
      PreviewImageMutation,
      PreviewImageMutationVariables
    >(PreviewImageDocument),
    ...options,
  });
};

export const RemoveBackgroundImagesDocument = `
    mutation removeBackgroundImages($images: [ImageInput!]!, $sessionId: ID!, $actionId: ID!) {
  removeBackgroundImages(
    images: $images
    sessionId: $sessionId
    actionId: $actionId
  )
}
    `;

export const useRemoveBackgroundImagesMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveBackgroundImagesMutation,
    TError,
    RemoveBackgroundImagesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveBackgroundImagesMutation,
    TError,
    RemoveBackgroundImagesMutationVariables,
    TContext
  >({
    mutationKey: ['removeBackgroundImages'],
    mutationFn: useFetchData<
      RemoveBackgroundImagesMutation,
      RemoveBackgroundImagesMutationVariables
    >(RemoveBackgroundImagesDocument),
    ...options,
  });
};

export const RemoveCouponDocument = `
    mutation removeCoupon($couponId: Int!) {
  removeCoupon(couponId: $couponId)
}
    `;

export const useRemoveCouponMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveCouponMutation,
    TError,
    RemoveCouponMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveCouponMutation,
    TError,
    RemoveCouponMutationVariables,
    TContext
  >({
    mutationKey: ['removeCoupon'],
    mutationFn: useFetchData<
      RemoveCouponMutation,
      RemoveCouponMutationVariables
    >(RemoveCouponDocument),
    ...options,
  });
};

export const RemoveGtinsDocument = `
    mutation removeGtins($productIds: [ID!]!) {
  removeGtins(productIds: $productIds)
}
    `;

export const useRemoveGtinsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveGtinsMutation,
    TError,
    RemoveGtinsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveGtinsMutation,
    TError,
    RemoveGtinsMutationVariables,
    TContext
  >({
    mutationKey: ['removeGtins'],
    mutationFn: useFetchData<RemoveGtinsMutation, RemoveGtinsMutationVariables>(
      RemoveGtinsDocument
    ),
    ...options,
  });
};

export const RemoveProductCouponsDocument = `
    mutation removeProductCoupons($saleId: String!, $productId: String!) {
  removeProductCoupons(saleId: $saleId, productId: $productId)
}
    `;

export const useRemoveProductCouponsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveProductCouponsMutation,
    TError,
    RemoveProductCouponsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveProductCouponsMutation,
    TError,
    RemoveProductCouponsMutationVariables,
    TContext
  >({
    mutationKey: ['removeProductCoupons'],
    mutationFn: useFetchData<
      RemoveProductCouponsMutation,
      RemoveProductCouponsMutationVariables
    >(RemoveProductCouponsDocument),
    ...options,
  });
};

export const RemoveProductsSizeGuideDocument = `
    mutation removeProductsSizeGuide($productIds: [ID!]!) {
  removeProductsSizeGuide(productIds: $productIds)
}
    `;

export const useRemoveProductsSizeGuideMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveProductsSizeGuideMutation,
    TError,
    RemoveProductsSizeGuideMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveProductsSizeGuideMutation,
    TError,
    RemoveProductsSizeGuideMutationVariables,
    TContext
  >({
    mutationKey: ['removeProductsSizeGuide'],
    mutationFn: useFetchData<
      RemoveProductsSizeGuideMutation,
      RemoveProductsSizeGuideMutationVariables
    >(RemoveProductsSizeGuideDocument),
    ...options,
  });
};

export const RemoveProductsDocument = `
    mutation removeProducts($productsIds: [String]!, $saleId: String!) {
  removeProducts(productsIds: $productsIds, saleId: $saleId)
}
    `;

export const useRemoveProductsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveProductsMutation,
    TError,
    RemoveProductsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveProductsMutation,
    TError,
    RemoveProductsMutationVariables,
    TContext
  >({
    mutationKey: ['removeProducts'],
    mutationFn: useFetchData<
      RemoveProductsMutation,
      RemoveProductsMutationVariables
    >(RemoveProductsDocument),
    ...options,
  });
};

export const RemoveProvisionalSaleDocument = `
    mutation removeProvisionalSale($key: String, $dataKey: String) {
  deleteDealToCalendar(key: $key, dataKey: $dataKey)
}
    `;

export const useRemoveProvisionalSaleMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveProvisionalSaleMutation,
    TError,
    RemoveProvisionalSaleMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveProvisionalSaleMutation,
    TError,
    RemoveProvisionalSaleMutationVariables,
    TContext
  >({
    mutationKey: ['removeProvisionalSale'],
    mutationFn: useFetchData<
      RemoveProvisionalSaleMutation,
      RemoveProvisionalSaleMutationVariables
    >(RemoveProvisionalSaleDocument),
    ...options,
  });
};

export const RemoveSaleDocument = `
    mutation removeSale($saleId: String!) {
  removeSale(saleId: $saleId)
}
    `;

export const useRemoveSaleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveSaleMutation,
    TError,
    RemoveSaleMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveSaleMutation,
    TError,
    RemoveSaleMutationVariables,
    TContext
  >({
    mutationKey: ['removeSale'],
    mutationFn: useFetchData<RemoveSaleMutation, RemoveSaleMutationVariables>(
      RemoveSaleDocument
    ),
    ...options,
  });
};

export const RemoveSkusDocument = `
    mutation removeSkus($productIds: [ID!]!) {
  removeSkus(productIds: $productIds)
}
    `;

export const useRemoveSkusMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveSkusMutation,
    TError,
    RemoveSkusMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveSkusMutation,
    TError,
    RemoveSkusMutationVariables,
    TContext
  >({
    mutationKey: ['removeSkus'],
    mutationFn: useFetchData<RemoveSkusMutation, RemoveSkusMutationVariables>(
      RemoveSkusDocument
    ),
    ...options,
  });
};

export const RemoveWidgetDocument = `
    mutation removeWidget($saleId: String!, $id: String!) {
  removeWidget(saleId: $saleId, id: $id)
}
    `;

export const useRemoveWidgetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveWidgetMutation,
    TError,
    RemoveWidgetMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveWidgetMutation,
    TError,
    RemoveWidgetMutationVariables,
    TContext
  >({
    mutationKey: ['removeWidget'],
    mutationFn: useFetchData<
      RemoveWidgetMutation,
      RemoveWidgetMutationVariables
    >(RemoveWidgetDocument),
    ...options,
  });
};

export const ReplaceProductsNameDocument = `
    mutation replaceProductsName($productIds: [ID!]!, $payload: [ReplacePayload!]!) {
  replaceProductsName(productIds: $productIds, payload: $payload)
}
    `;

export const useReplaceProductsNameMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ReplaceProductsNameMutation,
    TError,
    ReplaceProductsNameMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ReplaceProductsNameMutation,
    TError,
    ReplaceProductsNameMutationVariables,
    TContext
  >({
    mutationKey: ['replaceProductsName'],
    mutationFn: useFetchData<
      ReplaceProductsNameMutation,
      ReplaceProductsNameMutationVariables
    >(ReplaceProductsNameDocument),
    ...options,
  });
};

export const SalesCountDocument = `
    query SalesCount($storeId: StoreRegion!) {
  salesCount(storeId: $storeId) {
    count
  }
}
    `;

export const useSalesCountQuery = <TData = SalesCountQuery, TError = unknown>(
  variables: SalesCountQueryVariables,
  options?: Omit<
    UseQueryOptions<SalesCountQuery, TError, TData>,
    'queryKey'
  > & { queryKey?: UseQueryOptions<SalesCountQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<SalesCountQuery, TError, TData>({
    queryKey: ['SalesCount', variables],
    queryFn: useFetchData<SalesCountQuery, SalesCountQueryVariables>(
      SalesCountDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseSalesCountQuery = <
  TData = SalesCountQuery,
  TError = unknown,
>(
  variables: SalesCountQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<SalesCountQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      SalesCountQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<SalesCountQuery, TError, TData>({
    queryKey: ['SalesCountSuspense', variables],
    queryFn: useFetchData<SalesCountQuery, SalesCountQueryVariables>(
      SalesCountDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteSalesCountQuery = <
  TData = InfiniteData<SalesCountQuery>,
  TError = unknown,
>(
  variables: SalesCountQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<SalesCountQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      SalesCountQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<SalesCountQuery, SalesCountQueryVariables>(
    SalesCountDocument
  );
  return useInfiniteQuery<SalesCountQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['SalesCount.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteSalesCountQuery = <
  TData = InfiniteData<SalesCountQuery>,
  TError = unknown,
>(
  variables: SalesCountQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<SalesCountQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      SalesCountQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<SalesCountQuery, SalesCountQueryVariables>(
    SalesCountDocument
  );
  return useSuspenseInfiniteQuery<SalesCountQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['SalesCount.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const SaveImageDocument = `
    mutation saveImage($data: SaveImage!) {
  saveImage(data: $data) {
    __typename
    ... on ImageAdded {
      source_store_path_key
      image_storage_path_key
      image_updated_url
      source_url
    }
    ... on ErrorLike {
      message
    }
  }
}
    `;

export const useSaveImageMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaveImageMutation,
    TError,
    SaveImageMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SaveImageMutation,
    TError,
    SaveImageMutationVariables,
    TContext
  >({
    mutationKey: ['saveImage'],
    mutationFn: useFetchData<SaveImageMutation, SaveImageMutationVariables>(
      SaveImageDocument
    ),
    ...options,
  });
};

export const SaveImagesDocument = `
    mutation saveImages($images: [ImageInput!]!, $sessionId: ID!, $actionId: ID!, $options: SaveImagesOptions) {
  saveImages(
    images: $images
    sessionId: $sessionId
    actionId: $actionId
    options: $options
  )
}
    `;

export const useSaveImagesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaveImagesMutation,
    TError,
    SaveImagesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SaveImagesMutation,
    TError,
    SaveImagesMutationVariables,
    TContext
  >({
    mutationKey: ['saveImages'],
    mutationFn: useFetchData<SaveImagesMutation, SaveImagesMutationVariables>(
      SaveImagesDocument
    ),
    ...options,
  });
};

export const SearchBrandDocument = `
    query searchBrand($name: String!) {
  searchBrand(name: $name) {
    id
    name
    about
    createdAt
  }
}
    `;

export const useSearchBrandQuery = <TData = SearchBrandQuery, TError = unknown>(
  variables: SearchBrandQueryVariables,
  options?: Omit<
    UseQueryOptions<SearchBrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SearchBrandQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SearchBrandQuery, TError, TData>({
    queryKey: ['searchBrand', variables],
    queryFn: useFetchData<SearchBrandQuery, SearchBrandQueryVariables>(
      SearchBrandDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseSearchBrandQuery = <
  TData = SearchBrandQuery,
  TError = unknown,
>(
  variables: SearchBrandQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<SearchBrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      SearchBrandQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<SearchBrandQuery, TError, TData>({
    queryKey: ['searchBrandSuspense', variables],
    queryFn: useFetchData<SearchBrandQuery, SearchBrandQueryVariables>(
      SearchBrandDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteSearchBrandQuery = <
  TData = InfiniteData<SearchBrandQuery>,
  TError = unknown,
>(
  variables: SearchBrandQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<SearchBrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      SearchBrandQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<SearchBrandQuery, SearchBrandQueryVariables>(
    SearchBrandDocument
  );
  return useInfiniteQuery<SearchBrandQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['searchBrand.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteSearchBrandQuery = <
  TData = InfiniteData<SearchBrandQuery>,
  TError = unknown,
>(
  variables: SearchBrandQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<SearchBrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      SearchBrandQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<SearchBrandQuery, SearchBrandQueryVariables>(
    SearchBrandDocument
  );
  return useSuspenseInfiniteQuery<SearchBrandQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'searchBrand.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const SearchSalesV2Document = `
    query searchSalesV2($name: String!, $store: StoreRegion!, $limit: Int!) {
  searchSalesV2(name: $name, store: $store, limit: $limit) {
    hits {
      _score
      _source
      url
      _id
      highlight
      status
    }
    total
  }
}
    `;

export const useSearchSalesV2Query = <
  TData = SearchSalesV2Query,
  TError = unknown,
>(
  variables: SearchSalesV2QueryVariables,
  options?: Omit<
    UseQueryOptions<SearchSalesV2Query, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SearchSalesV2Query, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SearchSalesV2Query, TError, TData>({
    queryKey: ['searchSalesV2', variables],
    queryFn: useFetchData<SearchSalesV2Query, SearchSalesV2QueryVariables>(
      SearchSalesV2Document
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseSearchSalesV2Query = <
  TData = SearchSalesV2Query,
  TError = unknown,
>(
  variables: SearchSalesV2QueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<SearchSalesV2Query, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      SearchSalesV2Query,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<SearchSalesV2Query, TError, TData>({
    queryKey: ['searchSalesV2Suspense', variables],
    queryFn: useFetchData<SearchSalesV2Query, SearchSalesV2QueryVariables>(
      SearchSalesV2Document
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteSearchSalesV2Query = <
  TData = InfiniteData<SearchSalesV2Query>,
  TError = unknown,
>(
  variables: SearchSalesV2QueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<SearchSalesV2Query, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      SearchSalesV2Query,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<SearchSalesV2Query, SearchSalesV2QueryVariables>(
    SearchSalesV2Document
  );
  return useInfiniteQuery<SearchSalesV2Query, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['searchSalesV2.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteSearchSalesV2Query = <
  TData = InfiniteData<SearchSalesV2Query>,
  TError = unknown,
>(
  variables: SearchSalesV2QueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<SearchSalesV2Query, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      SearchSalesV2Query,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<SearchSalesV2Query, SearchSalesV2QueryVariables>(
    SearchSalesV2Document
  );
  return useSuspenseInfiniteQuery<SearchSalesV2Query, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'searchSalesV2.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const SendNotificationDocument = `
    mutation SendNotification($data: NotificationInput!) {
  sendNotification(data: $data)
}
    `;

export const useSendNotificationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SendNotificationMutation,
    TError,
    SendNotificationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SendNotificationMutation,
    TError,
    SendNotificationMutationVariables,
    TContext
  >({
    mutationKey: ['SendNotification'],
    mutationFn: useFetchData<
      SendNotificationMutation,
      SendNotificationMutationVariables
    >(SendNotificationDocument),
    ...options,
  });
};

export const SendOnboardingEmailDocument = `
    mutation sendOnboardingEmail($brandId: String!) {
  sendOnboardingEmail(brandId: $brandId)
}
    `;

export const useSendOnboardingEmailMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SendOnboardingEmailMutation,
    TError,
    SendOnboardingEmailMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SendOnboardingEmailMutation,
    TError,
    SendOnboardingEmailMutationVariables,
    TContext
  >({
    mutationKey: ['sendOnboardingEmail'],
    mutationFn: useFetchData<
      SendOnboardingEmailMutation,
      SendOnboardingEmailMutationVariables
    >(SendOnboardingEmailDocument),
    ...options,
  });
};

export const SetProductsAsUniqueDocument = `
    mutation setProductsAsUnique($productIds: [ID!]!) {
  setProductsAsUnique(productIds: $productIds)
}
    `;

export const useSetProductsAsUniqueMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SetProductsAsUniqueMutation,
    TError,
    SetProductsAsUniqueMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SetProductsAsUniqueMutation,
    TError,
    SetProductsAsUniqueMutationVariables,
    TContext
  >({
    mutationKey: ['setProductsAsUnique'],
    mutationFn: useFetchData<
      SetProductsAsUniqueMutation,
      SetProductsAsUniqueMutationVariables
    >(SetProductsAsUniqueDocument),
    ...options,
  });
};

export const SetProductsHideDocument = `
    mutation setProductsHide($productIds: [ID!]!, $value: Boolean!) {
  setProductsHide(productIds: $productIds, value: $value)
}
    `;

export const useSetProductsHideMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SetProductsHideMutation,
    TError,
    SetProductsHideMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SetProductsHideMutation,
    TError,
    SetProductsHideMutationVariables,
    TContext
  >({
    mutationKey: ['setProductsHide'],
    mutationFn: useFetchData<
      SetProductsHideMutation,
      SetProductsHideMutationVariables
    >(SetProductsHideDocument),
    ...options,
  });
};

export const SetShopifyStockProtectionDocument = `
    mutation setShopifyStockProtection($sellerId: ID!, $protect: Boolean!) {
  setShopifyStockProtection(sellerId: $sellerId, protect: $protect)
}
    `;

export const useSetShopifyStockProtectionMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SetShopifyStockProtectionMutation,
    TError,
    SetShopifyStockProtectionMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SetShopifyStockProtectionMutation,
    TError,
    SetShopifyStockProtectionMutationVariables,
    TContext
  >({
    mutationKey: ['setShopifyStockProtection'],
    mutationFn: useFetchData<
      SetShopifyStockProtectionMutation,
      SetShopifyStockProtectionMutationVariables
    >(SetShopifyStockProtectionDocument),
    ...options,
  });
};

export const ShowHiddenProductsAndVariantsDocument = `
    mutation showHiddenProductsAndVariants($productIds: [ID!]!) {
  showHiddenProductsAndVariants(productIds: $productIds)
}
    `;

export const useShowHiddenProductsAndVariantsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ShowHiddenProductsAndVariantsMutation,
    TError,
    ShowHiddenProductsAndVariantsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ShowHiddenProductsAndVariantsMutation,
    TError,
    ShowHiddenProductsAndVariantsMutationVariables,
    TContext
  >({
    mutationKey: ['showHiddenProductsAndVariants'],
    mutationFn: useFetchData<
      ShowHiddenProductsAndVariantsMutation,
      ShowHiddenProductsAndVariantsMutationVariables
    >(ShowHiddenProductsAndVariantsDocument),
    ...options,
  });
};

export const ShuffleProductListDocument = `
    mutation shuffleProductList($productIds: [ID!]!) {
  shuffleProductList(productIds: $productIds)
}
    `;

export const useShuffleProductListMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ShuffleProductListMutation,
    TError,
    ShuffleProductListMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ShuffleProductListMutation,
    TError,
    ShuffleProductListMutationVariables,
    TContext
  >({
    mutationKey: ['shuffleProductList'],
    mutationFn: useFetchData<
      ShuffleProductListMutation,
      ShuffleProductListMutationVariables
    >(ShuffleProductListDocument),
    ...options,
  });
};

export const UpdateAccentuateReferralDocument = `
    mutation updateAccentuateReferral($data: AccentuateReferralInput!) {
  updateAccentuateReferral(data: $data)
}
    `;

export const useUpdateAccentuateReferralMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateAccentuateReferralMutation,
    TError,
    UpdateAccentuateReferralMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateAccentuateReferralMutation,
    TError,
    UpdateAccentuateReferralMutationVariables,
    TContext
  >({
    mutationKey: ['updateAccentuateReferral'],
    mutationFn: useFetchData<
      UpdateAccentuateReferralMutation,
      UpdateAccentuateReferralMutationVariables
    >(UpdateAccentuateReferralDocument),
    ...options,
  });
};

export const UpdateBrandDocument = `
    mutation updateBrand($data: BrandUpdate) {
  updateBrand(data: $data)
}
    `;

export const useUpdateBrandMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateBrandMutation,
    TError,
    UpdateBrandMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateBrandMutation,
    TError,
    UpdateBrandMutationVariables,
    TContext
  >({
    mutationKey: ['updateBrand'],
    mutationFn: useFetchData<UpdateBrandMutation, UpdateBrandMutationVariables>(
      UpdateBrandDocument
    ),
    ...options,
  });
};

export const UpdateCouponDocument = `
    mutation updateCoupon($data: CouponUpdate!) {
  updateCoupon(data: $data)
}
    `;

export const useUpdateCouponMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateCouponMutation,
    TError,
    UpdateCouponMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateCouponMutation,
    TError,
    UpdateCouponMutationVariables,
    TContext
  >({
    mutationKey: ['updateCoupon'],
    mutationFn: useFetchData<
      UpdateCouponMutation,
      UpdateCouponMutationVariables
    >(UpdateCouponDocument),
    ...options,
  });
};

export const UpdateInvoiceDocument = `
    mutation UpdateInvoice($saleId: String!, $data: Json) {
  updateInvoice(saleId: $saleId, data: $data)
}
    `;

export const useUpdateInvoiceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateInvoiceMutation,
    TError,
    UpdateInvoiceMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateInvoiceMutation,
    TError,
    UpdateInvoiceMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateInvoice'],
    mutationFn: useFetchData<
      UpdateInvoiceMutation,
      UpdateInvoiceMutationVariables
    >(UpdateInvoiceDocument),
    ...options,
  });
};

export const UpdateManyProductOptionsDocument = `
    mutation updateManyProductOptions($saleId: String!, $data: [UpdateProductOptionManyInput!]!) {
  updateManyProductOptions(saleId: $saleId, data: $data) {
    id
    message
  }
}
    `;

export const useUpdateManyProductOptionsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateManyProductOptionsMutation,
    TError,
    UpdateManyProductOptionsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateManyProductOptionsMutation,
    TError,
    UpdateManyProductOptionsMutationVariables,
    TContext
  >({
    mutationKey: ['updateManyProductOptions'],
    mutationFn: useFetchData<
      UpdateManyProductOptionsMutation,
      UpdateManyProductOptionsMutationVariables
    >(UpdateManyProductOptionsDocument),
    ...options,
  });
};

export const UpdateManyProductDocument = `
    mutation updateManyProduct($data: [UpdateProductManyInput!]!) {
  updateManyProduct(data: $data)
}
    `;

export const useUpdateManyProductMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateManyProductMutation,
    TError,
    UpdateManyProductMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateManyProductMutation,
    TError,
    UpdateManyProductMutationVariables,
    TContext
  >({
    mutationKey: ['updateManyProduct'],
    mutationFn: useFetchData<
      UpdateManyProductMutation,
      UpdateManyProductMutationVariables
    >(UpdateManyProductDocument),
    ...options,
  });
};

export const UpdateProductDocument = `
    mutation updateProduct($data: ProductUpdateInputV3!) {
  updateProductV3(data: $data) {
    id
    name
    sourceUrl
    description
    gender
    badges {
      isSpecial
      position
      value
    }
    isDraft
    type
    images {
      id
      baseKey
      image
    }
    category {
      name
      vat
    }
    createdAt
    updatedAt
    hide
    brand {
      id
      about
      name
    }
    currency
    variants {
      id
      images
      stockCount
      sku
      gtin
      position
      buyingPrice
      retailPrice
      discountPercentage
      chooseFees
      hide
      absorbedShipping
      shippingFees
      deliveryDelays
      originCountry
      options {
        productOptionId
        valueId
      }
    }
    position
    faq
    faqPreOrder
    faqPostOrder
    couponExpirationDate
    couponDetail
    cgu
    isAlcohol
    customFields {
      key
      position
      type
      value
    }
    options {
      id
      type
      name
      position
      values {
        id
        position
        value
      }
      images {
        id
        baseKey
        image
      }
    }
    isDeliveredByChoose
    isReturnable
  }
}
    `;

export const useUpdateProductMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateProductMutation,
    TError,
    UpdateProductMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductMutation,
    TError,
    UpdateProductMutationVariables,
    TContext
  >({
    mutationKey: ['updateProduct'],
    mutationFn: useFetchData<
      UpdateProductMutation,
      UpdateProductMutationVariables
    >(UpdateProductDocument),
    ...options,
  });
};

export const UpdateProductsBuyingPriceDocument = `
    mutation updateProductsBuyingPrice($productIds: [ID!]!, $value: Float!) {
  updateProductsBuyingPrice(productIds: $productIds, value: $value)
}
    `;

export const useUpdateProductsBuyingPriceMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProductsBuyingPriceMutation,
    TError,
    UpdateProductsBuyingPriceMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductsBuyingPriceMutation,
    TError,
    UpdateProductsBuyingPriceMutationVariables,
    TContext
  >({
    mutationKey: ['updateProductsBuyingPrice'],
    mutationFn: useFetchData<
      UpdateProductsBuyingPriceMutation,
      UpdateProductsBuyingPriceMutationVariables
    >(UpdateProductsBuyingPriceDocument),
    ...options,
  });
};

export const UpdateProductsCategoryDocument = `
    mutation updateProductsCategory($productIds: [ID!]!, $category: String!) {
  updateProductsCategory(productIds: $productIds, category: $category)
}
    `;

export const useUpdateProductsCategoryMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProductsCategoryMutation,
    TError,
    UpdateProductsCategoryMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductsCategoryMutation,
    TError,
    UpdateProductsCategoryMutationVariables,
    TContext
  >({
    mutationKey: ['updateProductsCategory'],
    mutationFn: useFetchData<
      UpdateProductsCategoryMutation,
      UpdateProductsCategoryMutationVariables
    >(UpdateProductsCategoryDocument),
    ...options,
  });
};

export const UpdateProductsCouponExpiresAtDocument = `
    mutation updateProductsCouponExpiresAt($productIds: [ID!]!, $value: LocalDate!) {
  updateProductsCouponExpiresAt(productIds: $productIds, value: $value)
}
    `;

export const useUpdateProductsCouponExpiresAtMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProductsCouponExpiresAtMutation,
    TError,
    UpdateProductsCouponExpiresAtMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductsCouponExpiresAtMutation,
    TError,
    UpdateProductsCouponExpiresAtMutationVariables,
    TContext
  >({
    mutationKey: ['updateProductsCouponExpiresAt'],
    mutationFn: useFetchData<
      UpdateProductsCouponExpiresAtMutation,
      UpdateProductsCouponExpiresAtMutationVariables
    >(UpdateProductsCouponExpiresAtDocument),
    ...options,
  });
};

export const UpdateProductsDeliveryDocument = `
    mutation updateProductsDelivery($productIds: [ID!]!, $value: Boolean!) {
  updateProductsDelivery(productIds: $productIds, value: $value)
}
    `;

export const useUpdateProductsDeliveryMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProductsDeliveryMutation,
    TError,
    UpdateProductsDeliveryMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductsDeliveryMutation,
    TError,
    UpdateProductsDeliveryMutationVariables,
    TContext
  >({
    mutationKey: ['updateProductsDelivery'],
    mutationFn: useFetchData<
      UpdateProductsDeliveryMutation,
      UpdateProductsDeliveryMutationVariables
    >(UpdateProductsDeliveryDocument),
    ...options,
  });
};

export const UpdateProductsGenderDocument = `
    mutation updateProductsGender($productIds: [ID!]!, $value: Int) {
  updateProductsGender(productIds: $productIds, value: $value)
}
    `;

export const useUpdateProductsGenderMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProductsGenderMutation,
    TError,
    UpdateProductsGenderMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductsGenderMutation,
    TError,
    UpdateProductsGenderMutationVariables,
    TContext
  >({
    mutationKey: ['updateProductsGender'],
    mutationFn: useFetchData<
      UpdateProductsGenderMutation,
      UpdateProductsGenderMutationVariables
    >(UpdateProductsGenderDocument),
    ...options,
  });
};

export const UpdateProductsOriginCountryDocument = `
    mutation updateProductsOriginCountry($productIds: [ID!]!, $value: String!) {
  updateProductsOriginCountry(productIds: $productIds, value: $value)
}
    `;

export const useUpdateProductsOriginCountryMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProductsOriginCountryMutation,
    TError,
    UpdateProductsOriginCountryMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductsOriginCountryMutation,
    TError,
    UpdateProductsOriginCountryMutationVariables,
    TContext
  >({
    mutationKey: ['updateProductsOriginCountry'],
    mutationFn: useFetchData<
      UpdateProductsOriginCountryMutation,
      UpdateProductsOriginCountryMutationVariables
    >(UpdateProductsOriginCountryDocument),
    ...options,
  });
};

export const UpdateProductsRetailPriceDocument = `
    mutation updateProductsRetailPrice($productIds: [ID!]!, $value: Int!) {
  updateProductsRetailPrice(productIds: $productIds, value: $value)
}
    `;

export const useUpdateProductsRetailPriceMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProductsRetailPriceMutation,
    TError,
    UpdateProductsRetailPriceMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductsRetailPriceMutation,
    TError,
    UpdateProductsRetailPriceMutationVariables,
    TContext
  >({
    mutationKey: ['updateProductsRetailPrice'],
    mutationFn: useFetchData<
      UpdateProductsRetailPriceMutation,
      UpdateProductsRetailPriceMutationVariables
    >(UpdateProductsRetailPriceDocument),
    ...options,
  });
};

export const UpdateProductsReturnEligibilityDocument = `
    mutation updateProductsReturnEligibility($productIds: [ID!]!, $isReturnable: Boolean!) {
  updateProductsReturnEligibility(
    productIds: $productIds
    isReturnable: $isReturnable
  )
}
    `;

export const useUpdateProductsReturnEligibilityMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProductsReturnEligibilityMutation,
    TError,
    UpdateProductsReturnEligibilityMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductsReturnEligibilityMutation,
    TError,
    UpdateProductsReturnEligibilityMutationVariables,
    TContext
  >({
    mutationKey: ['updateProductsReturnEligibility'],
    mutationFn: useFetchData<
      UpdateProductsReturnEligibilityMutation,
      UpdateProductsReturnEligibilityMutationVariables
    >(UpdateProductsReturnEligibilityDocument),
    ...options,
  });
};

export const UpdateProductsChooseFeesDocument = `
    mutation updateProductsChooseFees($productIds: [ID!]!, $fees: Float!) {
  updateProductsChooseFees(productIds: $productIds, fees: $fees)
}
    `;

export const useUpdateProductsChooseFeesMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProductsChooseFeesMutation,
    TError,
    UpdateProductsChooseFeesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductsChooseFeesMutation,
    TError,
    UpdateProductsChooseFeesMutationVariables,
    TContext
  >({
    mutationKey: ['updateProductsChooseFees'],
    mutationFn: useFetchData<
      UpdateProductsChooseFeesMutation,
      UpdateProductsChooseFeesMutationVariables
    >(UpdateProductsChooseFeesDocument),
    ...options,
  });
};

export const UpdateProductsShippingCostDocument = `
    mutation updateProductsShippingCost($productIds: [ID!]!, $value: Float!) {
  updateProductsShippingCost(productIds: $productIds, value: $value)
}
    `;

export const useUpdateProductsShippingCostMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProductsShippingCostMutation,
    TError,
    UpdateProductsShippingCostMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductsShippingCostMutation,
    TError,
    UpdateProductsShippingCostMutationVariables,
    TContext
  >({
    mutationKey: ['updateProductsShippingCost'],
    mutationFn: useFetchData<
      UpdateProductsShippingCostMutation,
      UpdateProductsShippingCostMutationVariables
    >(UpdateProductsShippingCostDocument),
    ...options,
  });
};

export const UpdateProductsDocument = `
    mutation updateProducts($data: ProductsUpdate) {
  updateProducts(data: $data)
}
    `;

export const useUpdateProductsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateProductsMutation,
    TError,
    UpdateProductsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProductsMutation,
    TError,
    UpdateProductsMutationVariables,
    TContext
  >({
    mutationKey: ['updateProducts'],
    mutationFn: useFetchData<
      UpdateProductsMutation,
      UpdateProductsMutationVariables
    >(UpdateProductsDocument),
    ...options,
  });
};

export const UpdateProvisionalSalePartialDocument = `
    mutation updateProvisionalSalePartial($key: String, $data: Json) {
  updateDealKey(key: $key, data: $data)
}
    `;

export const useUpdateProvisionalSalePartialMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProvisionalSalePartialMutation,
    TError,
    UpdateProvisionalSalePartialMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProvisionalSalePartialMutation,
    TError,
    UpdateProvisionalSalePartialMutationVariables,
    TContext
  >({
    mutationKey: ['updateProvisionalSalePartial'],
    mutationFn: useFetchData<
      UpdateProvisionalSalePartialMutation,
      UpdateProvisionalSalePartialMutationVariables
    >(UpdateProvisionalSalePartialDocument),
    ...options,
  });
};

export const UpdateSaleDocument = `
    mutation updateSale($sale: SaleInput!, $brandNameChanged: Boolean) {
  updateSale(sale: $sale, brandNameChanged: $brandNameChanged)
}
    `;

export const useUpdateSaleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateSaleMutation,
    TError,
    UpdateSaleMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateSaleMutation,
    TError,
    UpdateSaleMutationVariables,
    TContext
  >({
    mutationKey: ['updateSale'],
    mutationFn: useFetchData<UpdateSaleMutation, UpdateSaleMutationVariables>(
      UpdateSaleDocument
    ),
    ...options,
  });
};
