import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import * as Sentry from '@sentry/react';
import { initializeApp } from 'firebase/app';
import type { IdTokenResult, ParsedToken, User } from 'firebase/auth';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import {
  getDatabase,
  onDisconnect,
  onValue,
  ref,
  remove,
  set,
} from 'firebase/database';

import { connectedUsersState } from '../atoms/connectedUsersState';
import type { Dictionary } from '../types/dictionary';
import type { UserRole } from '../utils/role';
import { checkRoles } from '../utils/role';
import { setUserId } from './analytics';

export const firebaseApp = initializeApp(
  {
    apiKey: import.meta.env.REACT_APP_FIREBASE_ADMIN_KEY as string,
    authDomain: import.meta.env.REACT_APP_FIREBASE_ADMIN_DOMAIN as string,
    databaseURL: import.meta.env.REACT_APP_FIREBASE_ADMIN_URL as string,
    projectId: import.meta.env.REACT_APP_FIREBASE_ADMIN_ID as string,
    storageBucket: import.meta.env.REACT_APP_FIREBASE_ADMIN_STOCKAGE as string,
    messagingSenderId: import.meta.env
      .REACT_APP_FIREBASE_ADMIN_SENDER as string,
  },
  'admin'
);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseDatabase = getDatabase(firebaseApp);

export const isGodUser = (user: User | null) => {
  return (
    user?.uid === 'f5qXbj8bW4SYzCZmoXrMidtwLyT2' || // vincent@appchoose.io - prod
    user?.uid === 'qkADoVZ6PseS0zV5tW5fIvIFJad2' || // timothee@appchoose.io - prod
    user?.uid === '65Cg4DfW6IfUI0dR50XjKrCxLXB2' || // thibaut@appchoose.io - prod
    user?.uid === '5JeLj4B4CoWidXtpZwojvlm9sgC3' || // thomas.m@appchoose.io - prod
    user?.uid === 'b25SFs60wzOYeLpVGPNmAG0N70c2' || // pia@appchoose.io - prod
    user?.uid === 'GpVRKveaZHTihbSytx80eMaKpdH3' // maeva@appchoose.io - prod
  );
};

export type ConnectedUserInfos = {
  user: {
    displayName: string;
    email: string;
  };
  page: string;
  key: string | null;
};
export type ConnectedUsers = Dictionary<ConnectedUserInfos>;

const setPresence = (user: User) => {
  const urls = window.location.pathname.split('/');
  const presenceRef = ref(firebaseDatabase, `presence2/${user.uid}`);
  set(presenceRef, {
    user: {
      displayName: user.displayName,
      email: user.email,
    },
    page: urls[1],
    key: urls[2] ?? null,
  });
  onDisconnect(presenceRef).remove();
};

const unsetPresence = (user: User) => {
  const presenceRef = ref(firebaseDatabase, `presence2/${user.uid}`);
  remove(presenceRef);
};

const listenPresence = (updateCallback: (data: ConnectedUsers) => void) => {
  const presenceRef = ref(firebaseDatabase, 'presence2');
  onValue(presenceRef, (snapshot) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const data = snapshot.val();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    updateCallback(data);
  });
};

const authProvider = {
  isAuthenticated: false,
  signin: async (callback: VoidFunction) => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/plus.login');
    provider.addScope('profile');
    provider.addScope('email');

    await signInWithPopup(getAuth(firebaseApp), provider);
    authProvider.isAuthenticated = true;
    callback();
  },
  signout: async (callback: VoidFunction) => {
    authProvider.isAuthenticated = false;
    await getAuth(firebaseApp).signOut();
    callback();
  },
};

interface AuthContextType {
  user: User | null;
  signin: (callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
  getIdToken: () => Promise<string>;
  getTokenResult: (callback: (isTokenResult: IdTokenResult) => void) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = React.createContext<AuthContextType>(null!);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  const location = useLocation();

  const setConnectedUsers = useSetRecoilState(connectedUsersState);

  useEffect(() => {
    listenPresence((data) => setConnectedUsers(data));

    const onPageFocused = () => {
      // Add a small delay to avoid a race condition with the blur event
      setTimeout(() => {
        if (auth.user) setPresence(auth.user);
      }, 1000);
    };
    const onPageUnfocused = () => {
      if (auth.user) unsetPresence(auth.user);
    };
    window.addEventListener('focus', onPageFocused, false);
    window.addEventListener('blur', onPageUnfocused, false);

    return () => {
      window.removeEventListener('focus', onPageFocused);
      window.removeEventListener('blur', onPageUnfocused);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.user) setPresence(auth.user);
  }, [auth.user, location]);

  useEffect(() => {
    if (auth.user) setUserId(auth.user.email ?? '');
  }, [auth.user]);

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export type RequireAuthorizationProps = {
  onlyAdmin: boolean;
  requireRole?: UserRole[];
  children: React.ReactNode;
};

export type ChooseClaims = ParsedToken & {
  admin: boolean;
  roles: UserRole[] | UserRole;
};

export const RequireAuthorization: React.FC<RequireAuthorizationProps> = ({
  onlyAdmin,
  requireRole,
  children,
}) => {
  const { getTokenResult } = useAuth();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [claims, setClaims] = useState<ChooseClaims | null>(null);

  useEffect(() => {
    getTokenResult((tokenResult) => {
      setClaims(tokenResult.claims as ChooseClaims);
      setIsLoading(false);
    });
  }, [getTokenResult]);

  if (isLoading) return null;

  if (!checkRoles({ onlyAdmin, requireRole }, claims)) {
    return <Navigate to="/unauthorized" state={{ from: location }} />;
  }

  return children;
};

export type AuthProviderProps = { children: React.ReactNode };

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
}: AuthProviderProps) => {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    return firebaseAuth.onAuthStateChanged(onAuthStateChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAuthStateChanged = (userAuth: User | null) => {
    setUser(userAuth);
    Sentry.setUser(
      userAuth ? { id: userAuth.uid, email: userAuth.email ?? '' } : null
    );
    if (initializing) setInitializing(false);
  };

  const signin = useCallback((callback: VoidFunction) => {
    return authProvider.signin(() => {
      setUser(firebaseAuth.currentUser);
      callback();
    });
  }, []);

  const signout = useCallback((callback: VoidFunction) => {
    return authProvider.signout(() => {
      setUser(null);
      callback();
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const getIdToken = useCallback(() => user!.getIdToken(), [user]);

  const getTokenResult = useCallback(
    (callback: (idTokenResult: IdTokenResult) => void) => {
      return user?.getIdTokenResult().then((token) => {
        callback(token);
      });
    },
    [user]
  );

  const value = useMemo(
    () => ({ user, signin, signout, getIdToken, getTokenResult }),
    [user, signin, signout, getIdToken, getTokenResult]
  );

  if (initializing) return null;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
