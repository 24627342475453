import { atom, selector } from 'recoil';

import { CrawlingData, CrawlingProductStatus } from '../types';

export const crawlingProductsState = atom<CrawlingData | undefined>({
  key: 'crawlingProductsState',
  default: undefined,
});

export const productsProcessedCountState = selector({
  key: 'productsProcessedCountState',
  get: ({ get }) => {
    const crawlingProducts = get(crawlingProductsState);

    return (
      crawlingProducts?.products.filter(
        (entry) =>
          entry._status === CrawlingProductStatus.SUCCESS ||
          entry._status === CrawlingProductStatus.ERROR
      ) ?? []
    ).length;
  },
});

export const productsProcessedErrorCountState = selector({
  key: 'productsProcessedErrorCountState',
  get: ({ get }) => {
    const crawlingProducts = get(crawlingProductsState);

    return (
      crawlingProducts?.products.filter(
        (entry) => entry._status === CrawlingProductStatus.ERROR
      ) ?? []
    ).length;
  },
});

export const productsProcessingState = selector({
  key: 'productsProcessingState',
  get: ({ get }) => {
    const crawlingProducts = get(crawlingProductsState);

    return (
      (
        crawlingProducts?.products.filter(
          (entry) => entry._status === CrawlingProductStatus.IN_PROGRESS
        ) ?? []
      ).length !== 0
    );
  },
});

export const productsProcessableState = selector({
  key: 'productsProcessableState',
  get: ({ get }) => {
    const crawlingProducts = get(crawlingProductsState);

    return (
      crawlingProducts?.products.filter(
        (entry) => entry._status !== CrawlingProductStatus.NOT_STARTED
      ) ?? []
    ).length;
  },
});
