import type { TFunction } from 'i18next';

export const formatPercentage = (value: number, locale: string) => {
  return Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(value);
};

export const formatPercentageRange = (
  percentageRange: { min: number; max: number } | null | undefined,
  locale: string,
  t: TFunction<'translation', undefined>
) => {
  if (!percentageRange) {
    return formatPercentage(0, locale);
  }

  if (percentageRange.max === percentageRange.min) {
    return formatPercentage(-percentageRange.min / 100, locale);
  }

  return t('sale_page.products.product_price_range', {
    min: formatPercentage(-percentageRange.min / 100, locale),
    max: formatPercentage(-percentageRange.max / 100, locale),
  });
};

export const formatInteger = (value: number, locale: string) => {
  return Intl.NumberFormat(locale, {
    maximumFractionDigits: 0,
  }).format(value);
};

export const formatIntegerRange = (
  integerRange: { min: number; max: number } | null | undefined,
  locale: string,
  t: TFunction<'translation', undefined>
) => {
  if (!integerRange) {
    return formatInteger(0, locale);
  }

  if (integerRange.max === integerRange.min) {
    return formatInteger(integerRange.min, locale);
  }

  return t('sale_page.products.product_price_range', {
    min: formatInteger(integerRange.min, locale),
    max: formatInteger(integerRange.max, locale),
  });
};

export function isInteger(value: string) {
  return /^-?\d+$/.test(value);
}

export function formatWeight(value: number, locale: string): string {
  return Intl.NumberFormat(locale, {
    style: 'unit',
    unit: 'kilogram',
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format(value);
}

export function formatWeightRange(
  weightRange: { min: number; max: number },
  locale: string,
  t: TFunction<'translation', undefined>
) {
  if (weightRange.min === weightRange.max) {
    return formatWeight(weightRange.min, locale);
  }

  return t('setup_page.columns.product_weight_range', {
    min: formatWeight(weightRange.min, locale),
    max: formatWeight(weightRange.max, locale),
  });
}

export function formatDimension(value: number, locale: string): string {
  return Intl.NumberFormat(locale, {
    style: 'unit',
    unit: 'centimeter',
  }).format(value);
}

export function formatDimensionRange(
  { width, height, length }: { width: number; height: number; length: number },
  locale: string
) {
  return `${formatDimension(width, locale)} x ${formatDimension(height, locale)} x ${formatDimension(length, locale)}`;
}

export function formatVolume(value: number, locale: string): string {
  return (
    Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    }).format(value) + ' m³'
  );
}
