import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AvatarGroup from '@appchoose/avatar-group';
import Tooltip from '@appchoose/tooltip';

import { findContactOrEmpty } from '../../types/choose-contact';

type SaleAvatarGroupProps = {
  saleName: string;
  setupName: string;
  logisticsName: string;
  className?: string;
  showDetails?: boolean;
  size?: 'big' | 'normal';
};

export const SaleAvatarGroup: React.FC<SaleAvatarGroupProps> = ({
  saleName,
  setupName,
  logisticsName,
  className,
  showDetails,
  size,
}) => {
  const { t } = useTranslation();

  const saleContact = useMemo(() => findContactOrEmpty(saleName), [saleName]);
  const setupContact = useMemo(
    () => findContactOrEmpty(setupName),
    [setupName]
  );
  const logisticsContact = useMemo(
    () => findContactOrEmpty(logisticsName),
    [logisticsName]
  );

  const avatars = [];
  if (saleContact) {
    avatars.push({
      src: `https://public.choose.app/avatars/80x80/${
        saleContact.avatar || ''
      }-80x80.png`,
      className: size === 'big' ? '!w-14 !h-14' : '',
    });
  }
  if (setupContact) {
    avatars.push({
      src: `https://public.choose.app/avatars/80x80/${
        setupContact.avatar || ''
      }-80x80.png`,
      className: size === 'big' ? '!w-14 !h-14' : '',
    });
  }
  if (logisticsContact) {
    avatars.push({
      src: `https://public.choose.app/avatars/80x80/${
        logisticsContact.avatar || ''
      }-80x80.png`,
      className: size === 'big' ? '!w-14 !h-14' : '',
    });
  }

  return showDetails ? (
    <Tooltip
      content={
        <div className="flex space-x-4 px-1.5 py-3 text-xs">
          <div className="space-y-1">
            <div className="font-semibold uppercase">
              {t('sales_page.sale')}
            </div>
            <div>{saleContact ? `${saleContact.firstName}` : '-'}</div>
          </div>
          <div className="space-y-1">
            <div className="font-semibold uppercase">
              {t('sales_page.setup')}
            </div>
            <div>{setupContact ? `${setupContact.firstName}` : '-'}</div>
          </div>
          <div className="space-y-1">
            <div className="font-semibold uppercase">{t('sales_page.am')}</div>
            <div>
              {logisticsContact ? `${logisticsContact.firstName}` : '-'}
            </div>
          </div>
        </div>
      }
    >
      <AvatarGroup avatars={avatars} className={className} />
    </Tooltip>
  ) : (
    <AvatarGroup avatars={avatars} className={className} />
  );
};
