import { useAuth } from '../api/auth';

export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  const { getIdToken } = useAuth();

  return async (variables?: TVariables) => {
    const res = await fetch(import.meta.env.REACT_APP_API_URL as string, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Apollographql-Client-Name': 'meusnidus-v2',
        Authorization: await getIdToken(),
        ...options,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = (await res.json()) as {
      data: TData;
      errors: Error[] | undefined;
    };

    if (json.errors?.[0]) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  };
};
