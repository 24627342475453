export const MEUSNIDUS_BASE_URL_FROM_HOST =
  window.location.host === 'meusnidus-v2.appchoose.io'
    ? 'https://meusnidus.appchoose.io'
    : 'https://meusnidus.appchoose.co';

export const BRAND_BASE_URL_FROM_HOST =
  window.location.host === 'meusnidus-v2.appchoose.io'
    ? 'https://mychoose.appchoose.io'
    : 'https://mychoose.appchoose.co';

export const BASE_URL_FROM_HOST =
  window.location.host === 'meusnidus-v2.appchoose.io'
    ? 'https://appchoose.io'
    : 'https://appchoose.co';
