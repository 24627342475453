/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import cn from '@appchoose/cn';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@appchoose/command';
import { Dialog, DialogOverlay, DialogPortal } from '@appchoose/dialog';
import Icon from '@appchoose/icon';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { CommandLoading } from 'cmdk';
import { matchSorter } from 'match-sorter';

import { track } from '../../api/analytics';
import { storeRegionState } from '../../atoms/storeRegionState';
import { useCategories } from '../../pages/sale-page/use-categories';
import { useDissociateImages } from '../../pages/setup-page/hooks';
import useRenameColumn from '../../pages/setup-page/setup-table-components/column-rename-utils';
import { crawlingProductsState } from '../../pages/setup-page/states/crawlingProductState';
import {
  contextRowSelectionState,
  rowSelectionState,
  useResetContextSelection,
} from '../../pages/setup-page/states/selectedRowsState';
import type { SalesElasticSearchHit } from '../../types/generated';
import {
  StoreRegion,
  useSearchBrandQuery,
  useSearchSalesV2Query,
} from '../../types/generated';
import {
  convertUtcToLocaleDate,
  formatDate,
  truncateTime,
} from '../../utils/date';
import { formatPercentage } from '../../utils/number';
import { getSaleStatusForSale } from '../../utils/sale';
import {
  getSaleStatusColor,
  getSaleStatusLabel,
} from '../sales-search-view/sales-search-view-item';
import { CommandBadge, CommandBadges } from './command';
import type { Page } from './command-menu-state';
import {
  Pages,
  isOpenCommandMenuState,
  isSetupProductsActiveCommandMenuState,
  pagesCommandMenuState,
  selectedCustomFieldNameState,
} from './command-menu-state';

const CommandDialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        'bg-background fixed inset-0 z-50 flex w-full items-start justify-center gap-4 border p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 sm:rounded-lg',
        className
      )}
      style={{
        padding: 'calc(-0.23px + 13vh) 16px 16px',
      }}
      {...props}
    >
      <div className="flex min-w-min max-w-screen-sm flex-1">{children}</div>
    </DialogPrimitive.Content>
  </DialogPortal>
));
CommandDialogContent.displayName = DialogPrimitive.Content.displayName;

export const CommandMenu = () => {
  const commandRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const scrollId = useRef<number>();
  const { resetContextSelection } = useResetContextSelection();

  const { t } = useTranslation();

  const [open, setOpen] = useRecoilState(isOpenCommandMenuState);
  const [inputValue, setInputValue] = useState('');
  const selectedFieldName = useRecoilValue(selectedCustomFieldNameState);
  const pageStructure: Page[] = [
    {
      page: Pages.Home,
      placeholder: t('command.what_do_you_need'),
    },
    {
      page: Pages.SearchBrand,
      placeholder: t('command.search_brand_placeholder'),
      badge: t('command.search_brand'),
    },
    {
      page: Pages.SearchSale,
      placeholder: t('command.search_sale_placeholder'),
      badge: t('command.search_sale'),
    },
    {
      page: Pages.SetupProducts,
      placeholder: t('command.select_an_action'),
      badge: t('command.setup'),
    },
    {
      page: Pages.SetProductCategory,
      placeholder: t('command.search_category_placeholder'),
      badge: t('command.edit_category'),
    },
    {
      page: Pages.RenameCustomFieldColumn,
      placeholder: t('command.select_custom_field'),
      badge: t('command.rename_custom_field'),
    },
    {
      page: Pages.RenameCustomFieldInput,
      placeholder: t('command.rename_custom_field', {
        fieldName: selectedFieldName,
      }),
      badge: selectedFieldName ?? '',
    },
  ];
  const [pages, setPages] = useRecoilState(pagesCommandMenuState);
  const activePage = pages[pages.length - 1];
  const isHome = activePage === Pages.Home;
  const badges = pages.toSpliced(0, 1);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
        track('KeyboardShortcut', {
          label: 'Mod-k',
        });
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, [setOpen]);

  const popPage = useCallback(() => {
    setPages((pages) => {
      const x = [...pages];
      x.splice(-1, 1);
      return x;
    });
  }, []);

  const bounce = () => {
    if (commandRef.current) {
      commandRef.current.style.transform = 'scale(0.96)';
      setTimeout(() => {
        if (commandRef.current) {
          commandRef.current.style.transform = '';
        }
      }, 100);

      setInputValue('');
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);

        if (!open) {
          // Reset context selection if exists on close
          resetContextSelection();
        }
      }}
    >
      <CommandDialogContent className="overflow-hidden border-none p-0 shadow-lg">
        <Command
          ref={commandRef}
          shouldFilter={activePage === Pages.Home}
          className="[&_[cmdk-group-heading]]:text-muted-foreground transition-transform [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:size-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:size-5"
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              bounce();
            }

            if (isHome || inputValue.length) {
              return;
            }

            if (e.key === 'Backspace') {
              e.preventDefault();
              popPage();
              bounce();
            }
          }}
        >
          {badges.length > 0 ? (
            <CommandBadges>
              {badges.map((badge) => (
                <CommandBadge key={badge}>
                  {pageStructure.find((p) => p.page === badge)?.badge}
                </CommandBadge>
              ))}
            </CommandBadges>
          ) : null}
          <CommandInput
            ref={inputRef}
            autoFocus
            value={inputValue}
            onValueChange={(value) => {
              setInputValue(value);

              //#region scroll list to top when typing
              // https://github.com/pacocoursey/cmdk/issues/234
              // https://github.com/pacocoursey/cmdk/issues/233

              // clear pending scroll
              if (scrollId.current) cancelAnimationFrame(scrollId.current);

              // the setTimeout is used to create a new task
              // this is to make sure that we don't scroll until the user is done typing
              // you can tweak the timeout duration ofc
              scrollId.current = requestAnimationFrame(() => {
                // inside your list select the first group and scroll to the top
                listRef.current?.scrollTo({ top: 0 });
              });

              //#endregion
            }}
            placeholder={
              pageStructure.find((p) => p.page === activePage)?.placeholder
            }
          />

          <CommandList ref={listRef}>
            {activePage === Pages.Home ? (
              <Home
                focusOnInput={() => {
                  inputRef.current?.focus();
                }}
                setOpen={setOpen}
              />
            ) : null}
            {activePage === Pages.SearchBrand ? (
              <Brands inputValue={inputValue} setOpen={setOpen} />
            ) : null}
            {activePage === Pages.SearchSale ? (
              <Sales inputValue={inputValue} setOpen={setOpen} />
            ) : null}
            {activePage === Pages.SetupProducts ? (
              <SetupProducts
                onPageChange={() => {
                  inputRef.current?.focus();
                }}
                setOpen={setOpen}
              />
            ) : null}
            {activePage === Pages.SetProductCategory ? (
              <SetProductCategory inputValue={inputValue} setOpen={setOpen} />
            ) : null}
            {activePage === Pages.RenameCustomFieldColumn && (
              <RenameCustomFieldColumn inputValue={inputValue} />
            )}
            {activePage === Pages.RenameCustomFieldInput && (
              <RenameCustomFieldInput
                inputValue={inputValue}
                setOpen={setOpen}
              />
            )}
          </CommandList>
        </Command>
      </CommandDialogContent>
    </Dialog>
  );
};

const Home = ({
  focusOnInput,
  setOpen,
}: {
  focusOnInput: () => void;
  setOpen: (open: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [pages, setPages] = useRecoilState(pagesCommandMenuState);
  const isSetupProductsActiveCommandMenu = useRecoilValue(
    isSetupProductsActiveCommandMenuState
  );

  return (
    <>
      <CommandEmpty>{t('command.no_result')}</CommandEmpty>
      <CommandGroup heading={t('command.sales')}>
        <CommandItem
          onSelect={() => {
            navigate('/calendar');
            setOpen(false);
          }}
        >
          <Icon icon="calendar" className="mr-2" />
          {t('command.calendar')}
        </CommandItem>
        <CommandItem
          onSelect={() => {
            setPages([...pages, Pages.SearchSale]);
            focusOnInput();
          }}
        >
          <Icon icon="cart" className="mr-2" />
          {t('command.search_sale')}
        </CommandItem>
      </CommandGroup>
      <CommandGroup heading={t('command.brands')}>
        <CommandItem
          onSelect={() => {
            setPages([...pages, Pages.SearchBrand]);
            focusOnInput();
          }}
        >
          <Icon icon="suitCase" className="mr-2" />
          {t('command.search_brand')}
        </CommandItem>
      </CommandGroup>
      {isSetupProductsActiveCommandMenu ? (
        <CommandGroup heading={t('command.setup')}>
          <CommandItem
            onSelect={() => {
              setPages([...pages, Pages.SetupProducts]);
              focusOnInput();
            }}
          >
            <Icon icon="settings" className="mr-2" />
            {t('command.configure_products')}
          </CommandItem>
        </CommandGroup>
      ) : null}
    </>
  );
};

const SaleCommandItem = ({
  hit,
  storeRegion,
  onClose,
}: {
  hit: SalesElasticSearchHit;
  storeRegion: StoreRegion;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const status = getSaleStatusForSale(
    truncateTime(new Date(hit._source.start_at)).toString(),
    hit._source.end_at,
    hit._source.store_id,
    hit._source.hide,
    hit._source.is_cancelled
  );

  return (
    <CommandItem
      key={hit?._id}
      onSelect={() => {
        navigate(`/vente/${hit?._id}`);
        onClose();
      }}
      className="flex justify-between"
    >
      <div className="flex items-center space-x-2">
        <span>{hit?._source.brand_name}</span>
        <div className="size-1 rounded-full bg-gray-300"></div>
        <span className={cn('text-xs', getSaleStatusColor(status))}>
          {getSaleStatusLabel(status, t)}
        </span>

        <span className="sr-only">{storeRegion}</span>
      </div>

      {hit?._source?.start_at && hit?._source?.end_at ? (
        <div className="text-xs text-gray-500">
          {formatDate(
            new Date(hit._source.start_at ?? ''),
            'PP',
            i18n.language
          )}{' '}
          -{' '}
          {formatDate(
            convertUtcToLocaleDate(
              hit._source.end_at ?? '',
              hit._source.store_id
            ),
            'PP',
            i18n.language
          )}
        </div>
      ) : null}
    </CommandItem>
  );
};

const Sales: React.FC<{
  inputValue: string;
  setOpen: (open: boolean) => void;
}> = ({ inputValue, setOpen }) => {
  const { t } = useTranslation();
  const storeRegion = useRecoilValue(storeRegionState);

  const { data: salesMain, isLoading: isLoadingMain } = useSearchSalesV2Query(
    { name: inputValue, store: storeRegion, limit: 25 },
    {
      select: (data) => data.searchSalesV2,
    }
  );
  const { data: salesSecondary, isLoading: isLoadingSecondary } =
    useSearchSalesV2Query(
      {
        name: inputValue,
        store: storeRegion === StoreRegion.Fr ? StoreRegion.Us : StoreRegion.Fr,
        limit: 25,
      },
      {
        select: (data) => data.searchSalesV2,
      }
    );

  return (
    <>
      {inputValue && !isLoadingMain && !isLoadingSecondary ? (
        <CommandEmpty>{t('command.no_result')}</CommandEmpty>
      ) : null}
      {(salesMain?.total ?? 0) > 0 ? (
        <CommandGroup
          heading={
            storeRegion === StoreRegion.Fr
              ? t('command.sales_eu')
              : t('command.sales_us')
          }
        >
          {salesMain?.hits
            ?.filter((hit) => !!hit)
            .map((hit) => (
              <SaleCommandItem
                key={hit?._id}
                hit={hit}
                storeRegion={storeRegion}
                onClose={() => setOpen(false)}
              />
            ))}
        </CommandGroup>
      ) : null}
      {(salesSecondary?.total ?? 0) > 0 ? (
        <CommandGroup
          heading={
            storeRegion === StoreRegion.Fr
              ? t('command.sales_us')
              : t('command.sales_eu')
          }
        >
          {salesSecondary?.hits
            ?.filter((hit) => !!hit)
            .map((hit) => (
              <SaleCommandItem
                key={hit?._id}
                hit={hit}
                storeRegion={
                  storeRegion === StoreRegion.Fr
                    ? StoreRegion.Us
                    : StoreRegion.Fr
                }
                onClose={() => setOpen(false)}
              />
            ))}
        </CommandGroup>
      ) : null}
      {isLoadingMain || isLoadingSecondary ? (
        <CommandLoading className="py-4 text-center text-sm">
          {t('command.loading')}
        </CommandLoading>
      ) : null}
    </>
  );
};

const Brands: React.FC<{
  inputValue: string;
  setOpen: (open: boolean) => void;
}> = ({ inputValue, setOpen }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading } = useSearchBrandQuery(
    {
      name: inputValue,
    },
    {
      select: (data) => data.searchBrand.slice(0, 100),
    }
  );

  return (
    <>
      {inputValue && !isLoading ? (
        <CommandEmpty>{t('command.no_result')}</CommandEmpty>
      ) : null}
      {(data?.length ?? 0) > 0 ? (
        <CommandGroup heading={t('command.brands')}>
          {data?.map((brand) => (
            <CommandItem
              key={brand.id}
              onSelect={() => {
                navigate(`/marque/${brand.id}`);
                setOpen(false);
              }}
            >
              <span>
                {brand.name} <span className="sr-only">{brand.id}</span>
              </span>
            </CommandItem>
          ))}
        </CommandGroup>
      ) : null}
      {isLoading ? (
        <CommandLoading className="py-4 text-center text-sm">
          {t('command.loading')}
        </CommandLoading>
      ) : null}
    </>
  );
};

const SetupProducts = ({
  onPageChange,
  setOpen,
}: {
  onPageChange: () => void;
  setOpen: (open: boolean) => void;
}) => {
  const { t } = useTranslation();
  const dissociateImages = useDissociateImages();

  const [pages, setPages] = useRecoilState(pagesCommandMenuState);

  return (
    <>
      <CommandEmpty>{t('command.no_result')}</CommandEmpty>
      <CommandGroup heading={t('command.setup')}>
        <CommandItem
          onSelect={() => {
            setPages([...pages, Pages.SetProductCategory]);
            onPageChange();
          }}
        >
          <Icon icon="edit" className="mr-2" />
          {t('command.edit_category')}
        </CommandItem>
        <CommandItem
          onSelect={() => {
            setPages([...pages, Pages.RenameCustomFieldColumn]);
            onPageChange();
          }}
        >
          <Icon icon="edit" className="mr-2" />
          {t('command.rename_custom_field')}
        </CommandItem>
        <CommandItem
          onSelect={() => {
            dissociateImages();
            setOpen(false);
          }}
        >
          <Icon icon="edit" className="mr-2" />
          {t('command.dissociate_images')}
        </CommandItem>
      </CommandGroup>
    </>
  );
};

const SetProductCategory: React.FC<{
  inputValue: string;
  setOpen: (open: boolean) => void;
}> = ({ inputValue, setOpen }) => {
  const { i18n, t } = useTranslation();

  const { data, isLoading } = useCategories();

  const rowSelection = useRecoilValue(rowSelectionState);
  const contextRowSelection = useRecoilValue(contextRowSelectionState);
  const setCrawlingProducts = useSetRecoilState(crawlingProductsState);

  const flattenedCategories =
    data?.flatMap((category) => [
      category,
      ...(category.subCategories?.map((subCategory) => ({
        ...subCategory,
        label: `${category.label} > ${subCategory.label}`,
      })) ?? []),
    ]) ?? [];
  const filteredCategories =
    inputValue.trim() === ''
      ? flattenedCategories
      : matchSorter(flattenedCategories, inputValue, {
          keys: ['label'],
        });

  const onSetProductCategory = (categoryId: string) => {
    const productRowIndexes =
      Object.keys(rowSelection).length > 0
        ? Object.keys(rowSelection).map((key) =>
            parseInt(key.split('.')[0] ?? '', 10)
          )
        : contextRowSelection
          ? [parseInt(contextRowSelection.split('.')[0] ?? '', 10)]
          : null;

    setCrawlingProducts((previous) => {
      if (!previous) return;

      const products = structuredClone(previous);

      if (productRowIndexes) {
        productRowIndexes.forEach((productRowIndex) => {
          if (!products.products[productRowIndex]) return;
          products.products[productRowIndex] = {
            ...products.products[productRowIndex],
            category: categoryId,
            _subRows: [
              ...(products.products[productRowIndex]._subRows ?? []).map(
                (subProduct) => ({
                  ...subProduct,
                  category: categoryId,
                })
              ),
            ],
          };
        });
      } else {
        products.products.forEach((product) => {
          product.category = categoryId;
          product._subRows?.forEach((subProduct) => {
            subProduct.category = categoryId;
          });
        });
      }

      return products;
    });
  };

  return (
    <>
      {inputValue && !isLoading && filteredCategories.length === 0 ? (
        <CommandEmpty>{t('command.no_result')}</CommandEmpty>
      ) : null}
      {filteredCategories.length > 0 ? (
        <CommandGroup heading={t('command.categories')}>
          {filteredCategories?.map((category) => (
            <CommandItem
              key={category.id}
              onSelect={() => {
                onSetProductCategory(category.id);
                setOpen(false);
              }}
              className="flex justify-between space-x-4"
            >
              <span>
                {category.label} <span className="sr-only">{category.id}</span>
              </span>
              <span className="text-xs text-gray-500">
                {formatPercentage((category.tva ?? 0) / 100, i18n.language)}
              </span>
            </CommandItem>
          ))}
        </CommandGroup>
      ) : null}
      {isLoading ? (
        <CommandLoading className="py-4 text-center text-sm">
          {t('command.loading')}
        </CommandLoading>
      ) : null}
    </>
  );
};

const RenameCustomFieldColumn: React.FC<{
  inputValue: string;
}> = ({ inputValue }) => {
  const { t } = useTranslation();
  const crawlingProducts = useRecoilValue(crawlingProductsState);
  const [pages, setPages] = useRecoilState(pagesCommandMenuState);
  const setSelectedFieldName = useSetRecoilState(selectedCustomFieldNameState);

  const flattenedCustomFields = Array.from(
    new Set(
      crawlingProducts?.products.flatMap((product) => {
        if (!product.customFields) return [];
        return product.customFields.map((field) => field.name);
      }) ?? []
    )
  ).map((name) => ({
    name,
    index: crawlingProducts?.products[0]?.customFields?.findIndex(
      (field) => field.name === name
    ),
  }));

  const filteredCustomFields =
    inputValue.trim() === ''
      ? flattenedCustomFields
      : matchSorter(flattenedCustomFields, inputValue, {
          keys: ['name'],
        });

  return (
    <CommandGroup heading={t('command.rename_custom_field')}>
      {filteredCustomFields.length > 0 ? (
        filteredCustomFields.map(({ name, index }) => (
          <CommandItem
            key={index}
            onSelect={() => {
              setSelectedFieldName(name);
              setPages([...pages, Pages.RenameCustomFieldInput]);
            }}
            className="flex justify-between space-x-4"
          >
            {name}
          </CommandItem>
        ))
      ) : (
        <CommandEmpty>{t('command.no_custom_fields')}</CommandEmpty>
      )}
    </CommandGroup>
  );
};

const RenameCustomFieldInput: React.FC<{
  inputValue: string;
  setOpen: (open: boolean) => void;
}> = ({ inputValue, setOpen }) => {
  const { t } = useTranslation();
  const { modifyCustomFieldName } = useRenameColumn();
  const [selectedFieldName, setSelectedFieldName] = useRecoilState(
    selectedCustomFieldNameState
  );

  const crawlingProducts = useRecoilValue(crawlingProductsState);

  const selectedFieldIndex = () =>
    crawlingProducts?.products[0]?.customFields?.findIndex(
      (field) => field?.name === selectedFieldName
    ) ?? -1;

  const allCustomFieldNames = () =>
    crawlingProducts?.products.flatMap((product) =>
      product.customFields?.map((field) => field?.name)
    ) ?? [];

  const isValid = () =>
    inputValue && !allCustomFieldNames().includes(inputValue);

  const index = selectedFieldIndex();

  if (index === -1) {
    return;
  }

  const handleRename = (onSelectValue: string) => {
    modifyCustomFieldName(index, onSelectValue)();
    setSelectedFieldName(onSelectValue);
    setOpen(false);
  };

  return (
    <div>
      {allCustomFieldNames().includes(inputValue) && (
        <p className="m-3 text-xs text-red-600">
          {t('command.custom_field_name_taken')}
        </p>
      )}
      <CommandGroup
        heading={t('command.enter_new_name', {
          fieldName: selectedFieldName,
        })}
      >
        <CommandItem
          key={index}
          onSelect={() => {
            if (isValid()) handleRename(inputValue);
          }}
          className="flex justify-between space-x-4"
        >
          {inputValue
            ? `${selectedFieldName} ⮕ ${inputValue}`
            : `${selectedFieldName}`}
        </CommandItem>
      </CommandGroup>
    </div>
  );
};
