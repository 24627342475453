import type {
  CalendarSalesQuery,
  SaleSetupStatus,
  SaleStatusFilter,
  StoreRegion,
  TeamName,
  TopBrandType,
} from './generated';

export enum CalendarSaleType {
  SALE,
  PROVISIONAL_SALE,
}

export type CalendarSale = {
  brand: {
    about?: string | null;
    id?: string | null;
  };
  cancellation_level: string | null;
  contactEmail: string | null;
  category?: string;
  description?: string;
  end_at: Date | null;
  gender: number | null;
  hubspot_category: string | null;
  hubspot_id: string | null;
  id: string;
  instagram_id: string | null;
  isShopify?: boolean | null;
  logistics_manager: string | null;
  name: string;
  note: string | null;
  operation_type: string | null;
  sale_by: string | null;
  setup_by: string | null;
  start_at: Date;
  type: CalendarSaleType;
  website: string | null;
  forecast: number | null;
  // Specific to sales
  potential: number | null;
  sale_status: SaleStatusFilter | null;
  setup_status: SaleSetupStatus | null;
  selection_name: string | null;
  image_header: string | null;
  previews: string[] | null;
  deliver_by_choose: boolean | null;
  hide: boolean | null;
  is_setup: boolean | null;
  url_google_drive: string | null;
  total_sales: number | null;
  store_id: StoreRegion | null;
  shopify?:
    | NonNullable<CalendarSalesQuery['sales']>['sales'][number]['shopify']
    | null;
  shippingPolicy:
    | NonNullable<
        CalendarSalesQuery['sales']
      >['sales'][number]['shippingPolicy']
    | null;
  hubspot_team_name: TeamName | null;
  hubspot_top_brand_type: TopBrandType | null;
  originalSaleId: string | null;
  duplicatedSaleId: string | null;
};
